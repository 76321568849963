import { createSlice } from "@reduxjs/toolkit";
import { getLembaga, getKecamatan, getKabupaten, getProvinsi, getThnAjar, getGolongan, getTagihan, getKelas, getRuang, getSistem, getRole, getLembagaList, getGolDonatur, getGolDonaturByDon } from "../actions/optional";

export const optionalStore = createSlice({
    name: "optional",
    initialState: {
        lembaga: [],
        kecamatan: [],
        kabupaten: [],
        provinsi: [],
        thn_ajar : [],
        golongan : [],
        tagihan : {},
        kelas : [],
        ruang : [],
        sistem : [],
        role : [],
        golDonatur : [],
        golDonaturByUid : [],
        lembagaList : [],
        loadingOptional: false,
    },
    extraReducers: builder => {
        builder
            .addCase(getLembaga.pending, (state) => {
                state.loadingOptional = true
            })
            .addCase(getLembaga.fulfilled, (state, action) => {
                state.loadingOptional = false
                state.lembaga = action.payload
            })
            .addCase(getLembaga.rejected, (state) => {
                state.loadingOptional = false
            })
            .addCase(getProvinsi.pending, (state) => {
                state.loadingOptional = true
            })
            .addCase(getProvinsi.fulfilled, (state, action) => {
                state.loadingOptional = false
                state.provinsi = action.payload
            })
            .addCase(getProvinsi.rejected, (state) => {
                state.loadingOptional = false
            })
            .addCase(getKabupaten.pending, (state) => {
                state.loadingOptional = true
            })
            .addCase(getKabupaten.fulfilled, (state, action) => {
                state.loadingOptional = false
                state.kabupaten = action.payload
            })
            .addCase(getKabupaten.rejected, (state) => {
                state.loadingOptional = false
            })
            .addCase(getKecamatan.pending, (state) => {
                state.loadingOptional = true
            })
            .addCase(getKecamatan.fulfilled, (state, action) => {
                state.loadingOptional = false
                state.kecamatan = action.payload
            })
            .addCase(getKecamatan.rejected, (state) => {
                state.loadingOptional = false
            })
            .addCase(getThnAjar.pending, (state) => {
                state.loadingOptional = true
            })
            .addCase(getThnAjar.fulfilled, (state, action) => {
                state.loadingOptional = false
                state.thn_ajar = action.payload
            })
            .addCase(getThnAjar.rejected, (state) => {
                state.loadingOptional = false
            })
            .addCase(getGolongan.pending, (state) => {
                state.loadingOptional = true
            })
            .addCase(getGolongan.fulfilled, (state, action) => {
                state.loadingOptional = false
                state.golongan = action.payload
            })
            .addCase(getGolongan.rejected, (state) => {
                state.loadingOptional = false
            })
            .addCase(getTagihan.pending, (state) => {
                state.loadingOptional = true
            })
            .addCase(getTagihan.fulfilled, (state, action) => {
                state.loadingOptional = false
                state.tagihan = action.payload
            })
            .addCase(getTagihan.rejected, (state) => {
                state.loadingOptional = false
            })
            .addCase(getKelas.pending, (state) => {
                state.loadingOptional = true
            })
            .addCase(getKelas.fulfilled, (state, action) => {
                state.loadingOptional = false
                state.kelas = action.payload
            })
            .addCase(getKelas.rejected, (state) => {
                state.loadingOptional = false
            })
            .addCase(getRuang.pending, (state) => {
                state.loadingOptional = true
            })
            .addCase(getRuang.fulfilled, (state, action) => {
                state.loadingOptional = false
                state.ruang = action.payload
            })
            .addCase(getRuang.rejected, (state) => {
                state.loadingOptional = false
            })
            .addCase(getSistem.pending, (state) => {
                state.loadingOptional = true
            })
            .addCase(getSistem.fulfilled, (state, action) => {
                state.loadingOptional = false
                state.sistem = action.payload
            })
            .addCase(getSistem.rejected, (state) => {
                state.loadingOptional = false
            })
            .addCase(getRole.pending, (state) => {
                state.loadingOptional = true
            })
            .addCase(getRole.fulfilled, (state, action) => {
                state.loadingOptional = false
                state.role = action.payload
            })
            .addCase(getRole.rejected, (state) => {
                state.loadingOptional = false
            })
            .addCase(getLembagaList.pending, (state) => {
                state.loadingOptional = true
            })
            .addCase(getLembagaList.fulfilled, (state, action) => {
                state.loadingOptional = false
                state.lembagaList = action.payload
            })
            .addCase(getLembagaList.rejected, (state) => {
                state.loadingOptional = false
            })
            .addCase(getGolDonatur.pending, (state) => {
                state.loadingOptional = true
            })
            .addCase(getGolDonatur.fulfilled, (state, action) => {
                state.loadingOptional = false
                state.golDonatur = action.payload
            })
            .addCase(getGolDonatur.rejected, (state) => {
                state.loadingOptional = false
            })
            .addCase(getGolDonaturByDon.pending, (state) => {
                state.loadingOptional = true
            })
            .addCase(getGolDonaturByDon.fulfilled, (state, action) => {
                state.loadingOptional = false
                state.golDonaturByUid = action.payload
            })
            .addCase(getGolDonaturByDon.rejected, (state) => {
                state.loadingOptional = false
            })
    }
})
export default optionalStore.reducer