import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAlumni } from "../../api/alumni";

export const allAlumniByKey = createAsyncThunk(
    'alumni/get/key',
    async(key,{RejectWithValue})=>{
        try{
            const res = await ApiAlumni.getByKey(key)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return RejectWithValue(err.response.data.msg)
        }
    }
)