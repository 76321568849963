import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiTahunSistem from "../../api/tahunSistem";

export const getAllTahunSistem = createAsyncThunk(
    'get/all/tahun-sistem',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiTahunSistem.getAll()
            if(res.status === 200){
                return res.data
            }
        }catch(err){
            return rejectWithValue(err.response?.data?.msg)
        }
    }
)

export const addTahunSistem = createAsyncThunk(
    'add/tahun-sistem',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiTahunSistem.add(data)
            if(res.status === 200){
                return res.data
            }
        }catch(err){
            return rejectWithValue(err.response?.data?.msg)
        }
    }
)

export const updateTahunSistem = createAsyncThunk(
    'update/tahun-sistem',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiTahunSistem.update(data.id,data.data)
            if(res.status === 200){
                return res.data
            }
        }catch(err){
            return rejectWithValue(err.response?.data?.msg)
        }
    }
)

export const deleteTahunSistem = createAsyncThunk(
    'delete/tahun-sistem',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiTahunSistem.delete(data)
            if(res.status === 200){
                return res.data
            }
        }catch(err){
            return rejectWithValue(err.response?.data?.msg)
        }
    }
)