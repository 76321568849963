import React from "react";
import Dialog from "./Dialog";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useResetForm from "../hooks/custom/useResetForm";
import Input from "./Input";
import AddButton from "./AddButton";
import useSistemOptions from "../options/sistem";
import Options from "./Options";
import Radio from "./Radio";

export default function FormTahunSistem(props) {
  const method = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Tidak boleh kosong"),
        from: yup.string().required("TIdak boleh koson"),
        to: yup.string().required("Tidak boleh kosong"),
      })
    ),
  });
  const sistemOptions = useSistemOptions(props.show);
  useResetForm(
    method,
    props.oneData
      ? {
          ...props.oneData,
          from: props.oneData?.academic_year?.split("/")[0],
          to: props?.oneData?.academic_year?.split("/")[1],
          active: props.oneData?.active ? "aktif" : "tidakAktid",
        }
      : { name: "", from: "", to: "" },
    props.show
  );
  return (
    <Dialog show={props.show} toggle={props.close}>
      <div className="bg-white md:w-[40vw] w-[95vw]">
        <div className="py-4 px-6 border-b border-slate-200">
          <h1 className="text-xl">Tambah Tahun Sistem</h1>
        </div>
        <form
          onSubmit={method.handleSubmit(props.submit)}
          className="px-6 py-7 space-y-1"
        >
          <h1 className="font-light"> Tahun Ajar</h1>
          <div className="flex space-x-2">
            <Input label="" method={method} methodName="from" />
            <h1 className=" flex justify-center items-end mb-2">/</h1>
            <Input label="" method={method} methodName="to" />
          </div>
          <Options
            label="Sistem"
            method={method}
            methodName="name"
            options={sistemOptions}
          />
          <AddButton />
        </form>
      </div>
    </Dialog>
  );
}
