import { createSlice } from "@reduxjs/toolkit";
import { allTunggakan, cetakBukti, getPembayaran, postPembayaran, getTunggakanDonatur, historySantri, batalPembayaran } from "../actions/pembayaran";

export const pembayaranStore = createSlice({
    name:"pembayaran",
    initialState :{
        allPembayaran : [],
        tunggakan : [],
        tunggakanDonatur : [],
        historyOneSantri : [],
        cetak : {},
        msgPembayaran : "",
        loadingPembayaran : false

    },
    reducers:{
        clearMsg : (state)=>{
            state.msgPembayaran = ""
        }
    },
    extraReducers : builder =>{
        builder
        .addCase(getPembayaran.pending,(state)=>{
            state.loadingPembayaran = true
        })
        .addCase(getPembayaran.fulfilled,(state,action)=>{
            state.loadingPembayaran = false
            state.allPembayaran = action.payload
        })
        .addCase(getPembayaran.rejected,(state,action)=>{
            state.loadingPembayaran = false
            state.msgPembayaran = action.payload
            state.allPembayaran = []
        })
        .addCase(postPembayaran.pending,(state)=>{
            state.loadingPembayaran = true
        })
        .addCase(postPembayaran.fulfilled,(state,action)=>{
            state.loadingPembayaran = false
            state.msgPembayaran = action.payload.msg
        })
        .addCase(postPembayaran.rejected,(state,action)=>{
            state.loadingPembayaran = false
            state.msgPembayaran = action.payload
        })
        .addCase(allTunggakan.pending,(state)=>{
            state.loadingPembayaran = true
        })
        .addCase(allTunggakan.fulfilled,(state,action)=>{
            state.loadingPembayaran = false
            state.tunggakan = action.payload
        })
        .addCase(allTunggakan.rejected,(state,action)=>{
            state.loadingPembayaran = false
        })
        .addCase(cetakBukti.pending,(state)=>{
            state.loadingPembayaran = true
        })
        .addCase(cetakBukti.fulfilled,(state,action)=>{
            state.loadingPembayaran = false
            state.cetak = action.payload
        })
        .addCase(cetakBukti.rejected,(state,action)=>{
            state.loadingPembayaran = false
        })
        .addCase(getTunggakanDonatur.pending,(state)=>{
            state.loadingPembayaran = true
        })
        .addCase(getTunggakanDonatur.fulfilled,(state,action)=>{
            state.loadingPembayaran = false
            state.tunggakanDonatur = action.payload
        })
        .addCase(getTunggakanDonatur.rejected,(state,action)=>{
            state.loadingPembayaran = false
        })
        .addCase(historySantri.pending,(state)=>{
            state.loadingPembayaran = true
        })
        .addCase(historySantri.fulfilled,(state,action)=>{
            state.loadingPembayaran = false
            state.historyOneSantri = action.payload
        })
        .addCase(historySantri.rejected,(state,action)=>{
            state.loadingPembayaran = false
        })
        .addCase(batalPembayaran.pending,(state)=>{
            state.loadingPembayaran = true
        })
        .addCase(batalPembayaran.fulfilled,(state,action)=>{
            state.loadingPembayaran = false
            state.msgPembayaran = action.payload.msg
        })
        .addCase(batalPembayaran.rejected,(state,action)=>{
            state.loadingPembayaran = false
            state.msgPembayaran = action.payload
        })
    }
})
export const pembayaranAction = pembayaranStore.actions
export default pembayaranStore.reducer