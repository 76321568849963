import { createSlice } from "@reduxjs/toolkit";
import { allAlumniByKey } from "../actions/alumni";

export const alumniStore = createSlice({
    name:"alumni",
    initialState:{
        alumniAll : [],
        loadingAlumni : false,
        msgAlumni : ""
    },
    extraReducers:builder =>{
        builder
        .addCase(allAlumniByKey.pending,(state)=>{
            state.loadingAlumni = true
        })
        .addCase(allAlumniByKey.fulfilled,(state,action)=>{
            state.loadingAlumni = false
            state.alumniAll = action.payload
        })
        .addCase(allAlumniByKey.rejected,(state,action)=>{
            state.loadingAlumni = false
            state.alumniAll = []
            state.msgAlumni = action.payload
        })
    }
})
export default alumniStore.reducer