import api from "./http";

const ApiSantri = {
    postSantri : (data) =>{
        return api.post(`/santri`,data)
    },
    getAllSantri : () =>{
        return api.get(`/santri`)
    },
    getOneSantri : (uuid)=>{
        return api.get(`/santri/get/${uuid}`)
    },
    getSearchSantri : (key) =>{
        return api.get(`/santri/search/${key}`)
    },
    putSantri : (uuid,data) => {
        return api.put(`/santri/${uuid}`,data)
    },
    deleteSantri : (uuid) =>{
        return api.delete(`/santri/${uuid}`)
    },
    createMobilUser : (data)=>{
        return api.post(`/santri/mobile/user`,data)
    },
    createMutasi : (uuid,data)=>{
        return api.put(`santri/mutasi/${uuid}`,data)
    },
    reportSantri : ()=>{
        return api.get(`/santri/report`)
    },
    getOrtu : (data) =>{
        return api.post(`/santri/ortu/get`,data)
    }
}
export default ApiSantri