import React, { useEffect, useState } from 'react'
import { GiMoneyStack } from 'react-icons/gi'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import HeaderUi from '../component/HeaderUi'
import Table from '../component/Table'
import { allAlumniByKey } from '../store/actions/alumni'

function TableAlumni() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {alumniAll,loadingAlumni,msgAlumni} = useSelector((state)=>state.alumni)
    const [key, setKey] = useState("")
    useEffect(()=>{
        if(key.length >2){
            dispatch(allAlumniByKey(key))
        }
    },[key])    
    return (
        <div>
            <HeaderUi titleHeader="Table Alumni" location="List" />
            <div className='bg-white m-5 p-5 flex flex-col shadow-lg'>
                <label className='text-md font-light text-slate-700'>Masukkan NUWB/Nama</label>
                <input className='px-2 py-1 outline-none border border-sky-400 w-[20rem] font-light' value={key} onChange={(e) => setKey(e.target.value)} />
            </div>
            <Table classFirst="hidden">
            <div className='bg-white'>
                    {alumniAll.length === 0 ? <div>
                        <h1 className='font-light text-center my-10 text-xl'>Tidak Ada Alumni</h1>
                    </div> :
                        <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden">
                            <table className="w-full text-sm text-left text-gray-500 ">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            No
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            NUWB
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Nama
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Lembaga
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Tahun Lulus
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Status
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Pembayaran
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {alumniAll.map((d, id) => (
                                        <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                                            <td className='px-6 py-3'>{id + 1}</td>
                                            <td className='px-6 py-3'>{d.nuwb}</td>
                                            <td className='px-6 py-3'>{d.nama}</td>
                                            <td className='px-6 py-3'>{d.lembaga?.nama}</td>
                                            <td className='px-6 py-3'>{d.status_santri?.tahun_lulus}</td>
                                            <td className='px-6 py-3'>{d.status_santri?.status === 1 ? "Aktif" : d.status_santri?.status === 2 ? "Lulus" : "Lain-lain"}</td>
                                            <td className='px-10 py-3'><GiMoneyStack className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                                navigate(`/home/pembayaran/${d.nuwb}`)
                                            }} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </Table>
        </div>
    )
}

export default TableAlumni