import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import AddButton from "./AddButton";
import Dialog from "./Dialog";
import Input from "./Input";
import Radio from "./Radio";

function FormEditLembaga(props) {
  const methodLembaga = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        nama: yup.string().required("Tidak Boleh Kosong"),
        cakepin: yup
          .string()
          .oneOf(["true", "false"], "pilih salah satu")
          .required("Tidak boleh kosong"),
      })
    ),
  });
  useEffect(() => {
    if (Object.keys(props.data).length !== 0) {
      methodLembaga.reset({nama:props.data.nama,cakepin:props.data.cakepin?.toString()});
    }
  }, [props.data]);
  return (
    <div>
      <Dialog show={props.showForm} toggle={props.closeForm}>
        <div className="bg-white md:w-[40vw] w-[95vw]">
          <div className="py-4 px-6 border-b border-slate-200">
            <h1 className="text-xl">Edit Lembaga</h1>
          </div>
          <form
            onSubmit={methodLembaga.handleSubmit(props.submit)}
            className="px-6 py-7 space-y-1"
          >
            {/* <Input label="Kode Lembaga" type="number" method={methodLembaga} methodName="kode_lembaga" /> */}
            <Input
              label="Nama Lembaga"
              method={methodLembaga}
              methodName="nama"
            />
            <div>
              <h1 className="font-light text-lg my-2">Cakepin</h1>
              <div className="flex my-auto md:space-x-0 space-x-2">
                <Radio
                  method={methodLembaga}
                  methodName="cakepin"
                  value="true"
                  label="Iya"
                />
                <Radio
                  method={methodLembaga}
                  methodName="cakepin"
                  value="false"
                  label="Tidak"
                />
              </div>
              {methodLembaga.formState.errors["cakepin"] && (
                <p className="text-red-600 mt-1 mx-1">
                  {methodLembaga.formState.errors["cakepin"].message}
                </p>
              )}
            </div>
            <AddButton />
          </form>
        </div>
      </Dialog>
    </div>
  );
}

export default FormEditLembaga;
