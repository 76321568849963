import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getKelas } from "../store/actions/optional";

export default function useGetKelasOptions(lembaga) {
  const [kelasOptions, setKelasOptions] = useState([]);
  const dispatch = useDispatch();
  const { kelas } = useSelector((state) => state.optional);
  useEffect(() => {
    if (lembaga) {
      dispatch(getKelas(lembaga));
    }
  }, [lembaga]);
  useEffect(() => {
    if (kelas.length > 0) {
      const data = kelas.map((value) => {
        return { value: value.kelas, label: value.kelas };
      });
      setKelasOptions(data);
    }
  }, [kelas]);
  return kelasOptions;
}
