import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from "yup"
import { getKelas, getLembaga } from '../store/actions/optional'
import Options from './Options'
import OptionsField from './OptionsField'
import Dialog from './Dialog'
import Input from './Input'
import AddButton from './AddButton'

function FormRuang(props) {
    const dispatch = useDispatch()
    const [lem, setLem] = useState({})
    const { lembaga, kelas } = useSelector((state) => state.optional)
    const methodRuang = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                id_kelas: yup.string().required("Tidak Boleh Kosong"),
                ruang: yup.string().required("Tidak Boleh Kosong"),
            })
        )
    })
    const lemOpt = lembaga.map((d) => {
        return { value: d.kode_lembaga, label: d.nama }
    })
    const klsOpt = kelas.map((d) => {
        return { value: d.id, label: d.kelas }
    })
    useEffect(() => {
        dispatch(getLembaga())
    }, [])
    useEffect(() => {
        if (lem.value !== "") {
            dispatch(getKelas(lem.value))
        }
    }, [lem])
    useEffect(() => {
        methodRuang.reset()
        setLem({})
    }, [props.showForm])
    return (
        <Dialog show={props.showForm} toggle={props.closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Tambah Ruang Kelas Baru</h1>
                </div>
                <form onSubmit={methodRuang.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                    <OptionsField label="Lembaga" onChange={(e) => setLem(e)} value={lem} options={lemOpt} />
                    <Options label="Kelas" method={methodRuang} methodName="id_kelas" options={klsOpt} />
                    <Input label="Nama Ruang" method={methodRuang} methodName="ruang" />
                    <AddButton />
                </form>
            </div>
        </Dialog>
    )
}

export default FormRuang