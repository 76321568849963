import api from "./http";

const ApiDonatur = {
    get : () =>{
        return api.get(`/cakepin/list-donatur`)
    },
    post : (data) =>{
        return api.post(`/cakepin/list-donatur`,data)
    },
    delete : (uuid) =>{
        return api.delete(`/cakepin/list-donatur/${uuid}`)
    },
    edit : (uuid,data) =>{
        return api.put(`/cakepin/list-donatur/${uuid}`,data)
    },
    addGolongan : (data) =>{
        return api.post(`/cakepin/list-golongan-donatur`,data)
    },
    editGolongan : (id,data)=>{
        return api.put(`/cakepin/list-golongan-donatur/${id}`,data)
    },
    generateDonatur : (data) =>{
        return api.post(`/cakepin/tagihan/donatur`,data)
    },
    getOneDonaturTtpByNuwb : (data) =>{
        return api.post(`/cakepin/tagihan/check/donatur`,data)
    },
    generateDonaturTdkTtp : (data) =>{
        return api.post(`/cakepin/tagihan/donatur/tidak-tetap`,data)
    },
    getOneDonaturTdkTtpByNuwb : (data) =>{
        return api.post(`/cakepin/tagihan/check/donatur/tidak-tetap`,data)
    },
    deleteOneDonaturTdkTtp : (data) =>{
        return api.post(`/cakepin/tagihan/delete/donatur/tidak-tetap`,data)
    },
    deleteOneDonaturTtp : (data) =>{
        return api.post(`/cakepin/tagihan/delete/donatur`,data)
    },
    createDonaturUp : (uuid,data)=>{
        return api.put(`/cakepin/tagihan/donatur/up/${uuid}`,data)
    }
}
export default ApiDonatur