import { createSlice } from "@reduxjs/toolkit";
import {
  editNoKkBersaudara,
  getAllBersaudara,
  postPotonganBersaudara,
} from "../actions/bersaudara";

export const bersaudaraStore = createSlice({
  name: "bersaudara",
  initialState: {
    allBersaudara: [],
    status: "iddle",
    loadingBersaudara: false,
    msgBersaudara: "",
  },
  reducers: {
    clearStatus: (state) => {
      state.status = "iddle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBersaudara.pending, (state) => {
        state.loadingBersaudara = true;
      })
      .addCase(getAllBersaudara.fulfilled, (state, action) => {
        state.loadingBersaudara = false;
        state.allBersaudara = action.payload;
      })
      .addCase(getAllBersaudara.rejected, (state, action) => {
        state.loadingBersaudara = false;
        state.msgBersaudara = action.payload;
      })
      .addCase(postPotonganBersaudara.pending, (state) => {
        state.loadingBersaudara = true;
        state.status = "pending";
      })
      .addCase(postPotonganBersaudara.fulfilled, (state, action) => {
        state.loadingBersaudara = false;
        state.status = "succes";
        state.msgBersaudara = action.payload.msg;
      })
      .addCase(postPotonganBersaudara.rejected, (state, action) => {
        state.loadingBersaudara = false;
        state.status = "error";
        state.msgBersaudara = action.payload;
      })
      .addCase(editNoKkBersaudara.pending, (state) => {
        state.loadingBersaudara = true;
      })
      .addCase(editNoKkBersaudara.fulfilled, (state, action) => {
        state.loadingBersaudara = false;
        state.msgBersaudara = action.payload.msg;
      })
      .addCase(editNoKkBersaudara.rejected, (state, action) => {
        state.loadingBersaudara = false;
        state.msgBersaudara = action.payload;
      });
  },
});

export const bersaudaraActions = bersaudaraStore.actions;
export default bersaudaraStore.reducer;
