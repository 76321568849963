import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DialogMutasi from "../component/DialogMutasi";
import FormAddUserMobile from "../component/FormAddUserMobile";
import FormDonaturSantri from "../component/FormDonaturSantri";
import FormGenerateTagihan from "../component/FormGenerateTagihan";
import FormUp from "../component/FormUp";
import HeaderUi from "../component/HeaderUi";
import Loader from "../component/Loader";
import SnackBar from "../component/SnackBar";
import Table from "../component/Table";
import {
  postDonaturUp,
  postGenerateDonatur,
  postGenerateDonaturTdkTtp,
} from "../store/actions/donatur";
import {
  addMutasiSantri,
  addUserMobile,
  oneSantri,
  searchSantri,
} from "../store/actions/santri";
import { createTagihan } from "../store/actions/spp";
import DialogNormalisasi from "../component/FormNormalisasi";
import { normalisasiSpp } from "../store/actions/spp";

function PanelSantri() {
  const nuwb = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { santriByKey, loadingSantri, msgSantri } = useSelector(
    (state) => state.santri
  );
  const { msgDonatur } = useSelector((state) => state.donatur);
  const [formGenerate, setFormGenerate] = useState(false);
  const [formDonatur, setFormDonatur] = useState(false);
  const [formUserMobile, setFormUserMobile] = useState(false);
  const [snackDon, setSnackDon] = useState(false);
  const [snack, setSnack] = useState(false);
  const [snackMobile, setSnackMobile] = useState(false);
  const [formTdkTetap, setFormTdkTetap] = useState(false);
  const [formMutasi, setFormMutasi] = useState(false);
  const [formNormalisasi, setFormNormalisasi] = useState(false);
  const [formDonUp, setFormDonUp] = useState(false);
  const { msgSpp } = useSelector((state) => state.spp);

  useEffect(() => {
    if (Object.keys(nuwb).length !== 0) {
      dispatch(searchSantri(nuwb.nuwb));
    }
  }, [nuwb]);
  const addTagihan = (e) => {
    dispatch(createTagihan(e));
    setFormGenerate(!formGenerate);
    setSnack(!snack);
  };
  const addDonatur = (e) => {
    dispatch(postGenerateDonatur(e));
    setFormDonatur(!formDonatur);
    setSnackDon(!snackDon);
  };
  const addDonTdkTtp = (e) => {
    dispatch(postGenerateDonaturTdkTtp(e));
    setFormTdkTetap(!formTdkTetap);
    setFormDonatur(false);
    setSnackDon(!snackDon);
  };
  const tambahMobile = (e) => {
    dispatch(addUserMobile(e));
    setFormUserMobile(false);
    setSnackMobile(true);
  };
  const mutasiSantri = (e) => {
    dispatch(addMutasiSantri({ uuid: santriByKey[0]?.uuid, ket: e }));
    setFormMutasi(false);
    setSnackMobile(true);
  };
  const addDonUp = (e) => {
    if (e.donatur === "yes") {
      e.donatur = true;
    } else if (e.donatur === "no") {
      e.donatur = false;
    }
    dispatch(postDonaturUp({ uuid: santriByKey[0].uuid, update: e }));
    setFormDonUp(false);
    setSnackDon(true);
  };
  const removeDonatur = () => {
    console.log(santriByKey[0]?.uuid);
    dispatch(normalisasiSpp(santriByKey[0]?.nuwb));
    setFormNormalisasi(false);
    setSnack(true);
  };
  return (
    <div>
      <Loader show={loadingSantri} />
      <SnackBar show={snack} toggle={setSnack} msg={msgSpp} />
      <SnackBar show={snackDon} toggle={setSnackDon} msg={msgDonatur} />
      <SnackBar show={snackMobile} toggle={setSnackMobile} msg={msgSantri} />
      <HeaderUi titleHeader="Panel Santri" />
      {santriByKey.length === 0 ? (
        <div></div>
      ) : (
        <div>
          <DialogMutasi
            show={formMutasi}
            close={setFormMutasi}
            msg={"Mutasi santri"}
            submit={mutasiSantri}
          />
          <DialogNormalisasi
            show={formNormalisasi}
            close={setFormNormalisasi}
            msg={"Hapus Normalisasi"}
            click={removeDonatur}
          />
          <FormUp
            showForm={formDonUp}
            closeForm={setFormDonUp}
            submit={addDonUp}
            uuid={santriByKey[0]?.uuid}
          />
          <FormAddUserMobile
            showForm={formUserMobile}
            closeForm={setFormUserMobile}
            submit={tambahMobile}
            uuid={santriByKey[0]?.uuid}
          />
          <FormGenerateTagihan
            showForm={formGenerate}
            closeForm={setFormGenerate}
            data={santriByKey[0]}
            uuid_santri={santriByKey[0]?.uuid}
            submit={addTagihan}
          />
          <FormDonaturSantri
            showForm={formDonatur}
            closeForm={setFormDonatur}
            secondShow={formTdkTetap}
            secondClose={() => {
              setFormTdkTetap();
              window.location.reload(false);
            }}
            changeForm={() => {
              setFormTdkTetap(!formTdkTetap);
              setFormDonatur(!formDonatur);
            }}
            uuid_santri={santriByKey[0]?.uuid}
            lembaga={santriByKey[0]?.kode_lembaga}
            submit={addDonatur}
            submitSecond={addDonTdkTtp}
          />
        </div>
      )}
      <Table classFirst="hidden">
        {santriByKey.length === 0 ? (
          <h1>Tidak Ada Santri</h1>
        ) : (
          <div>
            <h1 className="text-2xl font-light my-3">Data Santri</h1>
            <div className="h-[1px] bg-black w-full"></div>
            <div className="flex justify-between mt-4">
              <h1 className="text-xl">Nuwb :</h1>
              <h1 className="text-xl font-light">{santriByKey[0].nuwb}</h1>
            </div>
            <div className="flex justify-between mt-4">
              <h1 className="text-xl">Nama :</h1>
              <h1 className="text-xl font-light">{santriByKey[0].nama}</h1>
            </div>
            <div className="flex justify-between mt-4">
              <h1 className="text-xl">Lembaga :</h1>
              <h1 className="text-xl font-light">
                {santriByKey[0].lembaga?.nama}
              </h1>
            </div>
            <div className="flex justify-between mt-4">
              <h1 className="text-xl">Kelas :</h1>
              <h1 className="text-xl font-light">{santriByKey[0].kelas}</h1>
            </div>
            <div className="flex justify-between mt-4">
              <h1 className="text-xl">Ruang :</h1>
              <h1 className="text-xl font-light">{santriByKey[0].ruang}</h1>
            </div>
            <div className="h-[1px] bg-black w-full my-5"></div>
            <div className="flex flex-wrap grid-cols-1 gap-5">
              <button
                className="px-10 py-2 border border-[#155a7a] rounded-md text-[#104057] hover:bg-base hover:text-white transition-colors ease-in-out duration-300 "
                onClick={() => {
                  setFormGenerate(!formGenerate);
                }}
              >
                Tagihan
              </button>
              <button
                className="px-10 py-2 border border-[#155a7a] rounded-md text-[#104057] hover:bg-base hover:text-white transition-colors ease-in-out duration-300"
                onClick={() => {
                  dispatch(oneSantri(santriByKey[0].uuid));
                  navigate(`/home/edit/santri/${santriByKey[0].uuid}`);
                  // window.location.href = `/home/edit/santri/${santriByKey[0].uuid}`
                }}
              >
                Edit Santri
              </button>
              <button
                className="px-10 py-2 border border-[#155a7a] rounded-md text-[#104057] hover:bg-base hover:text-white transition-colors ease-in-out duration-300 "
                onClick={() => {
                  setFormDonatur(!formDonatur);
                }}
              >
                Donatur Spp
              </button>
              <button
                className="px-10 py-2 border border-[#155a7a] rounded-md text-[#104057] hover:bg-base hover:text-white transition-colors ease-in-out duration-300 "
                onClick={() => {
                  setFormDonUp(!formDonUp);
                }}
              >
                Donatur Up
              </button>
              <button
                className="px-10 py-2 border border-[#155a7a] rounded-md text-[#104057] hover:bg-base hover:text-white transition-colors ease-in-out duration-300 "
                onClick={() => {
                  navigate(`/home/panel/history/${nuwb.nuwb}`);
                }}
              >
                Rincian
              </button>
              <button
                className="px-10 py-2 border border-[#155a7a] rounded-md text-[#104057] hover:bg-base hover:text-white transition-colors ease-in-out duration-300"
                onClick={() => {
                  navigate(`/home/panel/bersaudara/${nuwb.nuwb}`);
                }}
              >
                Bersaudara
              </button>
              <button
                className="px-10 py-2 border border-[#155a7a] rounded-md text-[#104057] hover:bg-base hover:text-white transition-colors ease-in-out duration-300"
                onClick={() => {
                  setFormUserMobile(true);
                }}
              >
                User mobile
              </button>
              <button
                className="px-10 py-2 border border-[#155a7a] rounded-md text-[#104057] hover:bg-base hover:text-white transition-colors ease-in-out duration-300"
                onClick={() => {
                  setFormMutasi(true);
                }}
              >
                Mutasi
              </button>
              <button
                className="px-10 py-2 border border-[#155a7a] rounded-md text-[#104057] hover:bg-base hover:text-white transition-colors ease-in-out duration-300"
                onClick={() => {
                  setFormNormalisasi(true);
                }}
              >
                Normalisasi
              </button>
            </div>
          </div>
        )}
      </Table>
    </div>
  );
}

export default PanelSantri;
