import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getKabupaten } from "../store/actions/optional";

export default function useGetKabupatenOptions(provinsi_id) {
  const [kabupatenOptions, setKabupatenOptions] = useState([]);
  const dispatch = useDispatch();
  const { kabupaten } = useSelector((state) => state.optional);
  useEffect(() => {
    if (provinsi_id?.length > 0) {
      dispatch(getKabupaten(provinsi_id));
    }
  }, [provinsi_id]);
  useEffect(() => {
    if (kabupaten.length > 0) {
      const data = kabupaten.map((value) => {
        return { value: value.id, label: value.nama };
      });
      setKabupatenOptions(data);
    }
  }, [kabupaten]);
  return kabupatenOptions;
}
