import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiLembaga from "../../api/lembaga";

export const addLembaga = createAsyncThunk(
    'lembaga/add',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ApiLembaga.addNew(data)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const updateLembaga = createAsyncThunk(
    'lembaga/edit',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ApiLembaga.edit(data.uuid, data.update)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const postRuang = createAsyncThunk(
    'ruang/add',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ApiLembaga.addRuang(data)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const updateRuang = createAsyncThunk(
    'ruang/edit',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ApiLembaga.editRuang(data.id, data.update)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const destroyRuang = createAsyncThunk(
    'ruang/delete',
    async(id,{rejectWithValue})=>{
        try{
            const res = await ApiLembaga.deleteRuang(id)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)