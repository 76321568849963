import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiDiskonBersaudara from "../../api/diskon";

export const getAllDiskonBersaudara = createAsyncThunk(
  "get/all/diskon-bersaudara",
  async (_, { rejectWithValue }) => {
    try {
      const res = await ApiDiskonBersaudara.getAll();
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err?.response?.data?.msg);
    }
  }
);

export const getAllDiskonBersaudaraByTahun = createAsyncThunk(
  "get/all/by-tahun/diskon-bersaudara",
  async (year, { rejectWithValue }) => {
    try {
      const res = await ApiDiskonBersaudara.getByYear(year);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err?.response?.data?.msg);
    }
  }
);

export const addDiskonBersaudara = createAsyncThunk(
  "add/diskon-bersaudara",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiDiskonBersaudara.add(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err?.response?.data?.msg);
    }
  }
);

export const updateDiskonBersaudara = createAsyncThunk(
  "update/diskon-bersaudara",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiDiskonBersaudara.update(data.id, data.data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err?.response?.data?.msg);
    }
  }
);

export const deleteDiskonBersaudara = createAsyncThunk(
  "delete/diskon-bersaudara",
  async (id, { rejectWithValue }) => {
    try {
      const res = await ApiDiskonBersaudara.delete(id);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err?.response?.data?.msg);
    }
  }
);
