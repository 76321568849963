import React, { useEffect, useState } from 'react'
import { HiOutlinePencilSquare, HiOutlineTrash } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import DialogDelete from '../component/DialogDelete'
import FormAddDonatur from '../component/FormAddDonatur'
import FormEditDonatur from '../component/FormEditDonatur'
import HeaderUi from '../component/HeaderUi'
import Loader from '../component/Loader'
import SnackBar from '../component/SnackBar'
import Table from '../component/Table'
import { deleteOneDonatur, editOneDonatur, getAllDonatur, postNewDonatur } from '../store/actions/donatur'

function TableDonatur() {
    const dispatch = useDispatch()
    const { allDonatur,msgDonatur,loadingDonatur } = useSelector((state) => state.donatur)
    const [data,setData] = useState({})
    const [formDonatur, setFormDonatur] = useState(false)
    const [editDonatur,setEditDonatur] = useState(false)
    const [formDeleteDonatur,setFormDeleteDonatur] = useState(false)
    const [snack,setSnack] = useState(false)
    useEffect(() => {
        dispatch(getAllDonatur())
    }, [snack])
    const addDonatur = (e) =>{
        dispatch(postNewDonatur(e))
        setFormDonatur(!formDonatur)
        setSnack(!snack)
    }
    const updateDonatur = (e) =>{
        dispatch(editOneDonatur({uuid:data.uuid,update:e}))
        setEditDonatur(!editDonatur)
        setSnack(!snack)
    }
    const hapusDonatur = () =>{
        dispatch(deleteOneDonatur(data.uuid))
        setFormDeleteDonatur(!formDeleteDonatur)
        setSnack(!snack)
    }
    return (
        <div>
            <Loader show={loadingDonatur}/>
            <SnackBar show={snack} toggle={setSnack} msg={msgDonatur}/>
            <HeaderUi titleHeader="List Donatur" />
            <FormAddDonatur showForm={formDonatur} closeForm={setFormDonatur} submit={addDonatur} />
            <FormEditDonatur showForm={editDonatur} closeForm={setEditDonatur} submit={updateDonatur} data={data}/> 
            <DialogDelete show={formDeleteDonatur} close={setFormDeleteDonatur} onClick={hapusDonatur} /> 
            <Table
                titleFirst="Tambah"
                clickFirst={() => {
                    setFormDonatur(!formDonatur)
                }}

            >
                <div className='bg-white'>
                    {allDonatur.length === 0 ? <div>
                        <h1 className='font-light text-center my-10 text-xl'>Tidak Ada Donatur</h1>
                    </div> :
                        <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden z-0">
                            <table className="w-full text-sm text-left text-gray-500 ">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            No
                                        </th>
                                        <th scope="col" className="px-6 py-3 md:w-[75%]">
                                            Donatur
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Edit
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Delete
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allDonatur.map((d, id) => (
                                        <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                                            <td className='px-6 py-3'>{id + 1}</td>
                                            <td className='px-6 py-3 md:w-[75%]'>{d.nama}</td>
                                            <td className='px-3 py-3'><HiOutlinePencilSquare className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                                setEditDonatur(!editDonatur)
                                                setData(d)
                                            }} /></td>
                                            <td className='px-6 py-3'><HiOutlineTrash className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                                setFormDeleteDonatur(!formDeleteDonatur)
                                                setData(d)
                                            }} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </Table>
        </div>
    )
}

export default TableDonatur