import { createSlice } from "@reduxjs/toolkit";
import { addMutasiSantri, addSantri, addUserMobile, allReportSantri, allSantri, destroySantri, findAyah, findIbu, oneSantri, searchSantri, updateSantri } from "../actions/santri";

export const santriStore = createSlice({
    name:"santri",
    initialState:{
        santriAll : [],
        santriByKey : [],
        santriOne : {},
        ayahOne : {},
        ibuOne : {},
        reportSantriAll : [],
        msgSantri : "",
        loadingSantri : false
    },
    reducers : {
        clearSantriOne : (state)=>{
            state.santriOne = {}
            state.msgSantri = ""
        },
        clearAyah : (state)=>{
            state.ayahOne = {}
        },
        clearIbu : (state)=>{
            state.ibuOne = {}
        }
    },
    extraReducers : builder =>{
        builder
        .addCase(addSantri.pending,(state)=>{
            state.loadingSantri = true
        })
        .addCase(addSantri.fulfilled,(state,action)=>{
            state.loadingSantri = false
            state.msgSantri = action.payload.msg
        })
        .addCase(addSantri.rejected,(state,action)=>{
            state.loadingSantri = false
            state.msgSantri = action.payload
        })
        .addCase(allSantri.pending,(state)=>{
            state.loadingSantri = true
        })
        .addCase(allSantri.fulfilled,(state,action)=>{
            state.loadingSantri = false
            state.santriAll = action.payload
        })
        .addCase(allSantri.rejected,(state)=>{
            state.loadingSantri = false
        })
        .addCase(allReportSantri.pending,(state)=>{
            state.loadingSantri = true
        })
        .addCase(allReportSantri.fulfilled,(state,action)=>{
            state.loadingSantri = false
            state.reportSantriAll = action.payload
        })
        .addCase(allReportSantri.rejected,(state)=>{
            state.loadingSantri = false
            state.reportSantriAll = [] 
        })
        .addCase(oneSantri.pending,(state)=>{
            state.loadingSantri = true
        })
        .addCase(oneSantri.fulfilled,(state,action)=>{
            state.loadingSantri = false
            state.santriOne = action.payload
        })
        .addCase(oneSantri.rejected,(state)=>{
            state.loadingSantri = false
        })
        .addCase(searchSantri.pending,(state,action)=>{
            state.loadingSantri = true
        })
        .addCase(searchSantri.fulfilled,(state,action)=>{
            state.loadingSantri = false
            state.santriByKey = action.payload
        })
        .addCase(searchSantri.rejected,(state,action)=>{
            state.loadingSantri = false
        })
        .addCase(updateSantri.pending,(state)=>{
            state.loadingSantri = true
        })
        .addCase(updateSantri.fulfilled,(state,action)=>{
            state.loadingSantri = false
            state.msgSantri = action.payload.msg
        })
        .addCase(updateSantri.rejected,(state,action)=>{
            state.loadingSantri = false
            state.msgSantri = action.payload
        })
        .addCase(destroySantri.pending,(state)=>{
            state.loadingSantri = true
        })
        .addCase(destroySantri.fulfilled,(state,action)=>{
            state.loadingSantri = false
            state.msgSantri = action.payload.msg
        })
        .addCase(destroySantri.rejected,(state,action)=>{
            state.loadingSantri = false
            state.msgSantri = action.payload
        })
        .addCase(addUserMobile.pending,(state)=>{
            state.loadingSantri = true
        })
        .addCase(addUserMobile.fulfilled,(state,action)=>{
            state.loadingSantri = false
            state.msgSantri = action.payload.msg
        })
        .addCase(addUserMobile.rejected,(state,action)=>{
            state.loadingSantri = false
            state.msgSantri = action.payload
        })
        .addCase(addMutasiSantri.pending,(state)=>{
            state.loadingSantri = true
        })
        .addCase(addMutasiSantri.fulfilled,(state,action)=>{
            state.loadingSantri = false
            state.msgSantri = action.payload.msg
        })
        .addCase(addMutasiSantri.rejected,(state,action)=>{
            state.loadingSantri = false
            state.msgSantri = action.payload
        })
        .addCase(findAyah.pending,(state)=>{
            state.loadingSantri = true
        })
        .addCase(findAyah.fulfilled,(state,action)=>{
            state.loadingSantri = false
            state.ayahOne = action.payload
        })
        .addCase(findAyah.rejected,(state,action)=>{
            state.loadingSantri = false
            state.msgSantri = action.payload
        })
        .addCase(findIbu.pending,(state)=>{
            state.loadingSantri = true
        })
        .addCase(findIbu.fulfilled,(state,action)=>{
            state.loadingSantri = false
            state.ibuOne = action.payload
        })
        .addCase(findIbu.rejected,(state,action)=>{
            state.loadingSantri = false
            state.msgSantri = action.payload
        })
    }
})
export const santriActions = santriStore.actions
export default santriStore.reducer