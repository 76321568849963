import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Dialog from './Dialog'
import * as yup from "yup"
import Input from './Input'

function DialogMutasi(props) {
    const methodMutasi = useForm({
        mode:"all",
        resolver:yupResolver(
            yup.object().shape({
                ket:yup.string().required("tidak boleh kosong")
            })
        )
    })
    useEffect(()=>{
        methodMutasi.reset()
    },[props.show])
    return (
        <Dialog show={props.show} toggle={props.close}>
            <div className='bg-white md:w-[60vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>{props.msg}</h1>
                </div>
                <form onSubmit={methodMutasi.handleSubmit(props.submit)} className='p-5 w-full space-y-5 '>
                    <Input label="Keterangan" method={methodMutasi} methodName="ket"/>
                    <button type="submit" className='border border-sky-400 w-full  px-8 py-2 hover:text-sky-800 hover:border-sky-600 transition-colors ease-in-out duration-300'>Simpan</button>
                </form>
            </div>
        </Dialog>)
}

export default DialogMutasi