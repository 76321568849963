import React, { useEffect, useState } from "react";
import HeaderUi from "../component/HeaderUi";
import Table from "../component/Table";
import { useDispatch, useSelector } from "react-redux";
import { addBelanja, allBelanjaByNuwb } from "../store/actions/belanja";
import { useParams } from "react-router-dom";
import { currency } from "../component/Currency";
import moment from "moment";
import FormAddBelanja from "../component/FormAddBelanja";
import Loader from "../component/Loader";
import SnackBar from "../component/SnackBar";
import { convertToRupiah } from "../component/FormatUang";

function BelanjaSantri() {
  const dispatch = useDispatch();
  const params = useParams();
  const { belanjaAll, loadingBelanja, msgBelanja } = useSelector(
    (state) => state.belanja
  );
  const [showFormAddBelanja, setShowFormAddBelanja] = useState(false);
  const [showAlertBelanja, setShowAlertBelanja] = useState(false);
  const sendBelanja = (e) => {
    e.belanja = convertToRupiah(e.belanja)
    dispatch(addBelanja(e))
    setShowFormAddBelanja(false)
    setShowAlertBelanja(true)
  };
  useEffect(() => {
    dispatch(allBelanjaByNuwb(params?.nuwb));
  }, [showAlertBelanja]);
  return (
    <div>
      <HeaderUi titleHeader="Belanja Santri" />
      <Loader show={loadingBelanja} />
      <SnackBar
        show={showAlertBelanja}
        toggle={setShowAlertBelanja}
        msg={msgBelanja}
      />
      <FormAddBelanja
        showForm={showFormAddBelanja}
        closeForm={setShowFormAddBelanja}
        nuwb={params?.nuwb}
        submit={sendBelanja}
      />
      <Table classFirst="hidden">
        <div>
          <div className={`flex justify-end space-x-2`}>
            <button
              className="border border-blue-500 px-8 py-1 text-blue-500 hover:border-blue-400 hover:text-blue-400 transition-colors ease-in-out duration-300"
              onClick={() => setShowFormAddBelanja(true)}
            >
              Ambil Uang Belanja
            </button>
          </div>
          {Object.keys(belanjaAll).length !== 0 ? (
            <div>
              <div className="grid grid-cols-4 gap-4">
                <div className="flex border-b border-b-gray-600 space-x-2 text-lg font-light px-3">
                  <h1 className="font-semibold">Nuwb</h1>
                  <h1>:</h1>
                  <h1>{belanjaAll.nuwb}</h1>
                </div>
                <div className="flex border-b border-b-gray-600 space-x-2 text-lg font-light px-3">
                  <h1 className="font-semibold">Nama</h1>
                  <h1>:</h1>
                  <h1>{belanjaAll.nama}</h1>
                </div>
                <div className="flex border-b border-b-gray-600 space-x-2 text-lg font-light px-3">
                  <h1 className="font-semibold">Total belanja</h1>
                  <h1>:</h1>
                  <h1>
                    {belanjaAll.total_belanja !== null
                      ? currency(belanjaAll.total_belanja?.total)
                      : currency(0)}
                  </h1>
                </div>
              </div>
              <div className="bg-white">
                {belanjaAll?.belanjas?.length === 0 ? (
                  <div>
                    <h1 className="font-light text-center mt-10 text-xl">
                      Tidak Ada Riwayat
                    </h1>
                  </div>
                ) : (
                  <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden z-0">
                    <table className="w-full text-sm text-left text-gray-500 ">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            No
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Jumlah
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Status
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Tanggal Pengambilan
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Metode
                          </th>
                          <th scope="col" className="px-6 py-3">
                            User
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {belanjaAll?.belanjas?.map((d, id) => (
                          <tr
                            className="bg-white border-b hover:bg-gray-100"
                            key={id}
                          >
                            <td className="px-6 py-3">{id + 1}</td>
                            <td className="px-6 py-3">{d.jumlah !== null ? currency(d.jumlah) : currency(0)}</td>
                            <td className="px-6 py-3">{d.status === "k" ? "Kredit" : "Debit"}</td>
                            <td className="px-6 py-3">
                              {moment(d.createdAt).format("YYYY-MM-DD hh:mm")}
                            </td>
                            <td className="px-6 py-3">{d.metode}</td>
                            <td className="px-6 py-3">{d.user?.nama}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Table>
    </div>
  );
}

export default BelanjaSantri;
