import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getAllDiskonBersaudara,
  getAllDiskonBersaudaraByTahun,
} from "../store/actions/diskon";

export default function useGetAllDiskonBersaudara(tahun, trigger) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (tahun) {
      dispatch(getAllDiskonBersaudaraByTahun(tahun?.replace("/", "-")));
    } else {
      dispatch(getAllDiskonBersaudara());
    }
  }, [trigger, tahun]);
}
