import api from "./http";

const ApiBersaudara = {
    get :(nuwb) =>{
        return api.get(`/cakepin/bersaudara/${nuwb}`)
    },
    post : (data) =>{
        return api.post(`/cakepin/bersaudara`,data)
    },
    editKk : (data) =>{
        return api.post(`/cakepin/bersaudara/restructur/bersaudara`,data)
    }
}
export default ApiBersaudara