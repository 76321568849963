import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getThnAjar } from "../store/actions/optional";

export default function useGetTahun(trigger) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getThnAjar());
  }, [trigger]);
}
