import React from "react";
import Dialog from "./Dialog";

function DialogNormalisasi(props) {
  return (
    <Dialog show={props.show} toggle={props.close}>
      <div className="bg-white md:w-[60vw] w-[95vw]">
        <div className="py-4 px-6 border-b border-slate-200">
          <h1 className="text-xl">{props.msg}</h1>
        </div>
        <button
          onClick={props.click}
          className="m-5 border border-sky-400 w-full  px-8 py-2 hover:text-sky-800 hover:border-sky-600 transition-colors ease-in-out duration-300"
        >
          Simpan
        </button>
      </div>
    </Dialog>
  );
}

export default DialogNormalisasi;
