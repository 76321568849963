import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiBelanja } from "../../api/belanja";

export const allBelanjaByNuwb = createAsyncThunk(
    'belanja/santri/nuwb',
    async(nuwb,{rejectWithValue})=>{
        try{
            const res = await ApiBelanja.getAll(nuwb)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)

export const addBelanja =  createAsyncThunk(
    'belanja/post',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiBelanja.post(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)