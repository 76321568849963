import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiAuth from "../../api/auth";

export const islogin = createAsyncThunk(
    'auth/login',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiAuth.login(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const islogout = createAsyncThunk(
    'auth/logout',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiAuth.logout()
            if(res.status === 200){
                return res.data

            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)