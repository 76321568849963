import { yupResolver } from '@hookform/resolvers/yup'
import { Input } from 'postcss'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import AddButton from './AddButton'
import Dialog from './Dialog'
import Radio from './Radio'

function FormDeletePermanen(props) {
    const methodPermanen = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                uuid_process: yup.string().required(),
                status: yup.boolean().required("Tidak Boleh Kosong")
            })
        )
    })
    useEffect(() => {
        if (props.uuid !== "") {
            methodPermanen.reset({ uuid_process: props.uuid, status: null })
        }
    }, [props.uuid, props.showForm])
    return (
        <Dialog show={props.showForm} toggle={props.closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Delete Permanen Pembayaran</h1>
                </div>
                <form onSubmit={methodPermanen.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                    <div>
                        <h1 className='font-light text-lg mb-2'>Setuju Penghapusan</h1>
                        <div className='flex my-auto md:space-x-0 space-x-2'>
                            <Radio method={methodPermanen} methodName="status" value="true" label="Iya" />
                            <Radio method={methodPermanen} methodName="status" value="false" label="Tidak" />
                        </div>
                        {methodPermanen.formState.errors["status"] && (
                            <p className="text-red-600 mt-1 mx-1">
                                {methodPermanen.formState.errors["status"].message}
                            </p>
                        )}
                    </div>
                    <AddButton />
                </form>
            </div>
        </Dialog>
    )
}

export default FormDeletePermanen