import React, { useEffect, useState } from "react";
import { GiMoneyStack } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeaderUi from "../component/HeaderUi";
import Table from "../component/Table";
import { allAlumniByKey } from "../store/actions/alumni";
import OptionsField from "../component/OptionsField";
import { getLembaga } from "../store/actions/optional";
import { getAllDonatur } from "../store/actions/donatur";
import Button from "../component/Button";
import { getSubsidiByLembagaAndDonatur } from "../store/actions/subsidi";
import { currency } from "../component/Currency";
import Loader from "../component/Loader";

function Subsidi() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allSubsidi, loadingSubsidi } = useSelector((state) => state.subsidi);
  const { lembaga } = useSelector((state) => state.optional);
  const { allDonatur } = useSelector((state) => state.donatur);
  const [saveLembaga, setSaveLembaga] = useState({});
  const [saveDonatur, setSaveDonatur] = useState({});
  const [saveAlumni, setSaveAlumni] = useState({});
  const lembagaOptions = lembaga.map((e) => {
    return { value: e.kode_lembaga, label: e.nama };
  });
  lembagaOptions.splice(0, 0, { value: "-", label: "SEMUA" });
  const donaturOptions = allDonatur.map((e) => {
    return { value: e.uuid, label: e.nama };
  });
  donaturOptions.splice(0, 0, { value: "-", label: "SEMUA" });
  const alumniOptions = [
    { value: "-", label: "SEMUA" },
    { value: "2", label: "Iya" },
    { value: "1", label: "Tidak" },
  ];
  useEffect(() => {
    dispatch(getLembaga());
    dispatch(getAllDonatur());
  }, []);
  const filterSubsidi = () => {
    if (
      Object.keys(saveLembaga).length !== 0 &&
      Object.keys(saveDonatur).length !== 0 &&
      Object.keys(saveAlumni).length !== 0
    ) {
      console.log(saveLembaga.value, saveDonatur.value);
      dispatch(
        getSubsidiByLembagaAndDonatur({
          kode_lembaga: saveLembaga.value,
          uuid_donatur: saveDonatur.value,
          alumni: saveAlumni.value,
        })
      );
    }
  };
  return (
    <div>
      <HeaderUi titleHeader="Subsidi" location="List" />
      <Loader show={loadingSubsidi} />
      <div className="bg-white m-5 p-5 flex flex-col shadow-lg"></div>
      <Table classFirst="hidden">
        <div className="flex justify-between">
          <div className="md:flex md:flex-row flex-col md:space-x-3">
            <div className="w-72">
              <OptionsField
                label="Lembaga"
                value={saveLembaga}
                onChange={(e) => setSaveLembaga(e)}
                options={lembagaOptions}
              />
            </div>
            <div className="w-72">
              <OptionsField
                label="Donatur"
                value={saveDonatur}
                onChange={(e) => setSaveDonatur(e)}
                options={donaturOptions}
              />
            </div>
            <div className="w-72">
              <OptionsField
                label="Alumni"
                value={saveAlumni}
                onChange={(e) => setSaveAlumni(e)}
                options={alumniOptions}
              />
            </div>
          </div>
          <div className="flex justify-end items-end">
            <button
              className="border border-sky-500 px-10 py-2"
              onClick={filterSubsidi}
            >
              Saring
            </button>
          </div>
        </div>
        <div className="bg-white">
          {allSubsidi.length === 0 ? (
            <div>
              <h1 className="font-light text-center my-10 text-xl">
                Tidak Ada Subsidi
              </h1>
            </div>
          ) : (
            <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      No
                    </th>
                    <th scope="col" className="px-6 py-3">
                      NUWB
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Nama
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Lembaga
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Tahun Lulus
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Donatur
                    </th>
                    {/* <th scope="col" className="px-6 py-3">
                      Golongan Donatur
                    </th> */}
                    <th scope="col" className="px-6 py-3">
                      Tagihan Donatur/Bulan
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allSubsidi.map((d, id) => (
                    <tr
                      className="bg-white border-b hover:bg-gray-100"
                      key={id}
                    >
                      <td className="px-6 py-3">{id + 1}</td>
                      <td className="px-6 py-3">{d.nuwb}</td>
                      <td className="px-6 py-3">{d.nama}</td>
                      <td className="px-6 py-3">{d.lembaga?.nama}</td>
                      <td className="px-6 py-3">
                        {d.status_santri?.tahun_lulus === 0
                          ? "-"
                          : d.status_santri?.tahun_lulus}
                      </td>
                      <td className="px-6 py-3">
                        {d.status_santri?.status === 1
                          ? "Aktif"
                          : d.status_santri?.status === 2
                          ? "Lulus"
                          : "Lain-lain"}
                      </td>
                      <td className="px-6 py-3">
                        {d.status_santri.list_donatur.nama}
                      </td>
                      {/* <td className="px-6 py-3">
                        {d.status_santri?.list_donatur?.donatur[1]?.golongan}
                      </td> */}
                      <td className="px-6 py-3">
                        {d.status_santri.potongan_donatur
                          ? currency(d.status_santri?.potongan_donatur)
                          : 0}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Table>
    </div>
  );
}

export default Subsidi;
