import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRuang } from "../store/actions/optional";

export default function useGetRuangOptions(kelas) {
  const [ruangOptions, setRuangOptions] = useState([]);
  const dispatch = useDispatch();
  const { ruang } = useSelector((state) => state.optional);
  useEffect(() => {
    if (kelas) {
      dispatch(getRuang(kelas));
    }
  }, [kelas]);
  useEffect(() => {
    if (ruang.length > 0) {
      const data = ruang.map((value) => {
        return { value: value.ruang, label: value.ruang };
      });
      setRuangOptions(data);
    }
  }, [ruang]);
  return ruangOptions;
}
