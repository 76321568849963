import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiSpp from "../../api/spp";

export const allSpp = createAsyncThunk(
    'spp/all',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiSpp.getAll()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const postSpp = createAsyncThunk(
    'spp/add',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiSpp.add(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getOneSppByThnAjr = createAsyncThunk(
    'spp/thnAjr',
    async(thn,{rejectWithValue})=>{
        try{
            const res = await ApiSpp.getByTahunAjar(thn)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const updateListSpp = createAsyncThunk(
    'spp/update',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiSpp.update(data.uuid,data.update)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const createTagihan = createAsyncThunk(
    'tagian/generate',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiSpp.generateTagihan(data)
            if(res.status === 200){
                return res.data
            }else if(res.status === 201){
                window.location.href = `/home/panel/bersaudara/${res.data?.msg}`;
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const createTahun = createAsyncThunk(
    'tahun/post',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiSpp.addTahun(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getOneTagihan = createAsyncThunk(
    'tagihan/one',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiSpp.getTagihan(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const updateOneTagihan = createAsyncThunk(
    'tagihan/update/one',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiSpp.editTagihan(data.uuid,data.update)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const upByUuid = createAsyncThunk(
    'up/get',
    async(uuid,{rejectWithValue})=>{
        try{
            const res = await ApiSpp.getDataUp(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)

export const normalisasiSpp = createAsyncThunk(
    'normalisasi/spp/santri',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiSpp.putNormalisasi(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)