import api from "./http";

const ApiDiskonBersaudara = {
  getAll: () => {
    return api.get(`/cakepin/list-diskon-bersaudara`);
  },
  getByYear: (year) => {
    return api.get(`/cakepin/list-diskon-bersaudara/${year}`);
  },
  add: (data) => {
    return api.post(`/cakepin/list-diskon-bersaudara`, data);
  },
  update: (id, data) => {
    return api.put(`/cakepin/list-diskon-bersaudara/${id}`, data);
  },
  delete: (id) => {
    return api.delete(`/cakepin/list-diskon-bersaudara/${id}`);
  },
};

export default ApiDiskonBersaudara;
