import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Table from "../component/Table";
import {
  editNoKkBersaudara,
  getAllBersaudara,
  postPotonganBersaudara,
} from "../store/actions/bersaudara";
import HeaderUi from "../component/HeaderUi";
import SnackBar from "../component/SnackBar";
import Loader from "../component/Loader";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import DialogKk from "../component/DialogKk";
import DialogDelete from "../component/DialogDelete";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { bersaudaraActions } from "../store/slice/bersaudara";

function Bersaudara() {
  const param = useParams();
  const dispatch = useDispatch();
  const [save, setSave] = useState({});
  const [showdialogKK, setShowDialogKK] = useState(false);
  const [snackBersaudara, setSnackBersaudara] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const { allBersaudara, loadingBersaudara, msgBersaudara, status } =
    useSelector((state) => state.bersaudara);
  useEffect(() => {
    if (param.nuwb !== undefined && param.nuwb !== "") {
      dispatch(getAllBersaudara(param.nuwb));
    }
  }, [param.nuwb, snackBersaudara]);
  const proses = () => {
    setShowConfirm(false);
    dispatch(postPotonganBersaudara({ no_kk: allBersaudara[0].no_kk }));
  };
  useEffect(() => {
    if (status === "succes" || status === "error") {
      setSnackBersaudara(true);
      dispatch(bersaudaraActions.clearStatus());
    }
  }, [status]);
  const editNoKk = (r) => {
    dispatch(editNoKkBersaudara(r));
    setShowDialogKK(false);
    setSnackBersaudara(true);
  };
  return (
    <div>
      <HeaderUi titleHeader="Bersaudara" location="list" />
      <Loader show={loadingBersaudara} />
      <SnackBar
        show={snackBersaudara}
        toggle={setSnackBersaudara}
        msg={msgBersaudara}
      />
      <DialogKk
        showForm={showdialogKK}
        closeForm={setShowDialogKK}
        data={save}
        submit={editNoKk}
      />
      <DialogDelete
        approve="Anda yakin ingin menyetujui proses ini?"
        show={showConfirm}
        close={setShowConfirm}
        onClick={proses}
      />
      <Table classFirst="hidden">
        <div
          className={`flex justify-end space-x-2 ${
            allBersaudara.length !== 0 ? "block" : "hidden"
          }`}
        >
          <button
            className="border border-blue-500 px-8 py-1 text-blue-500 hover:border-blue-400 hover:text-blue-400 transition-colors ease-in-out duration-300"
            onClick={() => setShowConfirm(true)}
          >
            Proses
          </button>
        </div>
        {allBersaudara.length === 0 ? (
          <div>
            <h1 className="font-light text-center my-10 text-xl">
              Santri Ini Tidak Memiliki Saudara
            </h1>
          </div>
        ) : (
          <div className=" overflow-x-auto shadow-md sm:rounded-lg my-5 overflow-y-hidden z-0">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    No
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Tagihan
                  </th>
                  <th scope="col" className="px-6 py-3">
                    No KK
                  </th>
                  <th scope="col" className="px-6 py-3">
                    NUWB
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Nama
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Lembaga
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Kelas
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Ruang
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Edit KK
                  </th>
                </tr>
              </thead>
              <tbody>
                {allBersaudara.map((d, id) => {
                  return (
                    <tr
                      className="bg-white border-b hover:bg-gray-100"
                      key={id}
                    >
                      <td className="px-6 py-3">{id + 1}</td>
                      <td className="px-6 py-3">
                        {d.list_spp_santris?.uuid ? (
                          <IoCheckmarkDoneSharp className="w-6 h-6 text-blue-700" />
                        ) : (
                          <RxCross2 className="w-6 h-6 text-red-700" />
                        )}
                      </td>
                      <td className="px-6 py-3">{d.no_kk}</td>
                      <td className="px-6 py-3">{d.nuwb}</td>
                      <td className="px-6 py-3">{d.nama}</td>
                      <td className="px-6 py-3">{d.lembaga?.nama}</td>
                      <td className="px-6 py-3">{d.kelas}</td>
                      <td className="px-6 py-3">{d.ruang}</td>
                      <td className="px-6 py-3">
                        {d.status_santri?.status === 1
                          ? "Aktif"
                          : "Sudah Lulus"}
                      </td>
                      <td className="px-6 py-3">
                        <HiOutlinePencilSquare
                          className="w-6 h-6 mx-3 cursor-pointer"
                          onClick={() => {
                            setShowDialogKK(true);
                            setSave(d);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Table>
    </div>
  );
}

export default Bersaudara;
