import { useSelector } from "react-redux";
import useGetSistem from "../hooks/useGetSistem";
import { useEffect, useState } from "react";

export default function useSistemOptions(trigger) {
  const { sistem } = useSelector((state) => state.optional);
  const [sistemOptions, setSistemOptions] = useState([]);
  useGetSistem(trigger);
  useEffect(() => {
    if (sistem?.length > 0) {
      setSistemOptions(
        sistem?.map((value) => {
          return { value: value.nama_sistem, label: value.nama_sistem };
      }))
    }
  }, [sistem]);
  return sistemOptions
}
