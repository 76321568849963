export const pendidikan = [
    { value: "S3", label: "S3" },
    { value: "S2", label: "S2" },
    { value: "S1", label: "S1" },
    { value: "SMA/Sederajat", label: "SMA/Sederajat" },
    { value: "SMP/Sederajat", label: "SMP/Sederajat" },
    { value: "SD/Sederajat", label: "SD/Sederajat" },
    { value: "D4", label: "D4" },
    { value: "D3", label: "D3" },
    { value: "D2", label: "D2" },
    { value: "D1", label: "D1" },
    { value: "Informal", label: "Informal" },
    { value: "Non Formal", label: "Non Formal" },
    { value: "Paket A", label: "Paket A" },
    { value: "Paket B", label: "Paket B" },
    { value: "Paket C", label: "paket C" },
    { value: "Paud", label: "Paud" },
    { value: "RK/Sederajat", label: "RK/Sederajat" },
    { value: "Putus SD", label: "Putus SD" },
    { value: "Tidak Sekolah", label: "Tidak Sekolah" },
    { value: "Lainnya", label: "Lainnya" },
]
export const pekerjaan = [
    { value: "Buruh", label: "Buruh" },
    { value: "Karyawan Swasta", label: "Karyawan Swasta" },
    { value: "Nelayan", label: "Nelayan" },
    { value: "Pedagang Kecil", label: "Pedagang Kecil" },
    { value: "Pedagang Besar", label: "Pedagang Besar" },
    { value: "Pensiunan", label: "Pensiunan" },
    { value: "Petani", label: "Petani" },
    { value: "Peternak", label: "Peternak" },
    { value: "PNS", label: "PNS" },
    { value: "TNI", label: "TNI" },
    { value: "Polri", label: "Polri" },
    { value: "Tenaga Kerja Indonesia", label: "Tenaga Kerja Indonesia" },
    { value: "Wiraswasta", label: "Wiraswasta" },
    { value: "Wirausaha", label: "Wirausaha" },
    { value: "Tidak Bekerja", label: "Tidak Bekerja" },
    { value: "Lainnya", label: "Lainnya" },

]
export const penghasilan = [
    { value: "kurang dari Rp.500.000", label: "kurang dari Rp.500.000" },
    { value: "Rp. 500.000 - Rp. 999.999", label: "Rp. 500.000 - Rp. 999.999" },
    { value: "Rp. 1.000.000 - Rp. 1.999.999", label: "Rp. 1.000.000 - Rp. 1.999.999" },
    { value: "Rp. 2.000.000 - Rp. 4.999.999", label: "Rp. 2.000.000 - Rp. 4.999.999" },
    { value: "Rp. 5.000.000 - Rp. 9.999.999", label: "Rp. 5.000.000 - Rp. 9.999.999" },
    { value: "Rp. 10.000.000 - Rp. 20.000.000", label: "Rp. 10.000.000 - Rp. 20.000.000" },
    { value: "Lebih Dari Rp. 20.000.000", label: "Lebih Dari Rp. 20.000.000" },
    { value: "Tidak Dapat Diterapkan", label: "Tidak Dapat Diterapkan" },
    { value: "Tidak Berpenghasilan", label: "Tidak Berpenghasilan" },
]
export const status = [
    { value: "Masih Hidup", label: "Masih Hidup" },
    { value: "Sudah Meninggal", label: "Sudah Meninggal" },
    { value: "Tidak Diketahui", label: "Tidak Diketahui" },
]
export const bulanOpt = [
    { value: 1, label: "Januari" },
    { value: 2, label: "Februari" },
    { value: 3, label: "Maret" },
    { value: 4, label: "April" },
    { value: 5, label: "Mei" },
    { value: 6, label: "Juni" },
    { value: 7, label: "Juli" },
    { value: 8, label: "Agustus" },
    { value: 9, label: "September" },
    { value: 10, label: "Oktober" },
    { value: 11, label: "November" },
    { value: 12, label: "Desember" },
]
export const bulanTagihanOpt = [
    { value: 0, label: "Juli" },
    { value: 1, label: "Agustus" },
    { value: 2, label: "September" },
    { value: 3, label: "Oktober" },
    { value: 4, label: "November" },
    { value: 5, label: "Desember" },
    { value: 6, label: "Januari" },
    { value: 7, label: "Februari" },
    { value: 8, label: "Maret" },
    { value: 9, label: "April" },
    { value: 10, label: "Mei" },
    { value: 11, label: "Juni" },

]
export const bulanTunggakanOpt = [
    { value: "jul", label: "Juli" },
    { value: "agus", label: "Agustus" },
    { value: "sept", label: "September" },
    { value: "okt", label: "Oktober" },
    { value: "nov", label: "November" },
    { value: "des", label: "Desember" },
    { value: "jan", label: "Januari" },
    { value: "feb", label: "Februari" },
    { value: "mar", label: "Maret" },
    { value: "apr", label: "April" },
    { value: "mei", label: "Mei" },
    { value: "jun", label: "Juni" },

]
