import api from "./http";


export const ApiGambar = {
    post : (data,onUploadProgress)=>{
        return api.post(`/billboard`,data,{
            "Content-Type": "multiple/form data",
            onUploadProgress,
        })
    },
    postToDb : (data)=>{
        return api.post(`/billboard/create`,data)
    },
    get : () =>{
        return api.get("/billboard")
    },
    put : (uuid,data)=>{
        return api.put(`/billboard/${uuid}`,data)
    }
}