import { createSlice } from "@reduxjs/toolkit";
import { addUser, allUserReport, destroySistem, destroyUser, editByAdmin, editSelf, editSistemOne, getAllUser, getAllUserBySistem, postSistem } from "../actions/user";

export const userStore = createSlice({
    name:"user",
    initialState : {
        allUser : [],
        oneUser : {},
        allReport : [],
        loadingUser : false,
        msgUser : ""
    },
    extraReducers : builder =>{
        builder
        .addCase(getAllUser.pending,(state)=>{
            state.loadingUser = true
        })
        .addCase(getAllUser.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.allUser = action.payload
        })
        .addCase(getAllUser.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
        })
        .addCase(getAllUserBySistem.pending,(state)=>{
            state.loadingUser = true
        })
        .addCase(getAllUserBySistem.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.allUser = action.payload
        })
        .addCase(getAllUserBySistem.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
        })
        .addCase(addUser.pending,(state)=>{
            state.loadingUser = true
        })
        .addCase(addUser.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.msg
        })
        .addCase(addUser.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
        })
        .addCase(editSelf.pending,(state)=>{
            state.loadingUser = true
        })
        .addCase(editSelf.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.msg
        })
        .addCase(editSelf.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
        })
        .addCase(editByAdmin.pending,(state)=>{
            state.loadingUser = true
        })
        .addCase(editByAdmin.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.msg
        })
        .addCase(editByAdmin.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
        })
        .addCase(destroyUser.pending,(state)=>{
            state.loadingUser = true
        })
        .addCase(destroyUser.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.msg
        })
        .addCase(destroyUser.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.msg
        })
        .addCase(postSistem.pending,(state)=>{
            state.loadingUser = false
        })
        .addCase(postSistem.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.msg
        })
        .addCase(postSistem.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
        })
        .addCase(destroySistem.pending,(state)=>{
            state.loadingUser = false
        })
        .addCase(destroySistem.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.msg
        })
        .addCase(destroySistem.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
        })
        .addCase(allUserReport.pending,(state)=>{
            state.loadingUser = false
        })
        .addCase(allUserReport.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.allReport = action.payload
        })
        .addCase(allUserReport.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
        })
        .addCase(editSistemOne.pending,(state)=>{
            state.loadingUser = false
        })
        .addCase(editSistemOne.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.msg
        })
        .addCase(editSistemOne.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
        })



    }

})
export default userStore.reducer