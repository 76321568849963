import { createSlice } from "@reduxjs/toolkit";
import {
  addDiskonBersaudara,
  deleteDiskonBersaudara,
  getAllDiskonBersaudara,
  getAllDiskonBersaudaraByTahun,
  updateDiskonBersaudara,
} from "../actions/diskon";

export const diskonStore = createSlice({
  name: "diskon",
  initialState: {
    diskonAll: [],
    loadingDiskon: false,
    msgDiskon: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDiskonBersaudara.pending, (state) => {
        state.loadingDiskon = true;
      })
      .addCase(getAllDiskonBersaudara.fulfilled, (state, action) => {
        state.loadingDiskon = false;
        state.diskonAll = action.payload;
      })
      .addCase(getAllDiskonBersaudara.rejected, (state, action) => {
        state.loadingDiskon = false;
        state.diskonAll = [];
        state.msgDiskon = action.payload;
      })
      .addCase(getAllDiskonBersaudaraByTahun.pending, (state) => {
        state.loadingDiskon = true;
      })
      .addCase(getAllDiskonBersaudaraByTahun.fulfilled, (state, action) => {
        state.loadingDiskon = false;
        state.diskonAll = action.payload;
      })
      .addCase(getAllDiskonBersaudaraByTahun.rejected, (state, action) => {
        state.loadingDiskon = false;
        state.diskonAll = [];
        state.msgDiskon = action.payload;
      })
      .addCase(addDiskonBersaudara.pending, (state) => {
        state.loadingDiskon = true;
      })
      .addCase(addDiskonBersaudara.fulfilled, (state, action) => {
        state.loadingDiskon = false;
        state.msgDiskon = action.payload?.msg;
      })
      .addCase(addDiskonBersaudara.rejected, (state, action) => {
        state.loadingDiskon = false;
        state.diskonAll = [];
        state.msgDiskon = action.payload;
      })
      .addCase(updateDiskonBersaudara.pending, (state) => {
        state.loadingDiskon = true;
      })
      .addCase(updateDiskonBersaudara.fulfilled, (state, action) => {
        state.loadingDiskon = false;
        state.msgDiskon = action.payload?.msg;
      })
      .addCase(updateDiskonBersaudara.rejected, (state, action) => {
        state.loadingDiskon = false;
        state.diskonAll = [];
        state.msgDiskon = action.payload;
      })
      .addCase(deleteDiskonBersaudara.pending, (state) => {
        state.loadingDiskon = true;
      })
      .addCase(deleteDiskonBersaudara.fulfilled, (state, action) => {
        state.loadingDiskon = false;
        state.msgDiskon = action.payload?.msg;
      })
      .addCase(deleteDiskonBersaudara.rejected, (state, action) => {
        state.loadingDiskon = false;
        state.diskonAll = [];
        state.msgDiskon = action.payload;
      });
  },
});
export default diskonStore.reducer;
