import React, { useEffect, useState } from 'react'
import { HiOutlineChevronDown, HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import FormAddUser from '../component/FormAddUser'
import FormEditUser from '../component/FormEditUser'
import HeaderUi from '../component/HeaderUi'
import DialogDelete from '../component/DialogDelete'
import Loader from '../component/Loader'
import SnackBar from '../component/SnackBar'
import Table from '../component/Table'
import { addUser, destroyUser, editByAdmin, getAllUser, postSistem } from '../store/actions/user'
import FormEditSistem from '../component/FormEditSistem'
import FormAddSistem from '../component/FormAddSistem'

function TableUser() {
    const [formAddUser, setFormAddUser] = useState(false)
    const [editUser, setEditUser] = useState(false)
    const [formDeleteUser, setFormDeleteUser] = useState(false)
    const [snack, setSnack] = useState(false)
    const [formSistem, setFormSistem] = useState(false)
    const [dropActions, setDropActions] = useState(false)
    const [formAddSystem, setFormAddSystem] = useState(false)
    const [uuid, setUuid] = useState("")
    const [check, setCheck] = useState()
    const [userOne, setUserOne] = useState({})
    const { allUser, msgUser, loadingUser } = useSelector((state) => state.user)
    const { generalUser } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    useEffect(() => {
        if (generalUser) {
            dispatch(getAllUser("-"))
        }
        else {
            dispatch(getAllUser("cakepin"))
        }
    }, [snack, formSistem])
    const add = (e) => {
        dispatch(addUser(e))
        setFormAddUser(!formAddUser)
        setSnack(!snack)
    }
    const edit = (r) => {
        dispatch(editByAdmin({ uuid: userOne.uuid, update: r }))
        setEditUser(!editUser)
        setSnack(!snack)
    }
    const onDelete = () => {
        dispatch(destroyUser(uuid))
        setFormDeleteUser(!formDeleteUser)
        setSnack(!snack)
    }
    const tambahSistem = (e) => {
        dispatch(postSistem({ uuid: uuid, post: e }))
        setFormAddSystem(!formAddSystem)
        setSnack(!snack)
    }
    return (
        <div>
            <HeaderUi titleHeader="Admin" location={`list`} />
            <Loader show={loadingUser} />
            <DialogDelete show={formDeleteUser} close={setFormDeleteUser} onClick={onDelete} />
            <SnackBar show={snack} toggle={setSnack} msg={msgUser} />
            <FormAddUser showForm={formAddUser} closeForm={setFormAddUser} submit={add} />
            <FormEditUser showForm={editUser} closeForm={setEditUser} submit={edit} data={userOne} />
            <FormEditSistem showForm={formSistem} closeForm={setFormSistem} data={userOne} />
            <FormAddSistem showForm={formAddSystem} closeForm={setFormAddSystem} submit={tambahSistem} />
            <Table
                titleFirst="Tambah"
                clickFirst={() => setFormAddUser(!formAddUser)}
            >
                {allUser.length === 0 ? <div>
                    <h1 className='font-light text-center my-10 text-xl'>Tidak Ada User</h1>
                </div> :
                    <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden">
                        <table className="w-full text-sm text-left text-gray-500 ">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        No
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Username
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Sistem
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Role
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Super
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {allUser.map((d, id) => (
                                    <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                                        <td className='px-6 py-3'>{id + 1}</td>
                                        <td className='px-6 py-3'>{d.username}</td>
                                        <td className='px-6 py-3'>{d?.sistem?.map((e, id) => (
                                            <h1 key={id} className=''>{e.nama_sistem}</h1>
                                        ))}</td>
                                        <td className='px-6 py-3'>
                                            {d.sistem.map((f, id) => (
                                                <h1 key={id} className=''>{f.nama_role}</h1>
                                            ))}
                                        </td>
                                        <td className='px-6 py-3'>{d.super_admin ? "Super Admin" : "Admin"}</td>
                                        <td className='px-6 py-3'><HiOutlineChevronDown className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                            setDropActions(!dropActions)
                                            setCheck(id)
                                        }} /><div className={`border absolute shadow-lg bg-slate-100 ease-in-out transition-all duration-200 ${dropActions ? `mt-2 bg-opacity-100 visible` : `-mt-0 text-transparent bg-opacity-0 invisible`} ${id === check ? "block" : "hidden"} `}>
                                                <div className='py-2 px-2 hover:bg-slate-200 cursor-pointer' onClick={() => {
                                                    setDropActions(!dropActions)
                                                    setUserOne(d)
                                                    setEditUser(!editUser)
                                                    setUuid(d.uuid)
                                                }}>
                                                    Edit User
                                                </div>
                                                <div className='py-2 px-2 hover:bg-slate-200 cursor-pointer' onClick={() => {
                                                    setFormAddSystem(!formAddSystem)
                                                    setUuid(d.uuid)
                                                    setDropActions(!dropActions)
                                                }}>
                                                    Tambah Sistem
                                                </div>
                                                <div className='py-2 px-2 hover:bg-slate-200 cursor-pointer' onClick={() => {
                                                    setUserOne(d)
                                                    setUuid(d.uuid)
                                                    setDropActions(!dropActions)
                                                    setFormSistem(!formSistem)
                                                }}>
                                                    Edit Sistem
                                                </div>
                                                <div className='py-2 px-2 hover:bg-slate-200 cursor-pointer' onClick={() => {
                                                    setFormDeleteUser(!formDeleteUser)
                                                    setUuid(d.uuid)
                                                    setDropActions(!dropActions)
                                                }}>
                                                    Delete
                                                </div>
                                            </div></td>
                                        {/* <td className='px-6 py-3'><HiOutlinePencil className='w-6 h-6 cursor-pointer' onClick={() => {
                                            setUserOne(d)
                                            setEditUser(!editUser)
                                        }} /></td>
                                        <td className='px-8 py-3'><HiOutlineTrash className='w-6 h-6 cursor-pointer' onClick={() => {
                                            setFormDeleteUser(!formDeleteUser)
                                            setUuid(d.uuid)
                                        }} /></td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
            </Table>
        </div>
    )
}

export default TableUser