import React, { useEffect, useState } from 'react'
import { HiOutlinePencilSquare, HiOutlineTrash } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import DialogDelete from '../component/DialogDelete'
import FormEditRuang from '../component/FormEditRuang'
import FormRuang from '../component/FormRuang'
import HeaderUi from '../component/HeaderUi'
import OptionsField from '../component/OptionsField'
import SnackBar from '../component/SnackBar'
import Table from '../component/Table'
import { destroyRuang, postRuang, updateRuang } from '../store/actions/lembaga'
import { getKelas, getLembaga, getRuang } from '../store/actions/optional'

function ListRuang() {
    const [formRuang, setFormRuang] = useState(false)
    const [formEdit, setFormEdit] = useState(false)
    const [snack, setSnack] = useState(false)
    const [formDelete, setFormDelete] = useState(false)
    const [lem, setLem] = useState({})
    const [kel, setKel] = useState({})
    const [data, setData] = useState({})
    const { msgLembaga } = useSelector((state) => state.lembaga)
    const { ruang, lembaga, kelas } = useSelector((state) => state.optional)
    const dispatch = useDispatch()
    const add = (e) => {
        dispatch(postRuang(e))
        setFormRuang(!formRuang)
        setSnack(!snack)
    }
    const edit = (e) => {
        dispatch(updateRuang({ id: data.id, update: e }))
        setFormEdit(!formEdit)
        setSnack(!snack)
    }
    const deleted = () => {
        dispatch(destroyRuang(data.id))
        setFormDelete(!formDelete)
        setSnack(!snack)
    }
    const lemOpt = lembaga.map((e) => {
        return { value: e.kode_lembaga, label: e.nama }
    })
    const kelOpt = kelas.map((e) => {
        return { value: e.kelas, label: e.kelas }
    })
    useEffect(() => {
        dispatch(getLembaga())
    }, [])
    useEffect(() => {
        if (lem.value !== "") {
            dispatch(getKelas(lem.value))
        }
    }, [lem, snack])
    useEffect(() => {
        if (kel.value !== "") {
            let find = kelas.find((e)=> e.kelas === kel.value)
            dispatch(getRuang(find?.id))
        }
    }, [kel, snack])
    return (
        <div>
            <HeaderUi titleHeader="Ruang Kelas" location={"list"} />
            <DialogDelete show={formDelete} close={setFormDelete} onClick={deleted} />
            <SnackBar show={snack} toggle={setSnack} msg={msgLembaga} />
            <FormRuang showForm={formRuang} closeForm={setFormRuang} submit={add} />
            <FormEditRuang showForm={formEdit} closeForm={setFormEdit} submit={edit} data={data} />
            <Table
                titleFirst="Tambah"
                clickFirst={() => setFormRuang(!formRuang)}
            >
                <div className='bg-white'>
                    <div className="grid grid-cols-4 gap-4">
                        <OptionsField label="lembaga" onChange={(e) => setLem(e)} value={lem} options={lemOpt} />
                        <OptionsField label="Kelas" onChange={(e) => setKel(e)} value={kel} options={kelOpt} />
                    </div>
                    {ruang.length === 0 ? <div>
                        <h1 className='font-light text-center my-10 text-xl'>Tidak Ada Ruangan</h1>
                    </div> :
                        <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden z-0">
                            <table className="w-full text-sm text-left text-gray-500 ">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            No
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Lembaga
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Kelas
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Ruang
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Edit
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Hapus
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ruang.map((d, id) => (
                                        <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                                            <td className='px-6 py-3'>{id + 1}</td>
                                            <td className='px-6 py-3'>{lem.label}</td>
                                            <td className='px-6 py-3'>{kel.label}</td>
                                            <td className='px-6 py-3'>{d.ruang}</td>
                                            <td className='px-3 py-3'><HiOutlinePencilSquare className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                                setFormEdit(!formEdit)
                                                setData(d)
                                            }} /></td>
                                            <td className='px-3 py-3'><HiOutlineTrash className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                                setFormDelete(!formDelete)
                                                setData(d)
                                            }} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </Table>
        </div>
    )
}

export default ListRuang