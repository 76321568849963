import React from 'react'

function CheckBox(props) {
    return (
        <div className={` ${props.className} flex space-x-1`}>
            <div className='flex'>
                <input type="checkbox" className={`w-4 h-4 mt-[5px]`} checked={props.checked} readOnly={props.read} value={props.value} onChange={props.onChange} />
            </div>
            <label className='font-light text-gray-600 text-lg'>{props.label}</label>

        </div>

    )
}

export default CheckBox