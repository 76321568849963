import api from "./http";

const ApiLaporan = {
    get : (data) =>{
        return api.post(`/cakepin/pembayaran/report`,data)
    },
    getForDonatur : (data) =>{
        return api.post(`/cakepin/pembayaran/report-donatur`,data)
    },
    getForLembaga : (data) =>{
        return api.post(`/cakepin/pembayaran/report-lembaga`,data)
    },
    getLaporanSantri : (data) =>{
        return api.post(`/cakepin/pembayaran/report-santri`,data)
    },
    getLaporanTerhapus : () =>{
        return api.get(`/cakepin/pembayaran/cancel`)
    },
    hapusPembayaranPermanen : (data) =>{
        return api.post(`/cakepin/pembayaran/cancel/approve`,data)
    }
}
export default ApiLaporan