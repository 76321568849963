import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiLaporan from "../../api/laporan";

export const getAllLaporan = createAsyncThunk(
    'laporan/all',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiLaporan.get(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getAllLaporanDonatur = createAsyncThunk(
    'laporan/donatur',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiLaporan.getForDonatur(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getAllLaporanLem = createAsyncThunk(
    'laporan/lembaga',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiLaporan.getForLembaga(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getAllLaporanSantri = createAsyncThunk(
    'laporan/santri',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiLaporan.getLaporanSantri(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getAllLaporanTerhapus = createAsyncThunk(
    'laporan/terhapus/all',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiLaporan.getLaporanTerhapus()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const deleteLaporanPermanen = createAsyncThunk(
    'laporan/hapus/permanen',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiLaporan.hapusPembayaranPermanen(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)

export const emptyStateLaporan = createAsyncThunk(
    'state/empty',
    async()=>{
        return 
    }
)