import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from "yup"
import { getLembagaList, getRole, getSistem } from '../store/actions/optional'
import Options from './Options'
import Dialog from './Dialog'
import AddButton from './AddButton'
import Radio from './Radio'

function FormAddSistem(props) {
    const dispatch = useDispatch()
    const { sistem, role, lembagaList } = useSelector((state) => state.optional)
    const methodAddSistem = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                uuid_sistem: yup.string().required("Tidak Boleh Kosong"),
                uuid_role: yup.string().required("Tidak Boleh Kosong"),
                super_admin : yup.string().oneOf(["true","false"],"Pilih Salah Satu").required("Tidak Boleh Kosong"),
                no_lembaga: yup.string()
            })
        )
    })
    const sistemOpt = sistem.map((d) => {
        return { value: d.uuid, label: d.nama_sistem }
    })
    const roleOpt = role.map((d) => {
        return { value: d.uuid, label: d.nama_role }
    })
    const sistemWatch = methodAddSistem.watch("uuid_sistem")
    const roleWatch = methodAddSistem.watch("uuid_role")
    useEffect(() => {
        dispatch(getSistem())
    }, [])
    const lemOpt = lembagaList.map((d) => {
        return { value: d.no_lembaga, label: d.nama_lembaga }
    })
    useEffect(() => {
        if (sistemWatch !== "") {
            dispatch(getRole(sistemWatch))
        }
    }, [sistemWatch])
    useEffect(() => {
        if (roleWatch !== undefined) {
            dispatch(getLembagaList())
        }
    }, [roleWatch])
    useEffect(() => {
        methodAddSistem.reset({})
    }, [props.showForm])
    return (
        <Dialog show={props.showForm} toggle={props.closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Tambah Sistem Baru</h1>
                </div>
                <form onSubmit={methodAddSistem.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                    <Options label="Sistem" method={methodAddSistem} methodName="uuid_sistem" options={sistemOpt} />
                    <Options label="Role" method={methodAddSistem} methodName="uuid_role" options={roleOpt} />
                    <div className='mb-1'>
                        <h1 className='font-light text-lg mb-2'>Super Admin</h1>
                        <div className='flex my-auto md:space-x-0 space-x-2'>
                            <Radio method={methodAddSistem} methodName={`super_admin`} value="true" label="Iya" />
                            <Radio method={methodAddSistem} methodName={`super_admin`} value="false" label="Tidak" />
                        </div>
                        {methodAddSistem.formState.errors[`super_admin`] && (
                            <p className="text-red-600 mt-1 mx-1">
                                {methodAddSistem.formState.errors[`super_admin`].message}
                            </p>
                        )}
                    </div>
                    <div className={roleWatch === roleOpt.find((e) => e.label === "admin-lembaga")?.value && roleWatch !== undefined ? "block" : "hidden"}>
                        <Options label="Lembaga" method={methodAddSistem} methodName="no_lembaga" options={lemOpt} />
                    </div>
                    <AddButton />
                </form>
            </div>
        </Dialog>

    )
}

export default FormAddSistem