import api from "./http";

const ApiAuth = {
    login : (e) =>{
        return api.post(`/login`,e)
    },
    logout : () =>{
        return api.get(`/logout`)
    }
}
export default ApiAuth