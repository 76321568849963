import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Input from '../component/Input'
import Table from '../component/Table'
import HeaderUi from '../component/HeaderUi'
import { useDispatch, useSelector } from 'react-redux'
import InputField from '../component/InputField'
import { getAllLaporanSantri } from '../store/actions/laporan'
import moment from 'moment'
import { currency } from '../component/Currency'
import Loader from '../component/Loader'
import { HiOutlineTrash } from 'react-icons/hi2'
import FormDeleteLaporan from '../component/FormDeleteLaporan'
import { useNavigate } from 'react-router-dom'


function LaporanSantri() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [nuwb, setNuwb] = useState("")
    const { allLaporanSantri, loadingLaporan } = useSelector((state) => state.laporan)
    const { superAdmin,role } = useSelector((state) => state.auth)
    const [uuid, setUuid] = useState("")
    const [formDeleteLaporan, setFormDeleteLaporan] = useState(false)
    const methodLaporan = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                dari: yup.string().required(),
                sampai: yup.string().required(),
            })
        )
    })
    const getLaporan = (e) => {
        dispatch(getAllLaporanSantri(e))
    }
    let jml = 0
    allLaporanSantri.map((d) => {
        return jml += parseInt(d.jumlah)
    })
    const hapusLaporan = (e) => {
        // console.log(e)
        setFormDeleteLaporan(false)
    }
    return (
        <div>
            <Loader show={loadingLaporan} />
            <HeaderUi titleHeader="Laporan Santri" />
            <FormDeleteLaporan showForm={formDeleteLaporan} closeForm={setFormDeleteLaporan} uuid={uuid} submit={hapusLaporan} />
            <Table
                classFirst="hidden"
            >
                <div>
                    <form onSubmit={methodLaporan.handleSubmit(getLaporan)} className='md:flex md:flex-row flex-col md:space-x-3'>
                        <div className="grid md:grid-cols-4 grid-cols-2 gap-3 w-full">
                            <Input label="Dari" type="date" method={methodLaporan} methodName="dari" />
                            <Input label="Sampai" type="date" method={methodLaporan} methodName="sampai" />
                        </div>
                        <button type='submit' className='border border-blue-500 bg-slate-100 px-8 py-[7px] my-7'>Proses</button>
                        <button type='button' className={`border border-blue-500 bg-slate-100 px-8 py-[7px] my-7 ${role === "superadmin" ? "block":"hidden"}`} onClick={()=>navigate(`/home/laporan/terhapus`)}>Terhapus</button>
                    </form>
                    <div className='flex space-x-2 border-bya'>
                        <h1 className='text-lg font-light'>Total Semua</h1>
                        <h1 className='text-lg'> : </h1>
                        <h1 className='text-lg text-blue-700'>{currency(jml)}</h1>
                    </div>
                    {allLaporanSantri.length === 0 ? <div>
                        <h1 className='font-light text-center my-10 text-xl'>Tidak Ada Laporan</h1>
                    </div> :
                        <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden z-0">
                            <table className="w-full text-sm text-left text-gray-500 ">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            No
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Tanggal
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Nuwb
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Nama
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Keterangan
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Teller
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Metode
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Total
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Delete
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allLaporanSantri.map((d, id) => {
                                        return <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                                            <td className='px-6 py-3'>{id + 1}</td>
                                            <td className='px-6 py-3'>{moment(d.createdAt).format("DD-MM-YYYY hh:mm")}</td>
                                            <td className='px-6 py-3'>{d.santri?.nuwb}</td>
                                            <td className='px-6 py-3'>{d.santri?.nama}</td>
                                            <td className='px-6 py-3'>{d.ket}</td>
                                            <td className='px-6 py-3'>{d.user.nama}</td>
                                            <td className='px-6 py-3'>{d.metode_pembayaran}</td>
                                            <td className='px-6 py-3'>{currency(parseInt(d?.jumlah))}</td>
                                            <td className='px-6 py-3'>{<HiOutlineTrash className='cursor-pointer w-6 h-6 mx-3' onClick={() => {
                                                setUuid(d.uuid)
                                                setFormDeleteLaporan(!formDeleteLaporan)
                                            }} />}</td>

                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </Table>
        </div>
    )
}

export default LaporanSantri