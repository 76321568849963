import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLembaga } from "../store/actions/optional";

export default function useGetLembagaOptions() {
  const [lembagaOptions, setLembagaOptions] = useState([]);
  const dispatch = useDispatch();
  const { lembaga } = useSelector((state) => state.optional);
  useEffect(() => {
    dispatch(getLembaga());
  }, []);
  useEffect(() => {
    if (lembaga.length > 0) {
      const data = lembaga.map((value) => {
        return { value: value.kode_lembaga, label: value.nama };
      });
      setLembagaOptions(data);
    }
  }, [lembaga]);
  return lembagaOptions;
}
