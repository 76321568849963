import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import AddButton from './AddButton'
import Dialog from './Dialog'
import Options from './Options'
import TextArea from './TextArea'
import * as yup from "yup"
import { getLembaga, getThnAjar } from '../store/actions/optional'
import Input from './Input'

function FormEditListSpp(props) {
    const dispatch = useDispatch()
    const { lembaga,thn_ajar } = useSelector((state) => state.optional)
    const methodEditListSpp = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                spp: yup.string().required("Tidak Boleh Kosong"),
                up: yup.string().required("Tidak Boleh Kosong"),
                ket: yup.string().required("Tidak Boleh Kosong"),
                thn_ajar: yup.string().required("Tidak Boleh Kosong"),
                kode_lembaga: yup.string().required("Tidak Boleh Kosong"),
            })
        )
    })
    const lembagaOpt = lembaga.map((e) => {
        return { value: e.kode_lembaga, label: e.nama }
    })
    const tahunOpt = thn_ajar.map((d) => {
        return { value: d.tahun, label: d.tahun }
    })
    useEffect(() => {
        dispatch(getThnAjar())
        dispatch(getLembaga())
    }, [])
    useEffect(() => {
        if (Object.keys(props.data).length !== 0) {
            methodEditListSpp.reset(props.data)
        }
    }, [props.data])
    return (
        <div>
            <Dialog show={props.showForm} toggle={props.closeForm}>
                <div className='bg-white md:w-[40vw] w-[95vw]'>
                    <div className='py-4 px-6 border-b border-slate-200'>
                        <h1 className='text-xl'>Tambah List Spp</h1>
                    </div>
                    <form onSubmit={methodEditListSpp.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                        <Options label="Lembaga" method={methodEditListSpp} methodName="kode_lembaga" options={lembagaOpt} />
                        <Options label="Tahun Ajaran" method={methodEditListSpp} methodName="thn_ajar" options={tahunOpt} />
                        <Input label="Spp" type="number" method={methodEditListSpp} methodName="spp" />
                        <Input label="Up" type="number" method={methodEditListSpp} methodName="up" />
                        <TextArea label="Keterangan" method={methodEditListSpp} methodName="ket" />
                        <AddButton />
                    </form>
                </div>
            </Dialog>
        </div>
    )
}

export default FormEditListSpp