import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Dialog from './Dialog'
import Options from './Options'
import AddButton from './AddButton'
import { useDispatch, useSelector } from 'react-redux'
import { getAllDonatur, hapusOneDonaturTdkTtp, oneDonaturTdkTtpByNuwb } from '../store/actions/donatur'
import { getGolDonatur, getGolDonaturByDon, getGolongan, getThnAjar } from '../store/actions/optional'
import Input from './Input'
import { getOneTagihan } from '../store/actions/spp'
import { useParams } from 'react-router-dom'
import { bulanTagihanOpt } from './OptSelect'
import OptionsField from './OptionsField'
import Radio from './Radio'

function FormDonaturSantriTidakTetap(props) {
    const param = useParams()
    const dispatch = useDispatch()
    const { allDonatur, oneDonaturTtp, oneDonaturTdkTtp, loadingDonatur } = useSelector((state) => state.donatur)
    const { thn_ajar, golDonaturByUid, golongan, loadingOptional } = useSelector((state) => state.optional)
    const { oneTagihan } = useSelector((state) => state.spp)
    const [dariBulan, setDariBulan] = useState({})
    const [sampaiBulan, setSampaiBulan] = useState({})
    const [empty, setEmpty] = useState([])
    const methodDonaturTdkTtp = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                uuid: yup.string().required("Tidak Boleh Kosong"),
                tahun_ajar: yup.string().required("Tidak Boleh Kosong"),
                uuid_donatur: yup.string(),
                donasi: yup.string(),
                id_golongan_donatur: yup.string(),
                dari: yup.number(),
                sampai: yup.number(),
                untuk: yup.string().oneOf(["santri", "donatur"], "pilih salah satu").required("Tidak Boleh Kosong")

            })
        )
    })
    const watchDonatur = methodDonaturTdkTtp.watch("uuid_donatur")
    const watchTahun = methodDonaturTdkTtp.watch("tahun_ajar")
    const watchTetap = methodDonaturTdkTtp.watch("untuk")
    const donOpt = allDonatur.map((d) => {
        return { value: d.uuid, label: d.nama }
    })
    const rmv = donOpt.indexOf(donOpt.find((e) => e.value === oneDonaturTtp.uuid_donatur))
    if (rmv !== -1) {
        donOpt.splice(rmv, 1)
    }
    const thunOpt = thn_ajar.map((d) => {
        return { value: d.tahun, label: d.tahun }
    })
    const golDonOpt = golDonaturByUid.map((d) => {
        if (d.uuid_donatur === watchDonatur) {
            return { value: d.id, label: d.golongan }
        }
    })
    const tujuanOpt = [
        { value: "santri", label: "Santri" },
        { value: "donatur", label: "Donatur" }
    ]
    useEffect(() => {
        if (watchTetap !== undefined) {
            if (watchTetap === "donatur") {
                setDariBulan(bulanTagihanOpt[oneDonaturTtp.dari])
            }
            else {
                setDariBulan({})
            }
        }
    }, [watchTetap])
    useEffect(() => {
        if (Object.keys(oneDonaturTdkTtp).length !== 0 && Object.keys(golDonaturByUid).length !== 0 && props.showForm) {
            if (oneDonaturTdkTtp.uuid_donatur !== null) {
                methodDonaturTdkTtp.reset({ ...oneDonaturTdkTtp, uuid: props.uuid_santri, tahun_ajar: props.tahun })
                setDariBulan(bulanTagihanOpt[oneDonaturTdkTtp.dari])
                setSampaiBulan(bulanTagihanOpt[oneDonaturTdkTtp.sampai])
            }
        }
        if(props.tahun === undefined){
            methodDonaturTdkTtp.setValue("tahun_ajar",watchTahun)
        }
    }, [oneDonaturTdkTtp, golDonaturByUid, loadingDonatur, loadingOptional, props.uuid_santri, props.showForm, props.tahun,watchTahun])
    useEffect(() => {
        if (props.tahun !== "" && props.uuid_santri !== "" && props.tahun !== undefined) {
            methodDonaturTdkTtp.reset({ uuid: props.uuid_santri, tahun_ajar: props.tahun })
            setDariBulan({})
            setSampaiBulan({})
        }
        methodDonaturTdkTtp.setValue("uuid",props.uuid_santri)
    }, [props.tahun, props.uuid_santri])
    useEffect(() => {
        if (watchTahun !== "" && props.lembaga !== "") {
            let chnge = watchTahun?.replace("/", "-")
            dispatch(getGolongan({ thn: chnge, kode: props.lembaga }))
        }
    }, [watchTahun, props.lembaga])
    useEffect(() => {
        if (param.nuwb !== "" && watchTahun !== undefined) {
            dispatch(getOneTagihan({ nuwb: param.nuwb, tahun_ajar: watchTahun }))
        }
    }, [param.nuwb, watchTahun])
    useEffect(() => {
        if (dariBulan?.value !== "" && sampaiBulan?.value !== "") {
            methodDonaturTdkTtp.setValue("dari", dariBulan?.value)
            methodDonaturTdkTtp.setValue("sampai", sampaiBulan?.value)
        }
    }, [dariBulan, sampaiBulan])
    useEffect(() => {
        if (watchDonatur !== "" && watchDonatur !== undefined && watchDonatur !== null && props.showForm) {
            dispatch(getGolDonaturByDon(watchDonatur))
        }
        else if (Object.keys(oneDonaturTdkTtpByNuwb).length !== 0 && props.showForm) {
            if (oneDonaturTdkTtpByNuwb.uuid_donatur !== "") {
                dispatch(getGolDonaturByDon(oneDonaturTdkTtpByNuwb.uuid_donatur))
            }
        }
    }, [watchDonatur, props.showForm, oneDonaturTdkTtpByNuwb])
    useEffect(() => {
        if (props.tahun !== "" && props.tahun !== undefined && props.showForm) {
            dispatch(oneDonaturTdkTtpByNuwb({ nuwb: param.nuwb, tahun_ajar: props.tahun }))
        }
    }, [props.tahun, props.showForm])
    const hapusDonatur = () => {
        dispatch(hapusOneDonaturTdkTtp({ nuwb: param.nuwb, tahun_ajar: watchTahun }))
        props.closeForm(false)
    }
    return (
        <Dialog show={props.showForm} toggle={props.closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw] z-50'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Generate Donatur Tidak Tetap</h1>
                </div>
                <form onSubmit={methodDonaturTdkTtp.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                    <Options label="Tahun Ajar" method={methodDonaturTdkTtp} methodName="tahun_ajar" options={thunOpt} />
                    <div>
                        <Options label="Donatur" method={methodDonaturTdkTtp} methodName="uuid_donatur" options={donOpt} />
                        <Input type="number" label="Donasi" method={methodDonaturTdkTtp} methodName="donasi" />
                        <Options label="Golongan" method={methodDonaturTdkTtp} methodName="id_golongan_donatur" options={golDonOpt[0] !== undefined ? golDonOpt : empty} />
                        <Options label="Tujuan" options={tujuanOpt} method={methodDonaturTdkTtp} methodName="untuk" />
                        <OptionsField label="Dari Bulan" options={bulanTagihanOpt} onChange={(e) => setDariBulan(e)} value={{ value: dariBulan?.value, label: dariBulan?.label }} />
                        <OptionsField label="Sampai Bulan" options={bulanTagihanOpt.slice(parseInt(dariBulan?.value))} onChange={(e) => setSampaiBulan(e)} value={{ value: sampaiBulan?.value, label: sampaiBulan?.label }} />
                    </div>
                    <div className='flex justify-between'>
                        <button type='button' onClick={hapusDonatur} className={`border mt-5 border-red-400 hover:border-red-600 hover:text-red-500 px-8 ${oneDonaturTdkTtp.uuid_donatur !== null ? "block" : "hidden"}`}>Hapus</button>
                        <AddButton />
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default FormDonaturSantriTidakTetap