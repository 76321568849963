import { createSlice } from "@reduxjs/toolkit";
import { getAllSubsidi, getSubsidiByLembagaAndDonatur } from "../actions/subsidi";

const initialState = {
  allSubsidi: [],
  oneSubsidi: {},
  loadingSubsidi: false,
  messageSubsidi: "",
};

export const SubsidiStore = createSlice({
  name: "subsidi",
  initialState,
  reducers: {
    clearSubsidi: (state) => {
      state.allSubsidi = [];
      state.messageSubsidi = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSubsidi.pending, (state) => {
        state.loadingSubsidi = true;
      })
      .addCase(getAllSubsidi.fulfilled, (state, action) => {
        state.loadingSubsidi = false;
        state.allSubsidi = action.payload;
      })
      .addCase(getAllSubsidi.rejected, (state, action) => {
        state.loadingSubsidi = false;
        state.allSubsidi = [];
        state.messageSubsidi = action.payload;
      })
      .addCase(getSubsidiByLembagaAndDonatur.pending, (state) => {
        state.loadingSubsidi = true;
      })
      .addCase(getSubsidiByLembagaAndDonatur.fulfilled, (state, action) => {
        state.loadingSubsidi = false;
        state.allSubsidi = action.payload;
      })
      .addCase(getSubsidiByLembagaAndDonatur.rejected, (state, action) => {
        state.loadingSubsidi = false;
        state.allSubsidi = [];
        state.messageSubsidi = action.payload;
      });
  },
});


export const SubsidiActions = SubsidiStore.actions;
export default SubsidiStore.reducer;
