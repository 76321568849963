import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiGambar } from "../../api/gambar";


export const allGambar = createAsyncThunk(
    'gambar/get',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiGambar.get()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)

export const addGambar = createAsyncThunk(
    'gambar/add',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiGambar.post(data.gambar,data.loading)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const addGambarToDb = createAsyncThunk(
    'gambar/post/db',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiGambar.postToDb(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const updateStatusGambar = createAsyncThunk(
    'gambar/put',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiGambar.put(data.uuid,data.update)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)