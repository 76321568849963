import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "./Input";
import Radio from "./Radio";
import Options from "./Options";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getKabupaten,
  getKecamatan,
  getKelas,
  getLembaga,
  getProvinsi,
  getRuang,
} from "../store/actions/optional";
import useProvinceOptions from "../options/provinsi";
import useGetLembagaOptions from "../options/lembaga";
import useGetKabupatenOptions from "../options/kabupaten";
import useGetKecamatanOptions from "../options/kecamatan";
import useGetKelasOptions from "../options/kelas";
import useGetRuangOptions from "../options/ruang";

const FormSantri = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const {
    lembaga,
    kecamatan,
    kabupaten,
    provinsi,
    kelas,
    ruang,
    loadingOptional,
  } = useSelector((state) => state.optional);
  const { santriOne, loadingSantri } = useSelector((state) => state.santri);
  const location = useLocation();
  const methodSantri = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        kode_lembaga: yup.string().required("Tidak Boleh Kosong"),
        kelas: yup.string().required("Tidak Boleh Kosong"),
        ruang: yup.string().required("Tidak Boleh Kosong"),
        nisn: yup.string().when({
          is: (val) => val !== "",
          then: yup
            .string()
            .min(10, "Tidak Boleh Kurang dari 10 Karakter")
            .max(10, "Tidak Boleh Lebih dari 10 Karakter"),
          otherwise: yup.string(),
        }),
        nama: yup.string().required("Tidak Boleh Kosong"),
        gender: yup
          .string()
          .oneOf(["L", "P"], "Pilih Salah Satu")
          .required("Tidak Boleh Kosong"),
        t_lahir: yup.string().required("Tidak Boleh Kosong"),
        tgl_lahir: yup.string().required("Tidak Boleh Kosong"),
        nik: yup.string().when({
          is: (val) => val !== "",
          then: yup
            .string()
            .min(16, "Tidak Boleh Kurang dari 16 Karakter")
            .max(16, "Tidak Boleh Lebih dari 16 Karakter"),
          otherwise: yup.string(),
        }),
        no_kk: yup
          .string()
          .min(16, "Tidak Boleh Kurang dari 16 karakter")
          .max(16, "Tidak Boleh Lebih dari 16 karakter")
          .required("Tidak Boleh Kosong"),
        anak_ke: yup.string().required("Tidak Boleh Kosong"),
        alamat: yup.string().required("Tidak Boleh Kosong"),
        rt: yup.string().required("Tidak Boleh Kosong"),
        rw: yup.string().required("Tidak Boleh Kosong"),
        dusun: yup.string().required("Tidak Boleh Kosong"),
        desa: yup.string().required("Tidak Boleh Kosong"),
        kec: yup.string().required("Tidak Boleh Kosong"),
        kab: yup.string().required("Tidak Boleh Kosong"),
        prov: yup.string().required("Tidak Boleh Kosong"),
        domisili: yup.string().required("Tidak Boleh Kosong"),
        tinggal_bersama_wali: yup
          .string()
          .oneOf(["true", "false"], "Pilih Salah Satu")
          .required("Tidak Boleh Kosong"),
      })
    ),
  });
  useImperativeHandle(ref, () => ({
    method: methodSantri,
  }));
  useEffect(() => {
    if (location.pathname !== `/home/input/santri`) {
      if (Object.keys(santriOne).length !== 0) {
        if (
          Object.keys(santriOne.santri).length !== 0 &&
          santriOne.santri !== null
        ) {
          let str = "";
          if (santriOne.santri.tinggal_bersama_wali) {
            str = "true";
          } else {
            str = "false";
          }
          methodSantri.reset({
            ...santriOne.santri,
            tinggal_bersama_wali: str,
          });
        }
        console.log(santriOne)
      }
    }
  }, [loadingSantri]);
  const watchProvinsi = methodSantri.watch("prov");
  const watchKabupaten = methodSantri.watch("kab");
  const watchLembaga = methodSantri.watch("kode_lembaga");
  const watchKelas = methodSantri.watch("kelas");

  const lembagaOptions = useGetLembagaOptions();
  const provinsiOptions = useProvinceOptions();
  const kabupatenOptions = useGetKabupatenOptions(watchProvinsi);
  const kecamatanOptions = useGetKecamatanOptions(watchKabupaten);
  const kelasOptions = useGetKelasOptions(watchLembaga);
  const ruangOptions = useGetRuangOptions(watchKelas);
  return (
    <div>
      <form className="md:grid md:grid-cols-2 md:gap-5">
        <Options
          label="Lembaga"
          method={methodSantri}
          methodName="kode_lembaga"
          options={lembagaOptions}
        />
        <Options
          label="Kelas"
          method={methodSantri}
          methodName="kelas"
          options={kelasOptions}
        />
        <Options
          label="Ruang"
          method={methodSantri}
          methodName="ruang"
          options={ruangOptions}
        />
        <Input
          label="NISN"
          method={methodSantri}
          methodName="nisn"
          type="number"
        />
        <Input label="Nama Lengkap" method={methodSantri} methodName="nama" />
        <div>
          <h1 className="font-light text-lg mb-2">Gender</h1>
          <div className="flex my-auto md:space-x-0 space-x-2">
            <Radio
              method={methodSantri}
              methodName="gender"
              value="L"
              label="Laki-Laki"
            />
            <Radio
              method={methodSantri}
              methodName="gender"
              value="P"
              label="Perempuan"
            />
          </div>
          {methodSantri.formState.errors["gender"] && (
            <p className="text-red-600 mt-1 mx-1">
              {methodSantri.formState.errors["gender"].message}
            </p>
          )}
        </div>
        <Input
          label="Tempat Lahir"
          method={methodSantri}
          methodName="t_lahir"
        />
        <Input
          label="Tanggal Lahir"
          method={methodSantri}
          methodName="tgl_lahir"
          type="date"
        />
        <Input
          label="NIK"
          method={methodSantri}
          methodName="nik"
          type="number"
        />
        <Input
          label="No KK"
          method={methodSantri}
          methodName="no_kk"
          type="number"
        />
        <Input
          label="Anak Ke"
          method={methodSantri}
          methodName="anak_ke"
          type="number"
        />
        <Input label="Alamat" method={methodSantri} methodName="alamat" />
        <Input label="RT" method={methodSantri} methodName="rt" type="number" />
        <Input label="RW" method={methodSantri} methodName="rw" type="number" />
        <Input label="Dusun" method={methodSantri} methodName="dusun" />
        <Input label="Desa" method={methodSantri} methodName="desa" />
        <Input label="Domisili" method={methodSantri} methodName="domisili" />
        <Options
          label="Provinsi"
          method={methodSantri}
          methodName="prov"
          options={provinsiOptions}
        />
        <Options
          label="Kabupaten"
          method={methodSantri}
          methodName="kab"
          options={kabupatenOptions}
        />
        <Options
          label="Kecamatan"
          method={methodSantri}
          methodName="kec"
          options={kecamatanOptions}
        />
        <div>
          <h1 className="font-light text-lg mb-2">Tinggal Bersama Wali</h1>
          <div className="flex my-auto md:space-x-0 space-x-2">
            <Radio
              method={methodSantri}
              methodName="tinggal_bersama_wali"
              value="true"
              label="Iya"
            />
            <Radio
              method={methodSantri}
              methodName="tinggal_bersama_wali"
              value="false"
              label="Tidak"
            />
          </div>
          {methodSantri.formState.errors["tinggal_bersama_wali"] && (
            <p className="text-red-600 mt-1 mx-1">
              {methodSantri.formState.errors["tinggal_bersama_wali"].message}
            </p>
          )}
        </div>
      </form>
    </div>
  );
});

export default FormSantri;
