import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiUser from "../../api/user";

export const getAllUser = createAsyncThunk(
    'user/all',
    async (nama_sistem, { rejectWithValue }) => {
        try {
            const res = await ApiUser.get(nama_sistem)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getAllUserBySistem = createAsyncThunk(
    'user/sistem/all',
    async (uuid_sistem, { rejectWithValue }) => {
        try {
            const res = await ApiUser.getBySistem(uuid_sistem)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)

export const addUser = createAsyncThunk(
    'user/add',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ApiUser.post(data)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const editSelf = createAsyncThunk(
    'user/self/edit',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ApiUser.updateSelf(data)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const editByAdmin = createAsyncThunk(
    'user/edit',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ApiUser.update(data.uuid, data.update)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const destroyUser = createAsyncThunk(
    'user/delete',
    async (uuid, { rejectWithValue }) => {
        try {
            const res = await ApiUser.delete(uuid)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const postSistem = createAsyncThunk(
    'sistem/add',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ApiUser.addSistem(data.uuid, data.post)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const destroySistem = createAsyncThunk(
    'sistem/delete',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ApiUser.deleteSistem(data.uuid, data.uuid_sis)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const allUserReport = createAsyncThunk(
    'user/report/all',
    async (uuid, { rejectWithValue }) => {
        try {
            const res = await ApiUser.getUserReport(uuid)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const editSistemOne = createAsyncThunk(
    'sistem/edit/one',
    async (data, { rejectWithValue }) => {
        try {
            const res = await ApiUser.updateSistem(data.uuid,data.uuid_sistem,data.update)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err) {
            return rejectWithValue(err.response.data.msg)
        }

    }
)