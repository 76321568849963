import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getKecamatan } from "../store/actions/optional";

export default function useGetKecamatanOptions(kabupaten_id) {
  const [kecamatanOptions, setKecamatanOptions] = useState([]);
  const dispatch = useDispatch();
  const { kecamatan } = useSelector((state) => state.optional);
  useEffect(() => {
    if (kabupaten_id?.length > 0) {
      dispatch(getKecamatan(kabupaten_id));
    }
  }, [kabupaten_id]);
  useEffect(() => {
    if (kecamatan.length > 0) {
      const data = kecamatan.map((value) => {
        return { value: value.id, label: value.nama };
      });
      setKecamatanOptions(data);
    }
  }, [kecamatan]);
  return kecamatanOptions;
}
