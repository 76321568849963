import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import Dialog from './Dialog'
import Radio from './Radio'
import AddButton from './AddButton'
import InputField from './InputField'

function FormaddTahun(props) {
    const [dari,setDari] = useState("")
    const [sampai,setSampai] = useState("")
    const methodTahun = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                tahun: yup.string().required("Tidak Boleh Kosong"),
                active: yup.string().oneOf(["true", "false"], "Pilih Salah Satu").required("Tidak Boleh Kosong"),
            })
        )
    })
    const changeDari = (e) =>{
        setDari(e.target.value)
    }
    const changeSampai = (e) =>{
        setSampai(e.target.value)
    }
    useEffect(()=>{
        if(dari.length === 4 && sampai.length === 4){
            methodTahun.setValue("tahun",`${dari}/${sampai}`)
        }
    },[dari,sampai])
    return (
        <Dialog show={props.showForm} toggle={props.closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Tambah Tahun Ajar</h1>
                </div>
                <form onSubmit={methodTahun.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                    <h1 className='font-light'> Tahun Ajar</h1>
                    <div className='flex space-x-2'>
                        <InputField type="number" label="Dari" value={dari} onChange={changeDari} />
                        <InputField type="number" label="Sampai" value={sampai} onChange={changeSampai}/>
                    </div>
                    <div>
                        <h1 className='font-light text-lg my-2'>Active ?</h1>
                        <div className='flex my-auto md:space-x-0 space-x-2'>
                            <Radio method={methodTahun} methodName="active" value="true" label="Aktif" />
                            <Radio method={methodTahun} methodName="active" value="false" label="Tidak" />
                        </div>
                        {methodTahun.formState.errors["active"] && (
                            <p className="text-red-600 mt-1 mx-1">
                                {methodTahun.formState.errors["active"].message}
                            </p>
                        )}
                    </div>
                    <AddButton />
                </form>
            </div>
        </Dialog>
    )
}

export default FormaddTahun