import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import alumniStore from "./slice/alumni";
import authStore from "./slice/auth";
import bersaudaraStore from "./slice/bersaudara";
import donaturStore from "./slice/donatur";
import laporanStore from "./slice/laporan";
import lembagaStore from "./slice/lembaga";
import optionalStore from "./slice/optional";
import pembayaranStore from "./slice/pembayaran";
import pembayaranDonStore from "./slice/pembayaranDonatur";
import santriStore from "./slice/santri";
import sppStore from "./slice/spp";
import userStore from "./slice/user";
import gambarStore from "./slice/gambar";
import belanjaStore from "./slice/belanja";
import SubsidiStore from "./slice/subsidi";
import dashboardStore from "./slice/dashboard";
import DiskonBersaudara from "./slice/diskon";
import tahunSistem from "./slice/tahunSistem";

export const store = configureStore({
  reducer: {
    auth: persistReducer(
      {
        key: "cakepin",
        storage,
      },
      authStore
    ),
    dashboard: dashboardStore,
    santri: santriStore,
    optional: optionalStore,
    spp: sppStore,
    lembaga: lembagaStore,
    pembayaran: pembayaranStore,
    user: userStore,
    donatur: donaturStore,
    laporan: laporanStore,
    pembayaranDon: pembayaranDonStore,
    bersaudara: bersaudaraStore,
    alumni: alumniStore,
    gambar: gambarStore,
    belanja: belanjaStore,
    subsidi: SubsidiStore,
    diskonBersaudara: DiskonBersaudara,
    tahunSistem: tahunSistem,
  },
  middleware: [thunk],
});
export const persistor = persistStore(store);
