import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import CheckBox from '../component/CheckBox'
import InputField from '../component/InputField'
import OptionsField from '../component/OptionsField'
import { getThnAjar } from '../store/actions/optional'
import { getPembayaran, postPembayaran } from '../store/actions/pembayaran'
import { searchSantri } from '../store/actions/santri'
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { HiOutlinePencilSquare } from 'react-icons/hi2'
import Dialog from '../component/Dialog'
import moment from 'moment'
import { currency } from '../component/Currency'
import SnackBar from '../component/SnackBar'
import Loader from '../component/Loader'
import Options from '../component/Options'
import { pembayaranAction } from '../store/slice/pembayaran'

function Pembayaran() {
    const dispatch = useDispatch()
    const nuwb = useParams()
    const { thn_ajar } = useSelector((state) => state.optional)
    const { santriByKey } = useSelector((state) => state.santri)
    const { allPembayaran, msgPembayaran, loadingPembayaran } = useSelector((state) => state.pembayaran)
    const [tahun, setTahun] = useState({})
    const [msg, setMsg] = useState("")
    const [jumlahSpp, setJumlahSpp] = useState(0)
    const [jumlahUp, setJumlahUp] = useState(0)
    const [up, setUp] = useState(false)
    const [jul, setJul] = useState(false)
    const [aug, setAug] = useState(false)
    const [sep, setSep] = useState(false)
    const [okt, setOkt] = useState(false)
    const [nov, setNov] = useState(false)
    const [des, setDes] = useState(false)
    const [jan, setJan] = useState(false)
    const [feb, setFeb] = useState(false)
    const [mar, setMar] = useState(false)
    const [apr, setApr] = useState(false)
    const [mei, setMei] = useState(false)
    const [jun, setJun] = useState(false)
    const [show, setShow] = useState(false)
    const [antrian, setAntrian] = useState([])
    const [confirm, setConfirm] = useState(false)
    const [done, setDone] = useState(false)
    const bulan = ["jan", "feb", "mar", "apr", "mei", "jun", "jul", "agus", "sept", "okt", "nov", "des"]
    const [readJumlahSpp, setReadJumlahSpp] = useState(true)
    const [readJumlahUp, setReadJumlahUp] = useState(true)
    const [snack, setSnack] = useState(false)
    const methodPembayaran = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                nuwb: yup.string().required("Tidak Boleh Kosong"),
                tahun_ajar: yup.string().required("Tidak Boleh Kosong"),
                spp: yup.object().shape({
                    jul: yup.number(),
                    agus: yup.number(),
                    sept: yup.number(),
                    okt: yup.number(),
                    nov: yup.number(),
                    des: yup.number(),
                    jan: yup.number(),
                    feb: yup.number(),
                    mar: yup.number(),
                    apr: yup.number(),
                    mei: yup.number(),
                    jun: yup.number(),
                }),
                up: yup.number(),
                metode_pembayaran: yup.string().required("Tidak Boleh Kosong")
            }),
        ),
        defaultValues: { spp: { jul: 0, agus: 0, sept: 0, okt: 0, nov: 0, des: 0, jan: 0, feb: 0, mar: 0, apr: 0, mei: 0, jun: 0 }, up: 0 }
    })
    const detailSantri = () => {
        dispatch(searchSantri(nuwb.nuwb))
        methodPembayaran.setValue("metode_pembayaran", "tunai")
        setShow(true)
    }
    useEffect(()=>{

    },[show,tahun])
    useEffect(() => {
        thn_ajar.map((d) => {
            if (d.active) {
                setTahun({ value: d.tahun, label: d.tahun })
            }
        })

    }, [thn_ajar])
    const batal = () => {
        setJumlahSpp(0)
        setJumlahUp(0)
        setUp(false)
        setJul(false)
        setAug(false)
        setSep(false)
        setOkt(false)
        setNov(false)
        setDes(false)
        setJan(false)
        setFeb(false)
        setMar(false)
        setApr(false)
        setMei(false)
        setJun(false)
    }
    useEffect(() => {
        setJumlahSpp(0)
        setJumlahUp(0)
        setUp(false)
        setJul(false)
        setAug(false)
        setSep(false)
        setOkt(false)
        setNov(false)
        setDes(false)
        setJan(false)
        setFeb(false)
        setMar(false)
        setApr(false)
        setMei(false)
        setJun(false)
        dispatch(pembayaranAction.clearMsg())
    }, [tahun])
    useEffect(() => {
        dispatch(getThnAjar())
        dispatch(pembayaranAction.clearMsg())
    }, [])
    useEffect(() => {
        if (nuwb.nuwb !== "" && Object.keys(tahun).length !== 0) {
            dispatch(getPembayaran({ nuwb: nuwb.nuwb, tahun_ajar: tahun.value }))
        }
    }, [nuwb.nuwb, tahun, snack])
    useEffect(() => {
        if (nuwb.nuwb !== "" && Object.keys(tahun).length !== 0) {
            methodPembayaran.setValue("nuwb", nuwb.nuwb)
            methodPembayaran.setValue("tahun_ajar", tahun.value)
        }
    }, [nuwb, tahun, snack])
    const thnOpt = thn_ajar.map((d) => {
        return { value: d.tahun, label: d.tahun }
    })
    const metodeOpt = [
        { value: "tunai", label: "Tunai" },
        { value: "cimb", label: "CIMB" },
        { value: "bsi", label: "BSI" },
        { value: "bntb", label: "BNTB" },
    ]
    const clickJul = () => {
        if (!aug) {
            setJul(!jul)
            if (jumlahSpp === 0) {
                if (!jul) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.jul)
                    antrian.push({ no: 7, jumlah: allPembayaran.spp.jul })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!jul) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.jul)
                    antrian.push({ no: 7, jumlah: allPembayaran.spp.jul })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - allPembayaran.spp.jul)
                    antrian.splice(-1, 1)
                }
            }
        }
    }
    const clickaug = () => {
        if ((allPembayaran.spp?.jul === 0 && !sep) || (jul && !sep)) {
            setAug(!aug)
            if (jumlahSpp === 0) {
                if (!aug) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.agus)
                    antrian.push({ no: 8, jumlah: allPembayaran.spp.agus })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!aug) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.agus)
                    antrian.push({ no: 8, jumlah: allPembayaran.spp.agus })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - allPembayaran.spp.agus)
                    antrian.splice(-1, 1)
                }
            }

        }
    }
    const clicksep = () => {
        if ((allPembayaran.spp?.agus === 0 && !okt) || (aug && !okt)) {
            setSep(!sep)
            if (jumlahSpp === 0) {
                if (!sep) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.sept)
                    antrian.push({ no: 9, jumlah: allPembayaran.spp.sept })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!sep) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.sept)
                    antrian.push({ no: 9, jumlah: allPembayaran.spp.sept })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - allPembayaran.spp.sept)
                    antrian.splice(-1, 1)
                }
            }
        }
    }
    const clickOkt = () => {
        if ((allPembayaran.spp?.sept === 0 && !nov) || (sep && !nov)) {
            setOkt(!okt)
            if (jumlahSpp === 0) {
                if (!okt) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.okt)
                    antrian.push({ no: 10, jumlah: allPembayaran.spp.okt })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)

                }
            }
            else {
                if (!okt) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.okt)
                    antrian.push({ no: 10, jumlah: allPembayaran.spp.okt })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - allPembayaran.spp.okt)
                    antrian.splice(-1, 1)
                }
            }
        }
        else if (!sep) {

        }

    }
    const clickNov = () => {
        if ((allPembayaran.spp?.okt === 0 && !des) || (okt && !des)) {
            setNov(!nov)
            if (jumlahSpp === 0) {
                if (!nov) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.nov)
                    antrian.push({ no: 11, jumlah: allPembayaran.spp.nov })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!nov) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.nov)
                    antrian.push({ no: 11, jumlah: allPembayaran.spp.nov })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - allPembayaran.spp.nov)
                    antrian.splice(-1, 1)
                }
            }
        }
    }
    const clickDes = () => {
        if ((allPembayaran.spp?.nov === 0 && !jan) || (nov && !jan)) {
            setDes(!des)
            if (jumlahSpp === 0) {
                if (!des) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.des)
                    antrian.push({ no: 12, jumlah: allPembayaran.spp.des })

                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!des) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.des)
                    antrian.push({ no: 12, jumlah: allPembayaran.spp.des })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - allPembayaran.spp.des)
                    antrian.splice(-1, 1)
                }
            }

        }
    }
    const clickJan = () => {
        if ((allPembayaran.spp?.des === 0 && !feb) || (des && !feb)) {
            setJan(!jan)
            if (jumlahSpp === 0) {
                if (!jan) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.jan)
                    antrian.push({ no: 1, jumlah: allPembayaran.spp.jan })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!jan) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.jan)
                    antrian.push({ no: 1, jumlah: allPembayaran.spp.jan })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - allPembayaran.spp.jan)
                    antrian.splice(-1, 1)
                }
            }
        }
    }
    const clickFeb = () => {
        if ((allPembayaran.spp?.jan === 0 && !mar) || (jan && !mar)) {
            setFeb(!feb)
            if (jumlahSpp === 0) {
                if (!feb) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.feb)
                    antrian.push({ no: 2, jumlah: allPembayaran.spp.feb })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!feb) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.feb)
                    antrian.push({ no: 2, jumlah: allPembayaran.spp.feb })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - allPembayaran.spp.feb)
                    antrian.splice(-1, 1)
                }
            }
        }
    }
    const clickMar = () => {
        if ((allPembayaran.spp?.feb === 0 && !apr) || (feb && !apr)) {
            setMar(!mar)
            if (jumlahSpp === 0) {
                if (!mar) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.mar)
                    antrian.push({ no: 3, jumlah: allPembayaran.spp.mar })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!mar) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.mar)
                    antrian.push({ no: 3, jumlah: allPembayaran.spp.mar })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - allPembayaran.spp.mar)
                    antrian.splice(-1, 1)
                }
            }
        }
    }
    const clickApr = () => {
        if ((allPembayaran.spp?.mar === 0 && !mei) || (mar && !mei)) {
            setApr(!apr)
            if (jumlahSpp === 0) {
                if (!apr) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.apr)
                    antrian.push({ no: 4, jumlah: allPembayaran.spp.apr })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!apr) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.apr)
                    antrian.push({ no: 4, jumlah: allPembayaran.spp.apr })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - allPembayaran.spp.apr)
                    antrian.splice(-1, 1)
                }
            }
        }
    }
    const clickMei = () => {
        if ((allPembayaran.spp?.apr === 0 && !jun) || (apr && !jun)) {
            setMei(!mei)
            if (jumlahSpp === 0) {
                if (!mei) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.mei)
                    antrian.push({ no: 5, jumlah: allPembayaran.spp.mei })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!mei) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.mei)
                    antrian.push({ no: 5, jumlah: allPembayaran.spp.mei })

                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - allPembayaran.spp.mei)
                    antrian.splice(-1, 1)
                }
            }
        }
    }
    const clickJun = () => {
        if (allPembayaran.spp?.mei === 0 || mei) {
            setJun(!jun)
            if (jumlahSpp === 0) {
                if (!jun) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.jun)
                    antrian.push({ no: 6, jumlah: allPembayaran.spp.jun })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!jun) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + allPembayaran.spp.jun)
                    antrian.push({ no: 6, jumlah: allPembayaran.spp.jun })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - allPembayaran.spp.jun)
                    antrian.splice(-1, 1)
                }
            }
        }
    }
    const clickUp = () => {
        if (allPembayaran.up !== 0) {
            setUp(!up)
            if (jumlahUp === 0) {
                if (!up) {
                    setJumlahUp(() => parseInt(jumlahUp) + allPembayaran.up)
                }
            }
            else {
                if (up) {
                    setJumlahUp(0)
                }
            }
        }
    }
    const navigate = useNavigate()
    const add = (e) => {
        dispatch(postPembayaran(e))
        setConfirm(!confirm)
        setDone(true)
    }
    useEffect(() => {
        if (done && msgPembayaran !== "ada tunggakan ditagihan sebelumnya" && msgPembayaran !== "") {
                let thn = tahun.value?.replace("/", "-")
                navigate(`/home/bukti/${nuwb.nuwb}/${thn}`)
                setDone(false)
        }
    }, [msgPembayaran, loadingPembayaran])
    const create = () => {
        setConfirm(!confirm)
        let jumlah = parseInt(jumlahSpp)
        let jumUp = parseInt(jumlahUp)
        antrian.map((e) => {
            if (jumlah >= e.jumlah) {
                methodPembayaran.setValue(`spp.${bulan[e.no - 1]}`, e.jumlah)
                jumlah -= e.jumlah
            }
            else if (jumlah < e.jumlah) {
                methodPembayaran.setValue(`spp.${bulan[e.no - 1]}`, jumlah)
                jumlah = 0
            }
        })
        methodPembayaran.setValue("up", jumUp)
    }
    const changeJumlahSpp = (e) => {
        setJumlahSpp(e.target.value)
    }
    const changeJumlahUp = (e) => {
        setJumlahUp(e.target.value)
    }
    return (
        <div >
            <Loader show={loadingPembayaran} />
            <SnackBar show={snack} toggle={setSnack} msg={msgPembayaran} />
            <Dialog show={confirm} toggle={setConfirm}>
                <div className='bg-white md:w-[40vw] w-[95vw]'>
                    <div className='py-4 px-6 border-b border-slate-200'>
                        <h1 className='text-xl'>Lakukan Pembayaran ?</h1>
                    </div>
                    <form onSubmit={methodPembayaran.handleSubmit(add)} className='px-6 py-7 space-y-1'>
                        <button className='w-full py-2 border border-blue-500 hover:border-blue-700 hover:bg-slate-50 transition-colors ease-in-out duration-200 font-light'>Iya</button>
                    </form>
                </div>
            </Dialog>
            <div className='md:flex m-7 flex-wrap'>
                <div className='bg-white md:w-[45%] shadow-lg'>
                    <h1 className='bg-second p-4 font-light text-white text-xl'>Info Santri</h1>
                    <div className='m-4'>
                        <InputField label="NUWB" value={nuwb.nuwb} read={true} />
                        <button className='border border-sky-500 px-4 py-1 my-2 hover:border-sky-700 hover:bg-blue-500 hover:text-white transition-all ease-in-out duration-300 rounded-sm' onClick={detailSantri}>Tampilkan</button>
                        <InputField label="Nama" value={allPembayaran?.santri?.nama === undefined || !show ? "" : allPembayaran?.santri?.nama} read={true} />
                        <InputField label="Lembaga" value={allPembayaran?.santri?.lembaga === undefined || !show ? "" : allPembayaran?.santri?.lembaga} read={true} />
                        <InputField label="Kelas" value={allPembayaran?.santri?.kelas === undefined || !show ? "-" : allPembayaran?.santri?.kelas} read={true} />
                        <InputField label="Ruang" value={allPembayaran?.santri?.ruang === undefined || !show ? "-" : allPembayaran?.santri?.ruang} read={true} />
                    </div>
                </div>
                <div className='bg-white shadow-lg md:mx-10 md:w-[45%]'>
                    <h1 className='bg-second p-4 font-light text-white text-xl'>Info Kewajiban</h1>
                    <div className='m-4'>
                        <div className='flex'>
                            <InputField type="number" label="Pembayaran SPP" value={parseInt(jumlahSpp)} onChange={changeJumlahSpp} read={readJumlahSpp} />
                            <button className=' ml-2'><HiOutlinePencilSquare className='w-6 h-6 -mb-6' onClick={() => setReadJumlahSpp(!readJumlahSpp)} /></button>
                        </div>
                        <div className='flex'>
                            <InputField type="number" label="Pembayaran UP" onChange={changeJumlahUp} value={parseInt(jumlahUp)} read={readJumlahUp} />
                            <button className=' ml-2'><HiOutlinePencilSquare className='w-6 h-6 -mb-6' onClick={() => setReadJumlahUp(!readJumlahUp)} /></button>
                        </div>
                        <InputField type="number" label="Total" value={parseInt(jumlahSpp) + parseInt(jumlahUp)} read={true} />
                    </div>
                    <form onSubmit={methodPembayaran.handleSubmit(add)} className='bg-white'>
                        <h1 className='bg-second p-4 font-light text-white text-xl'>Aksi</h1>
                        <div className='m-4 space-y-1'>
                            <OptionsField options={thnOpt} onChange={(e) => setTahun(e)} value={tahun} />
                            <Options method={methodPembayaran} methodName="metode_pembayaran" options={metodeOpt} />
                        </div>
                        <div className='m-2'>
                            <button type='button' onClick={create} className=' bg-blue-600 hover:bg-blue-700 px-10 py-2 mx-2 text-white'>Simpan</button>
                            <button type='button' className=' bg-red-600 hover:bg-red-700 px-10 py-2 text-white' onClick={batal}>Batal</button>
                        </div>
                    </form>

                </div>
                <div className=' bg-white shadow-lg my-7 md:w-[30%] h-full'>
                    <h1 className='bg-second p-4 font-light text-white text-xl'>Panel Pembayaran</h1>
                    {Object.keys(allPembayaran).length !== 0 && show ?
                        allPembayaran.spp !== null ?
                            <div className='mx-4 mt-4 flex '>
                                <div className='grid grid-cols-4 w-[90%] m-2'>
                                    <CheckBox label="jul" onChange={clickJul} checked={allPembayaran?.spp?.jul !== 0 ? jul : true} />
                                    <CheckBox label="aug" onChange={clickaug} checked={allPembayaran?.spp?.agus !== 0 ? aug : true} />
                                    <CheckBox label="sep" onChange={clicksep} checked={allPembayaran?.spp?.sept !== 0 ? sep : true} />
                                    <CheckBox label="okt" onChange={clickOkt} checked={allPembayaran?.spp?.okt !== 0 ? okt : true} />
                                    <CheckBox label="nov" onChange={clickNov} checked={allPembayaran?.spp?.nov !== 0 ? nov : true} />
                                    <CheckBox label="des" onChange={clickDes} checked={allPembayaran?.spp?.des !== 0 ? des : true} />
                                    <CheckBox label="jan" onChange={clickJan} checked={allPembayaran?.spp?.jan !== 0 ? jan : true} />
                                    <CheckBox label="feb" onChange={clickFeb} checked={allPembayaran?.spp?.feb !== 0 ? feb : true} />
                                    <CheckBox label="mar" onChange={clickMar} checked={allPembayaran?.spp?.mar !== 0 ? mar : true} />
                                    <CheckBox label="apr" onChange={clickApr} checked={allPembayaran?.spp?.apr !== 0 ? apr : true} />
                                    <CheckBox label="mei" onChange={clickMei} checked={allPembayaran?.spp?.mei !== 0 ? mei : true} />
                                    <CheckBox label="jun" onChange={clickJun} checked={allPembayaran?.spp?.jun !== 0 ? jun : true} />
                                </div>
                                <div className='md:w-[10%] m-2'>
                                    <CheckBox label="Up" onChange={clickUp} checked={allPembayaran?.up !== 0 ? up : true} />
                                </div>
                            </div>
                            :
                            <div className='mx-4 mt-4 flex '>
                                <div className='grid grid-cols-4 w-[90%] m-2'>
                                    <CheckBox label="jul" checked={false} read={true} />
                                    <CheckBox label="aug" checked={false} read={true} />
                                    <CheckBox label="sep" checked={false} read={true} />
                                    <CheckBox label="okt" checked={false} read={true} />
                                    <CheckBox label="nov" checked={false} read={true} />
                                    <CheckBox label="des" checked={false} read={true} />
                                    <CheckBox label="jan" checked={false} read={true} />
                                    <CheckBox label="feb" checked={false} read={true} />
                                    <CheckBox label="mar" checked={false} read={true} />
                                    <CheckBox label="apr" checked={false} read={true} />
                                    <CheckBox label="mei" checked={false} read={true} />
                                    <CheckBox label="jun" checked={false} read={true} />
                                </div>
                                <div className='md:w-[10%] m-2'>
                                    <CheckBox label="Up" checked={false} read={true} />
                                </div>
                            </div>
                        :
                        <div className='mx-4 mt-4 flex '>
                            <div className='grid grid-cols-4 w-[90%] m-2'>
                                <CheckBox label="jul" checked={false} read={true} />
                                <CheckBox label="aug" checked={false} read={true} />
                                <CheckBox label="sep" checked={false} read={true} />
                                <CheckBox label="okt" checked={false} read={true} />
                                <CheckBox label="nov" checked={false} read={true} />
                                <CheckBox label="des" checked={false} read={true} />
                                <CheckBox label="jan" checked={false} read={true} />
                                <CheckBox label="feb" checked={false} read={true} />
                                <CheckBox label="mar" checked={false} read={true} />
                                <CheckBox label="apr" checked={false} read={true} />
                                <CheckBox label="mei" checked={false} read={true} />
                                <CheckBox label="jun" checked={false} read={true} />
                            </div>
                            <div className='md:w-[10%] m-2'>
                                <CheckBox label="Up" checked={false} read={true} />
                            </div>
                        </div>
                    }
                    <h1 className={`px-5 pt-2 text-sm ${allPembayaran?.spp === null ? "text-red-700" : "text-blue-700"}`}>{allPembayaran?.spp === null ? "Tagihan Tidak Tersedia / Tunggakan Tahun Sebelumnya Belum Lunas " : ``}</h1>
                    <h1 className='text-red-500 px-5 text-sm pb-2'>{msgPembayaran}</h1>
                </div>
                <div className='shadow-lg md:ml-7 my-8 md:w-[65%]'>
                    <h1 className='bg-second p-4 font-light text-white text-xl'>History Pembayaran</h1>
                    <div className='bg-white p-2'>
                        {allPembayaran?.pembayaran?.length === 0 ? <div>
                            <h1 className='font-light text-center my-10 text-xl'>Tidak Ada History</h1>
                        </div> :
                            <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden">
                                <table className="w-full text-sm text-left text-gray-500 ">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                Tgl
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Ket
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Jumlah
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allPembayaran?.pembayaran?.map((d, id) => (
                                            <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                                                <td className='px-6 py-3'>{moment(d.createdAt).format("DD-MM-YYYY hh:mm")}</td>
                                                <td className='px-6 py-3'>{d.ket}</td>
                                                <td className='px-6 py-3'>{currency(d.jumlah)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pembayaran