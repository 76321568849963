import React from 'react'

function InputField(props) {
    return (
        <div className={` ${props.className} flex flex-col w-full`}>
            <label className='font-light text-gray-600'>{props.label}</label>
            <div className='flex'>
                <input type={props.type} className={`py-[7px] px-2 outline-none border mt-1 w-full `} readOnly={props.read} value={props.value} onChange={props.onChange} />
                {props.icon}
            </div>
        </div>

    )
}

export default InputField