import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import AddButton from "./AddButton";
import Dialog from "./Dialog";
import Input from "./Input";
import Options from "./Options";
import { convertToRupiah, formatRupiah } from "./FormatUang";

function FormAddBelanja(props) {
  const methodAddBelanja = useForm({
    resolver: yupResolver(
      yup.object().shape({
        nuwb: yup.string().required("Tidak boleh kosong"),
        belanja: yup.string().required("Tidak boleh kosong"),
        status: yup.string().required("Tidak boleh kosong"),
        metode: yup.string().required("Tidak boleh kosong"),
      })
    ),
  });
  const metodeOpt = [
    { value: "tunai", label: "Tunai" },
    { value: "cimb", label: "CIMB" },
    { value: "bsi", label: "BSI" },
    { value: "bntb", label: "BNTB" },
  ];
  const watchBelanja = methodAddBelanja.watch("belanja");
  const statusOpt = [
    { value: "d", label: "Debit" },
    { value: "k", label: "Kredit" },
  ];
  useEffect(() => {
    if (watchBelanja !== null && watchBelanja !== 0) {
      let reset = convertToRupiah(watchBelanja);
      let change = formatRupiah(reset);
      methodAddBelanja.setValue("belanja", change);
    }
  }, [watchBelanja]);
  useEffect(() => {
    if (props.showForm && props.nuwb !== null) {
      methodAddBelanja.reset({ nuwb: props.nuwb, belanja: 0, status: "" });
    }
  }, [props.showForm, props.nuwb]);
  return (
    <Dialog show={props.showForm} toggle={props.closeForm}>
      <div className="bg-white md:w-[40vw] w-[95vw]">
        <div className="py-4 px-6 border-b border-slate-200">
          <h1 className="text-xl">Proses Uang Belanja</h1>
        </div>
        <form
          onSubmit={methodAddBelanja.handleSubmit(props.submit)}
          className="px-6 py-7 space-y-1"
        >
          <Input
            label="Nominal"
            method={methodAddBelanja}
            methodName="belanja"
          />
          <Options
            label="Transaksi"
            method={methodAddBelanja}
            methodName="status"
            options={statusOpt}
          />
          <Options
          label="Metode"
          method={methodAddBelanja}
          methodName="metode"
          options={metodeOpt}
          />
          <AddButton />
        </form>
      </div>
    </Dialog>
  );
}

export default FormAddBelanja;
