import React, { useEffect, useState } from "react";
import HeaderUi from "../component/HeaderUi";
import Table from "../component/Table";
import AddUploadGambar from "../component/AddUploadGambar";
import { useDispatch, useSelector } from "react-redux";
import { addGambar, addGambarToDb, allGambar, updateStatusGambar } from "../store/actions/gambar";
import gambar, { gambarActions } from "../store/slice/gambar";
import SnackBar from "../component/SnackBar";
import { HiOutlinePencilSquare } from "react-icons/hi2";

function UploadGambar() {
  const [url, setUrl] = useState("https://sipahamv2.ponpesabuhurairah.id/");
  const dispatch = useDispatch();
  const [showAdd, setShowAdd] = useState(false);
  const [button,setButton] = useState(false)
  const { succes, msgGambar, loadingGambar, gambarAll } = useSelector(
    (state) => state.gambar
  );
  const [title, setTitle] = useState("");
  const [alert, setAlert] = useState(false);
  const [load, setLoad] = useState(0);
  const tambah = (e) => {
    setTitle(e.title);
    let gambar = e.gambar[0];
    const profile = new FormData();
    profile.append("profile", gambar);
    dispatch(
      addGambar({
        gambar: profile,
        loading: (progress) => {
          setLoad((progress.loaded / progress.total) * 100);
        },
      })
    );
    setButton(true)
  };
  const editStatus = (e)=>{
    dispatch(updateStatusGambar({uuid:e.uuid,update:{status:!e.status}}))
    setAlert(true)
  }
  useEffect(() => {
    if (load === 100) {
      setLoad(0);
      setShowAdd(false);
      setButton(false)
    }
    if (Object.keys(succes).length !== 0) {
      if (succes?.uuid !== "") {
        dispatch(
          addGambarToDb({
            title: title,
            name_file: succes.uuid,
            file_type: succes.file_type,
          })
        );
        dispatch(gambarActions.clearUuid());
        setAlert(true);
      }
    }
  }, [load, succes]);
  useEffect(() => {
    dispatch(allGambar());
  }, [alert]);
  return (
    <div>
      <SnackBar show={alert} toggle={setAlert} msg={msgGambar} />
      <AddUploadGambar
        showForm={showAdd}
        closeForm={setShowAdd}
        submit={tambah}
        val={`${load}%`}
        showButton={`${button ? "hidden" : "block"}`}
      />
      <HeaderUi titleHeader="Upload Gambar" />
      <Table titleFirst="Tambah" clickFirst={() => setShowAdd(!showAdd)}>
        <div className="bg-white">
          {gambarAll.length === 0 ? (
            <div>
              <h1 className="font-light text-center my-10 text-xl">
                Tidak Ada Gambar
              </h1>
            </div>
          ) : (
            <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden z-0">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      No
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Title
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Gambar
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Edit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {gambarAll.map((d, id) => (
                    <tr
                      className="bg-white border-b hover:bg-gray-100"
                      key={id}
                    >
                      <td className="px-6 py-3">{id + 1}</td>
                      <td className="px-6 py-3">{d.title}</td>
                      <td className="px-6 py-3">
                        {/* <img src={`${url}${d.name_file}`} alt="" /> */}
                        <img className="w-[200px] h-[100px]" crossOrigin="anonymous" src={`${url}${d.name_file}`} alt={`${url}${d.name_file}`} />
                      </td>
                      <td className={`px-6 py-3 `}><h1 className={`border w-32 text-white text-center p-1 shadow-md rounded-md font-semibold ${d.status ? "bg-blue-500" : "bg-red-500"}`}>{d.status ? "Ditayangkan" : "Tidak Ditayangkan"}</h1></td>
                      <td className="px-6 py-3"><button className="border w-28 p-1 rounded-md shadow-md cursor-pointer" onClick={() => editStatus(d)}>Ubah Status</button></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Table>
    </div>
  );
}

export default UploadGambar;
