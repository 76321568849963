import api from "./http";

const ApiPembayaranDonatur = {
    get : (data) =>{
        return api.post(`/cakepin/tagihan/donatur/get`,data)
    },
    getGolongan : (uuid) =>{
        return api.get(`/cakepin/list-golongan-donatur/${uuid}`)
    },
    addPembayaran : (data) =>{
        return api.post(`/cakepin/pembayaran/donatur`,data)
    }
}
export default ApiPembayaranDonatur