import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { HiOutlineTrash } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../component/Button'
import CheckBox from '../component/CheckBox'
import { currency } from '../component/Currency'
import DialogDelete from '../component/DialogDelete'
import FormDeleteLaporan from '../component/FormDeleteLaporan'
import HeaderUi from '../component/HeaderUi'
import InputField from '../component/InputField'
import Loader from '../component/Loader'
import OptionsField from '../component/OptionsField'
import SnackBar from '../component/SnackBar'
import Table from '../component/Table'
import { getThnAjar } from '../store/actions/optional'
import { batalPembayaran, historySantri } from '../store/actions/pembayaran'

function TableHistorySantri() {
    const param = useParams()
    const dispatch = useDispatch()
    const { historyOneSantri, loadingPembayaran, msgPembayaran } = useSelector((state) => state.pembayaran)
    const { thn_ajar } = useSelector(state => state.optional)
    const [formDeleteHistory, setFormDeleteHistroy] = useState(false)
    const [snack, setSnack] = useState(false)
    const [uuid, setUuid] = useState("")
    const [tahun, setTahun] = useState({})
    const [tampung, setTampung] = useState([])
    const [indexing, setIndexing] = useState([])
    const [catatan, setCatatan] = useState("")
    const tahunOpt = thn_ajar.map((d) => {
        return { value: d.tahun, label: d.tahun }
    })
    const clickBox = (d, id) => {
        if (indexing[id] === 0) {
            if (id === 0 || indexing[id - 1] === 1) {
                setTampung((e) => [...e, { uuid: d.uuid, index: d.index }])
                indexing.splice(id, 1, 1)
            }
        }
        else {
            if (id === indexing.length - 1 || indexing[id + 1] === 0) {
                setTampung((e) => e.filter((i) => i.uuid !== d.uuid))
                indexing.splice(id, 1, 0)
            }
        }
    }
    useEffect(() => {
        dispatch(getThnAjar())
    }, [])
    useEffect(() => {
        if (Object.keys(param).length !== 0 && Object.keys(tahunOpt).length !== 0) {
            let thn_ = tahun.value?.replace("/", "-")
            dispatch(historySantri({ nuwb: param.nuwb, thn: thn_ }))
        }
    }, [param, snack, tahun])
    const hapusHistory = () => {
        dispatch(batalPembayaran({tahun_ajar:tahun.value,nuwb:param.nuwb,catatan:catatan,list:tampung}))
        setFormDeleteHistroy(!formDeleteHistory)
        setSnack(!snack)
    }
    useEffect(() => {
        if (historyOneSantri.length > 0) {
            setIndexing([])
            setTampung([])
            let indx = []
            historyOneSantri.map(() => {
                indx.push(0)
            })
            setIndexing(indx)
        }
    }, [historyOneSantri])
    return (
        <div>
            <HeaderUi titleHeader="History Pembayaran" />
            <Loader show={loadingPembayaran} />
            <SnackBar show={snack} toggle={setSnack} msg={msgPembayaran} />
            <DialogDelete show={formDeleteHistory} close={setFormDeleteHistroy} onClick={hapusHistory} />
            <Table
                classFirst="hidden"
            >
                <div>
                    <div className=' flex space-x-2'>
                        <div className='w-72 mt-1'>
                            <OptionsField label="Tahun" value={tahun} onChange={(e) => setTahun(e)} options={tahunOpt} />
                        </div>
                        <div className={`${tampung.length > 0 ? "block w-[50%]" : "hidden"}`}>
                            <InputField label="catatan" value={catatan} onChange={(e) => setCatatan(e.target.value)} />
                        </div>
                        <div className='flex items-end w-full justify-end'>
                            <Button title="Proses" click={()=>setFormDeleteHistroy(true)} />
                        </div>
                    </div>
                    {historyOneSantri.length === 0 ? <div>
                        <h1 className='font-light text-center my-10 text-xl'>Tidak Ada History</h1>
                    </div> :
                        <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden">
                            <table className="w-full text-sm text-left text-gray-500 ">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Tgl
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Thn Ajar
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Ket
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Metode
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Jumlah
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {historyOneSantri.map((d, id) => (
                                        <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                                            <td className='px-6 py-3'><CheckBox onChange={() => clickBox(d, id)}
                                                checked={indexing.length > 0 && indexing[id] !== undefined ? indexing[id] : 0} /></td>
                                            <td className='px-6 py-3'>{moment(d.createdAt).format("DD-MM-YYYY hh:mm")}</td>
                                            <td className='px-6 py-3'>{d.tahun_ajar}</td>
                                            <td className='px-6 py-3'>{d.ket}</td>
                                            <td className='px-6 py-3'>{d.metode_pembayaran}</td>
                                            <td className='px-6 py-3'>{currency(d.jumlah)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </Table>
        </div>
    )
}

export default TableHistorySantri