import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import AddButton from './AddButton'
import Dialog from './Dialog'
import Options from './Options'
import * as yup from "yup"
import { useSelector } from 'react-redux'
import Input from './Input'

function FormEditGolDonatur(props) {
    const {allDonatur} = useSelector((state)=>state.donatur)
    const methodGolonganDonatur = useForm({
        mode:"all",
        resolver:yupResolver(
            yup.object().shape({
                golongan : yup.string().required("Tidak Boleh Kosong"),
                uuid_donatur : yup.string().required("Tidak Boleh Kosong")
            })
        )
    }) 
    const donaturOpt = allDonatur.map((d)=>{
        return {value:d.uuid,label:d.nama}
    })
    useEffect(()=>{
        if(Object.keys(props.data).length !== 0){
            methodGolonganDonatur.reset({golongan:props.data.golongan,uuid_donatur:props.data.uuid_donatur})
        }
    },[props.data])
    return (
        <Dialog show={props.showForm} toggle={props.closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Tambah Golongan Donatur</h1>
                </div>
                <form onSubmit={methodGolonganDonatur.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                    <Input label="Golongan" method={methodGolonganDonatur} methodName="golongan" />
                    <Options label="Donatur" method={methodGolonganDonatur} methodName="uuid_donatur" options={donaturOpt} />
                    <AddButton />
                </form>
            </div>
        </Dialog>

    )
}

export default FormEditGolDonatur