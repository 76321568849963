import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Dialog from './Dialog'
import Options from './Options'
import AddButton from './AddButton'
import { useDispatch, useSelector } from 'react-redux'
import { getAllDonatur, hapusOneDonaturTtp, oneDonaturTtpByNuwb } from '../store/actions/donatur'
import { getGolDonatur, getGolDonaturByDon, getGolongan, getThnAjar } from '../store/actions/optional'
import Input from './Input'
import { getOneTagihan } from '../store/actions/spp'
import { useParams } from 'react-router-dom'
import { bulanTagihanOpt } from './OptSelect'
import OptionsField from './OptionsField'
import Radio from './Radio'
import FormDonaturSantriTidakTetap from './FormGenerateDonaturTidaTetapSantri'

function FormDonaturSantri(props) {
    const param = useParams()
    const dispatch = useDispatch()
    const { allDonatur, oneDonaturTtp, loadingDonatur } = useSelector((state) => state.donatur)
    const { thn_ajar, golDonaturByUid, golongan, loadingOptional } = useSelector((state) => state.optional)
    const { oneTagihan } = useSelector((state) => state.spp)
    const [disable, setDisable] = useState(false)
    const [dariBulTetap, setDariBulTetap] = useState({})
    const [sampaiBulTetap, setSampaiBulTetap] = useState({})
    const [dariBulTidak, setDariBulTidak] = useState({})
    const [sampaiBulTidak, setSampaiBulTidak] = useState({})
    const [thn, setThn] = useState("")
    const [empty, setEmpty] = useState([])
    const methodDonatur = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                uuid: yup.string().required("Tidak Boleh Kosong"),
                uuid_tagihan: yup.string(),
                tahun_ajar: yup.string().required("Tidak Boleh Kosong"),
                uuid_donatur: yup.string(),
                donasi: yup.string(),
                id_golongan_donatur: yup.string(),
                dari: yup.number(),
                sampai: yup.number()

            })
        )
    })
    const watchDonatur = methodDonatur.watch("uuid_donatur")
    const watchTahun = methodDonatur.watch("tahun_ajar")
    const donOpt = allDonatur.map((d) => {
        return { value: d.uuid, label: d.nama }
    })
    const thunOpt = thn_ajar.map((d) => {
        return { value: d.tahun, label: d.tahun }
    })
    const golDonOpt = golDonaturByUid.map((d) => {
        if (d.uuid_donatur === watchDonatur) {
            return { value: d.id, label: d.golongan }
        }
    })
    useEffect(() => {
        if (watchDonatur !== "" && watchDonatur !== undefined && watchDonatur !== null) {
            dispatch(getGolDonaturByDon(watchDonatur))
        }
        else if (Object.keys(oneDonaturTtp).length !== 0) {
            if (oneDonaturTtp.uuid_donatur !== "") {
                dispatch(getGolDonaturByDon(oneDonaturTtp.uuid_donatur))
            }
        }
    }, [watchDonatur,loadingDonatur])
    useEffect(() => {
        if (Object.keys(oneDonaturTtp).length !== 0 && Object.keys(golDonaturByUid).length !== 0) {
            if (oneDonaturTtp.uuid_donatur !== null) {
                methodDonatur.reset({ ...oneDonaturTtp, uuid: props.uuid_santri, tahun_ajar: watchTahun, id_golongan_donatur: golDonaturByUid.find((e) => e?.id === oneDonaturTtp.id_golongan_donatur)?.id })
                setDariBulTetap(bulanTagihanOpt[oneDonaturTtp.dari])
                setSampaiBulTetap(bulanTagihanOpt[11])
            }
        }
    }, [oneDonaturTtp, golDonaturByUid, loadingDonatur, loadingOptional, props.uuid_santri, props.showForm])
    useEffect(() => {
        if (props.uuid_santri !== "") {
            methodDonatur.reset({ uuid: props.uuid_santri })
            setDariBulTetap({})
            setSampaiBulTetap(bulanTagihanOpt[11])
        }
    }, [props.showForm, props.uuid_santri])
    useEffect(() => {
        if (watchTahun !== "" && props.lembaga !== "") {
            let chnge = watchTahun?.replace("/", "-")
            dispatch(getGolongan({ thn: chnge, kode: props.lembaga }))
        }
    }, [watchTahun, props.lembaga])
    useEffect(() => {
        if (param.nuwb !== "" && watchTahun !== undefined) {
            dispatch(getOneTagihan({ nuwb: param.nuwb }))
            dispatch(oneDonaturTtpByNuwb({ nuwb: param.nuwb, tahun_ajar: watchTahun }))
            setThn(watchTahun)
        }
    }, [param.nuwb, watchTahun])
    useEffect(() => {
        if (Object.keys(oneTagihan).length !== 0 && Object.keys(golongan).length !== 0) {
            methodDonatur.setValue("uuid_tagihan", golongan.find((e) => e.ket === oneTagihan.santri?.gol)?.uuid)
        }
    }, [oneTagihan, golongan])
    useEffect(() => {
        if (dariBulTetap.value !== "" && sampaiBulTetap.value !== "") {
            methodDonatur.setValue("dari", dariBulTetap.value)
            methodDonatur.setValue("sampai", sampaiBulTetap.value)
        }
    }, [dariBulTetap, sampaiBulTetap])
    useEffect(() => {
        dispatch(getThnAjar())
        dispatch(getAllDonatur())
    }, [])
    const deleteDonatur = () => {
        dispatch(hapusOneDonaturTtp({ nuwb: param.nuwb, tahun_ajar: watchTahun }))
        window.location.reload(false)
    }
    return (
        <div>
            <FormDonaturSantriTidakTetap showForm={props.secondShow} closeForm={props.secondClose} uuid_santri={props.uuid_santri} submit={props.submitSecond} tahun={thn} />
            <Dialog show={props.secondShow ? false : props.showForm} toggle={props.closeForm}>
                <div className='bg-white md:w-[40vw] w-[95vw]'>
                    <div className='py-4 px-6 border-b border-slate-200'>
                        <h1 className='text-xl'>Generate Donatur Tetap</h1>
                    </div>
                    <form onSubmit={methodDonatur.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                        <Options label="Tahun Ajar" method={methodDonatur} methodName="tahun_ajar" options={thunOpt} />
                        <div>
                            <Options label="Donatur" method={methodDonatur} methodName="uuid_donatur" options={donOpt} />
                            <Input type="number" label="Donasi" method={methodDonatur} methodName="donasi" />
                            <Options label="Golongan" method={methodDonatur} methodName="id_golongan_donatur" options={golDonOpt[0] !== undefined ? golDonOpt : empty} />
                            <OptionsField label="Dari Bulan" options={bulanTagihanOpt} onChange={(e) => setDariBulTetap(e)} value={{ value: dariBulTetap.value, label: dariBulTetap.label }} />
                            <OptionsField label="Sampai Bulan" options={bulanTagihanOpt.slice(parseInt(dariBulTetap.value))} onChange={(e) => setSampaiBulTetap(e)} value={{ value: sampaiBulTetap.value, label: sampaiBulTetap.label }} disabled={true} />
                        </div>
                        <div className='flex justify-between'>
                            <button type='button' onClick={props.changeForm} className={`border mt-5 border-blue-400 hover:border-blue-600 hover:text-blue-500 px-8`}>Tidak Tetap</button>
                            {/* <button type='button' onClick={deleteDonatur} className={`border mt-5 border-blue-400 hover:border-blue-600 hover:text-blue-500 px-8 ${oneDonaturTtp?.uuid_donatur !== null && Object.keys(oneDonaturTtp).length !== 0 ? "block" : "hidden"}`}>Hapus</button> */}
                            <AddButton />
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>

    )
}

export default FormDonaturSantri