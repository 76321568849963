import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiPembayaranDonatur from "../../api/pembayaranDonatur";

export const getPembayaranDon = createAsyncThunk(
    'donatur/pembayaran/get',
    async(data,{rejectWithvalue})=>{
        try{
            const res = await ApiPembayaranDonatur.get(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithvalue(err.response.data.msg)
        }
    }
)
export const getGolonganDon = createAsyncThunk(
    'golongan/donatur/pembayaran',
    async(uuid,{rejectWithvalue})=>{
        try{
            const res = await ApiPembayaranDonatur.getGolongan(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithvalue(err.response.data.msg)
        }
    }
)
export const postPembayaranDon = createAsyncThunk(
    'pembayaran/donatur',
    async(data,{rejectWithvalue})=>{
        try{
            const res = await ApiPembayaranDonatur.addPembayaran(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithvalue(err.response.data.msg)
        }
    }
)