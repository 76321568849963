import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiSubsidi } from "../../api/subsidi";

export const getAllSubsidi = createAsyncThunk(
    'get/subsidi/all',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiSubsidi.getAll()
            if(res.status === 200){
                return res.data
            }

        }catch(r){
            return rejectWithValue(r.response?.data?.msg)
        }
    }
)

export const getSubsidiByLembagaAndDonatur = createAsyncThunk(
    'get/subsidi/by/lembaga',
    async(data,{rejectWithValue})=>{
        try{
            console.log(data)
            const res = await ApiSubsidi.getByLembagaNDonatur(data.kode_lembaga,data.uuid_donatur,data.alumni)
            if(res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r.response?.data?.msg)
        }
    }
)