import api from "./http";

const ApiUser = {
    get : (nama_sistem) =>{
        return api.get(`/user/${nama_sistem}`)
    },
    getBySistem : (uuid_sistem) =>{
        return api.get(`/user/sistem/${uuid_sistem}`)
    },
    post : (data) =>{
        return api.post(`/user`,data)
    },
    updateSelf : (data) =>{
        return api.put(`/user`,data)
    },
    update : (uuid,data) =>{
        return api.put(`/user/${uuid}`,data)
    },
    delete : (uuid) =>{
        return api.delete(`/user/${uuid}`)
    },
    addSistem : (uuid,data)=>{
        return api.post(`/user/sistem/${uuid}`,data)
    },
    deleteSistem : (uuid,uuid_sis)=>{
        return api.delete(`/user/sistem/${uuid}/${uuid_sis}`)
    },
    updateSistem : (uuid,uuid_sistem,data) =>{
        return api.put(`/user/sistem/${uuid}/${uuid_sistem}`,data)
    },
    getUserReport : (uuid_sistem) =>{
        return api.get(`/user/sistem/${uuid_sistem}`)
    }
}
export default ApiUser