import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import Dialog from './Dialog'
import Radio from './Radio'
import Input from './Input'
import AddButton from './AddButton'
import InputField from './InputField'
import { HiEye, HiEyeSlash } from 'react-icons/hi2'

function FormAddUserMobile(props) {
    const [eye, setEye] = useState(false)
    const [eye2, setEye2] = useState(false)
    const methodMobileUser = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                uuid: yup.string().required(),
                password: yup.string().required('tidak boleh kosong').min(8, "minimal 8 karakter"),
                confirmPassword: yup.string().oneOf([yup.ref('password'), null], "password harus sama")
            })
        )
    })
    useEffect(() => {
        methodMobileUser.reset({ uuid: props.uuid, password: "", confirmPassword: "" })
    }, [props.showForm, props.uuid])
    return (
        <Dialog show={props.showForm} toggle={props.closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Mobile User</h1>
                </div>
                <form onSubmit={methodMobileUser.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                    <div className=''>
                        <Input
                            className="w-full py-2"
                            type={eye ? "text" : "password"}
                            label="Password"
                            method={methodMobileUser}
                            methodName="password"
                            icon={eye ? <HiEye className='w-6 h-6 -ml-8 cursor-pointer absolute right-5 mt-3' onClick={() => setEye(!eye)} /> : <HiEyeSlash className='w-6 h-6 my-auto -ml-8 cursor-pointer absolute right-5 mt-3' onClick={() => setEye(!eye)} />}
                        />
                        <Input
                            className="w-full py-2"
                            type={eye2 ? "text" : "password"}
                            label="Confirm Password"
                            method={methodMobileUser}
                            methodName="confirmPassword"
                            icon={eye2 ? <HiEye className='w-6 h-6 -ml-8 cursor-pointer absolute right-5 mt-3' onClick={() => setEye2(!eye2)} /> : <HiEyeSlash className='w-6 h-6 my-auto -ml-8 cursor-pointer absolute right-5 mt-3' onClick={() => setEye2(!eye2)} />}
                        />
                    </div>
                    <AddButton />
                </form>
            </div>
        </Dialog>)
}

export default FormAddUserMobile