import React, { useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import Loader from './Loader'
import { HiUser, HiOutlineListBullet, HiOutlineXMark, HiOutlineBars2, HiOutlinePencilSquare, HiOutlineArrowRightOnRectangle, HiOutlineUser } from "react-icons/hi2"
import { menuSantri, menuSpp, menuUtama } from './Menu'
import FormEditSelf from './FormEditSelf'
import { useDispatch, useSelector } from 'react-redux'
import { editSelf } from '../store/actions/user'
import SnackBar from './SnackBar'
import { islogout } from '../store/actions/auth'



function Sidebar() {
    const {generalUser,super_admin,username} = useSelector((state)=>state.auth)
    const [sideMenu, setSideMenu] = useState(false)
    const [refrensiDrop, setRefrensiDrop] = useState(false)
    const [formUser, setFormUser] = useState(false)
    const [dropUser, setDropUser] = useState(false)
    const {msgUser} = useSelector((state)=>state.user)
    const [snack,setSnack] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation()
    const date = new Date()
    const tahun = date.getFullYear()
    const edit = (r) =>{
        dispatch(editSelf(r))
        setFormUser(!formUser)
        setSnack(!snack)
    }
    const out = () =>{
        dispatch(islogout())
    }
    return (
        <div className=''>
            <Loader show={false} />
            <SnackBar show={snack} toggle={setSnack} msg={msgUser}/>
            <FormEditSelf showForm={formUser} closeForm={setFormUser} submit={edit}/>
            <header className='bg-base py-4 md:px-8 px-4 flex justify-between  w-full'>
                <div className='flex md:space-x-2 space-x-4'>
                    <HiOutlineListBullet onClick={() => setSideMenu(!sideMenu)} className='md:w-9 md:h-9 w-8 h-8 my-auto block bg-slate-200 md:mt-1 rounded-full p-1 cursor-pointer' />
                    <div className='flex'>
                        <h1 className='text-4xl text-white'>Cakep</h1>
                        <h1 className='text-xl text-white'>in</h1>
                    </div>
                </div>
                <div>
                    <div className=' my-auto mx-auto relative'>
                        <HiOutlineBars2 className='w-10 h-10 bg-slate-200 rounded-full p-1 cursor-pointer ' onClick={() => setDropUser(!dropUser)} />
                        <div className={`absolute -left-[5rem] transition-all ease-in-out duration-300 z-50 ${dropUser ? "-bottom-24 visible text-opacity-100 bg-opacity-100" : "-bottom-20 invisible text-transparent bg-opacity-0"}  bg-slate-200 rounded-md`}>
                            <div className='flex px-4 py-2 md:space-x-3 hover:bg-sky-300 cursor-pointer w-32' onClick={() => {
                                setDropUser(!dropUser)
                                setFormUser(!formUser)
                            }}>
                                <HiOutlinePencilSquare className='my-auto w-5 h-5' />
                                <h1 className='text-xl my-auto'>Edit</h1>
                            </div>
                            <div className='flex px-4 py-2 md:space-x-3 hover:bg-sky-300 cursor-pointer w-32' onClick={out}>
                                <HiOutlineArrowRightOnRectangle className='my-auto w-5 h-5' />
                                <h1 className='text-xl my-auto'>Logout</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className='flex' >
                <div className={`md:static fixed md:top-0 top-0 bg-slate-50 md:h-[91vh] h-screen  w-[85vw] md:z-0 z-10 overflow-y-hidden hover:overflow-y-auto transition-all ease-in-out duration-300 border-r-2 ${sideMenu ? "left-0 md:w-[30vw]" : "-left-[85vw] md:w-0"}`}>
                    <div className='w-full flex justify-end p-3 md:hidden'>
                        <HiOutlineXMark onClick={() => setSideMenu(!sideMenu)} className='w-7 h-7' />
                    </div>
                    <div className='p-4 md:my-5 flex'>
                        <HiUser className='w-12 h-12 bg-white rounded-full p-2 my-auto' />
                        <div>
                            <h1 className='mx-3 text-lg font-light'>{username}</h1>
                        </div>
                    </div>
                    {menuUtama.map((e, id) => (
                        <Link onClick={() => setSideMenu(!sideMenu)} to={`${e.link}`} key={id} className={`flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${location.pathname === e.link && e.drop === refrensiDrop ? "border-l-4 border-sky-500 text-sky-700" : "border-l-4 border-transparent"}`}>
                            <div className='my-auto' >
                                {e.logo}
                            </div>
                            <h1 className='text-lg'>{e.title}</h1>
                        </Link>
                    ))}
                    <h1 className='px-6 text-xl text-gray-500 font-light my-2'>Santri</h1>
                    {menuSantri.map((e, id) => (
                        <Link onClick={() => setSideMenu(!sideMenu)} to={`${e.link}`} key={id} className={`flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${location.pathname === e.link && e.drop === refrensiDrop ? "border-l-4 border-sky-500 text-sky-700" : "border-l-4 border-transparent"}`}>
                            <div className='my-auto' >
                                {e.logo}
                            </div>
                            <h1 className='text-lg'>{e.title}</h1>
                        </Link>
                    ))}
                    <h1 className={`px-6 text-xl text-gray-500 font-light my-2 ${super_admin ? "block" : "hidden"}`}>Panel</h1>
                    {menuSpp.map((e, id) => (
                        <div className={`${super_admin ? "block" : "hidden"}`} key={id} onClick={() => {
                            setDropUser(false)
                        }}>
                            {e.drop ?
                                <div key={id}  >
                                    <div onClick={() => setRefrensiDrop(!refrensiDrop)} className={`flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 cursor-pointer border-l-4 border-transparent `}>
                                        <div className='my-auto'>
                                            {e.logo}
                                        </div>
                                        <h1 className={`text-lg`}>{e.title}</h1>
                                        <div className='flex justify-end w-full my-auto'>
                                            {e.dropIcon}
                                        </div>
                                    </div>
                                    <div className={refrensiDrop ? "block" : "hidden"}>
                                        {e.menuDrop.map((d, ind) => (
                                            <Link onClick={() => setSideMenu(!sideMenu)} to={`${d.link}`} key={ind} className={`flex hover:text-sky-600  py-2 px-9 font-light transition-colors ease-in-out duration-300 ${location.pathname === d.link ? "border-l-4 border-sky-500 text-sky-700" : "border-l-4 border-transparent"} ${d.title !== "List User" ? "block" : generalUser !== false || super_admin !== false ? "block" : "hidden"}`}>
                                                <h1 className={`text-lg `}>{d.title}</h1>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                                :
                                <Link onClick={() => setSideMenu(!sideMenu)} to={`${e.link}`} key={id} className={`flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${location.pathname === e.link && e.drop !== refrensiDrop ? "border-l-4 border-sky-500 text-sky-700" : "border-l-4 border-transparent"} `}>
                                    <div className='my-auto'>
                                        {e.icons}
                                    </div>
                                    <h1 className='text-lg'>{e.title}</h1>
                                </Link>

                            }
                        </div>
                    ))}
                </div>
                <div className='md:w-[100vw] w-full relative bg-slate-100 h-[90vh] overflow-y-auto' onClick={() => setDropUser(false)}>
                    <Outlet />
                    <footer className=' p-3 flex space-x-2'>
                        <h1 className='font-light md:text-xl'>COPYRIGHT &#9400; {tahun}</h1>
                        <h1 className='font-extralight md:text-xl text-gray-900'>Ponpes Abu Hurairah.</h1>
                    </footer>
                </div>
                <div onClick={() => setSideMenu(!sideMenu)} className={`bg-black bg-opacity-80 fixed top-0 left-0 w-screen h-screen md:hidden ${sideMenu ? "block" : "hidden"}`}>
                </div>
            </div>
        </div>
        )
}

export default Sidebar