import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import HeaderUi from '../component/HeaderUi'
import Table from '../component/Table'
import * as yup from "yup"
import Input from '../component/Input'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUser } from '../store/actions/user'
import Options from '../component/Options'
import { emptyState, emptyStateLaporan, getAllLaporan, getAllLaporanDonatur } from '../store/actions/laporan'
import { currency } from '../component/Currency'
import Loader from '../component/Loader'
import moment from 'moment'

function LaporanDonatur() {
    const dispatch = useDispatch()
    const { allUser } = useSelector((state) => state.user)
    const { superAdmin,role } = useSelector((state) => state.auth)
    const { allLaporanDonatur, loadingLaporan } = useSelector((state) => state.laporan)
    const methodLaporan = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                dari: yup.string().required(),
                sampai: yup.string().required(),
                uuid_user: yup.string()
            })
        )
    })
    const getLaporan = (e) => {
        dispatch(getAllLaporanDonatur(e))
    }
    const userOpt = allUser.map((d) => {
        return { value: d.uuid, label: d.nama }
    })
    userOpt.splice(0, 0, { value: "-", label: "Semua" })
    useEffect(() => {
        if (role === "superadmin") {
            dispatch(getAllUser())
        }
        dispatch(emptyStateLaporan())
    }, [])
    let jml = 0
    allLaporanDonatur.map((d) => {
        return jml += parseInt(d.total)
    })
    return (
        <div>
            <Loader show={loadingLaporan} />
            <HeaderUi titleHeader="Laporan Pembayaran" />
            <Table
                classFirst="hidden"
            >
                <div>
                    <form onSubmit={methodLaporan.handleSubmit(getLaporan)} className='md:flex md:flex-row flex-col md:space-x-3'>
                        <div className="grid md:grid-cols-4 grid-cols-2 gap-3 w-full">
                            <Input label="Dari" type="date" method={methodLaporan} methodName="dari" />
                            <Input label="Sampai" type="date" method={methodLaporan} methodName="sampai" />
                            <div className={superAdmin ? "block" : "hidden"}>
                                <Options label="Teller" method={methodLaporan} methodName="uuid_user" options={userOpt} />
                            </div>
                        </div>
                        <button type='submit' className='border border-blue-500 bg-slate-100 px-8 py-[7px] my-7'>Proses</button>
                    </form>
                    <div className='flex space-x-2 border-bya'>
                        <h1 className='text-lg font-light'>Total Semua</h1>
                        <h1 className='text-lg'> : </h1>
                        <h1 className='text-lg text-blue-700'>{currency(jml)}</h1>
                    </div>
                    {allLaporanDonatur.length === 0 ? <div>
                        <h1 className='font-light text-center my-10 text-xl'>Tidak Ada Laporan</h1>
                    </div> :
                        <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden z-0">
                            <table className="w-full text-sm text-left text-gray-500 ">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            No
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Metode
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            UP
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            SPP
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Total
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allLaporanDonatur.map((d, id) => {
                                        return <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                                            <td className='px-6 py-3'>{id + 1}</td>
                                            <td className='px-6 py-3'>{d.metode_pembayaran}</td>
                                            <td className='px-6 py-3'>{currency(parseInt(d.spp))}</td>
                                            <td className='px-6 py-3'>{currency(parseInt(d.total))}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </Table >
        </div >
    )
}

export default LaporanDonatur