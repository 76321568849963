import React, { useEffect, useState } from 'react'
import { AiOutlineLoading3Quarters } from "react-icons/ai"

function ButtonNext(props) {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading])
    return (
        <button type={props.type} className={props.class} onClick={props.onClick} disabled={props.disabled}>
            {loading ? <AiOutlineLoading3Quarters className='w-6 h-6 mx-auto animate-spin' /> : <div>{props.name}    {props.icon}
            </div>}
        </button>
    )
}

export default ButtonNext