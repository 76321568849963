import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLembaga, getLembagaList, getRole, getSistem } from '../store/actions/optional'
import Options from './Options'
import Radio from './Radio'

function SistemHelp(props) {
    const dispatch = useDispatch()
    const { role, sistem, lembagaList } = useSelector((state) => state.optional)
    const sistemWatch = props.method.watch(`sistem[${props.id}].uuid_sistem`)
    useEffect(() => {
        dispatch(getSistem())
        dispatch(getLembagaList())
    }, [])
    const watchRole = props.method.watch(`sistem[${props.id}].uuid_role`)
    const find = role.find((e) => e.uuid === watchRole)?.nama_role
    const lemOpt = lembagaList.map((d) => {
        return { value: d.no_lembaga, label: d.nama_lembaga }
    })
    useEffect(() => {
        if (sistemWatch !== undefined && sistemWatch !== null && sistemWatch !== "") {
            dispatch(getRole(sistemWatch))
        }
    }, [sistemWatch])
    const sistemOpt = sistem.map((d) => {
        return { value: d.uuid, label: d.nama_sistem }
    })
    const roleOpt = role.map((d) => {
        return { value: d.uuid, label: d.nama_role }
    })
    return (
        <div className='w-full'>
            <Options label="Sistem" method={props.method} methodName={`sistem[${props.id}].uuid_sistem`} options={sistemOpt} />
            <Options label="Role" method={props.method} methodName={`sistem[${props.id}].uuid_role`} options={roleOpt} />
            <div className='mb-1'>
                <h1 className='font-light text-lg mb-2'>Super Admin</h1>
                <div className='flex my-auto md:space-x-0 space-x-2'>
                    <Radio method={props.method} methodName={`sistem[${props.id}].super_admin`} value="true" label="Iya" />
                    <Radio method={props.method} methodName={`sistem[${props.id}].super_admin`} value="false" label="Tidak" />
                </div>
                {props.method.formState.errors[`sistem[${props.id}].super_admin`] && (
                    <p className="text-red-600 mt-1 mx-1">
                        {props.method.formState.errors[`sistem[${props.id}].super_admin`].message}
                    </p>
                )}
            </div>
            <div className={find === "admin-lembaga" ? "block" : "hidden"}>
                <Options label="Lembaga" method={props.method} methodName={`sistem[${props.id}].no_lembaga`} options={lemOpt} />
            </div>
        </div>
    )
}

export default SistemHelp