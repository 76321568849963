import { createSlice } from "@reduxjs/toolkit";
import { allBelanjaByNuwb,addBelanja } from "../actions/belanja";

export const belanjaStore = createSlice({
    name:"belanja",
    initialState : {
        belanjaAll : {},
        loadingBelanja : false,
        msgBelanja : ""
    },
    reducers : {
        clearBelanja : (state)=>{
            state.belanjaAll = {}
            state.msgBelanja = ""
        }
    },
    extraReducers : builder =>{
        builder
        .addCase(allBelanjaByNuwb.pending,(state)=>{
            state.loadingBelanja = true
        })
        .addCase(allBelanjaByNuwb.fulfilled,(state,action)=>{
            state.belanjaAll = action.payload
            state.loadingBelanja = false
        })
        .addCase(allBelanjaByNuwb.rejected,(state,action)=>{
            state.belanjaAll = []
            state.msgBelanja = action.payload
            state.loadingBelanja = false
        })
        .addCase(addBelanja.pending,(state)=>{
            state.loadingBelanja = true
        })
        .addCase(addBelanja.fulfilled,(state,action)=>{
            state.msgBelanja = action.payload.msg
            state.loadingBelanja = false
        })
        .addCase(addBelanja.rejected,(state,action)=>{
            state.msgBelanja = action.payload
            state.loadingBelanja = false
        })
    }
})

export const belanjaActions = belanjaStore.actions
export default belanjaStore.reducer