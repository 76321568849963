import { createSlice } from "@reduxjs/toolkit";
import { islogin, islogout } from "../actions/auth";

export const authStore = createSlice({
    name:"auth",
    initialState : {
        token : "",
        username : "",
        nama : "",
        role : "",
        sistem : "",
        super_admin : false,
        generalUser : false,
        loadingAuth : false,
        msgAuth : ""
    },
    extraReducers : builder =>{
        builder
        .addCase(islogin.pending,(state)=>{
            state.loadingAuth = true
        })
        .addCase(islogin.fulfilled,(state,action)=>{
            state.loadingAuth = false
            state.token = action.payload.token
            state.nama = action.payload.user.nama
            state.username = action.payload.user.username
            state.role = action.payload.user.sistems.find((e)=>e.sistem === "cakepin")?.role
            state.super_admin = action.payload.user.sistems.find((e)=>e.sistem === "cakepin")?.super_admin
            state.sistem = action.payload.user.sistems.find((e)=>e.sistem === "cakepin")?.sistem
            state.generalUser = action.payload?.user?.general_user

        })
        .addCase(islogin.rejected,(state,action)=>{
            state.loadingAuth = false
            state.msgAuth = action.payload
        })
        .addCase(islogout.pending,(state)=>{
            state.loadingAuth = true
        })
        .addCase(islogout.fulfilled,(state)=>{
            state.loadingAuth = false
            state.nama = ""
            state.token = ""
            state.username = ""
            state.generalUser = false
            state.super_admin = false
        })
        .addCase(islogout.rejected,(state)=>{
            state.loadingAuth = false
            state.nama = ""
            state.token = ""
            state.username = ""
            state.generalUser = false
            state.super_admin = false
        })
    }
})
export default authStore.reducer