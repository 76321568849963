import api from "./http";

const ApiPembayaran = {
  get: (data) => {
    return api.post(`/cakepin/tagihan/get`, data);
  },
  bayar: (data) => {
    return api.post(`/cakepin/pembayaran`, data);
  },
  getTunggakan: (data) => {
    return api.post(`/cakepin/tagihan/tunggakan`, data);
  },
  bukti: (data) => {
    return api.post(`/cakepin/pembayaran/bukti`, data);
  },
  getTunggakanDonatur: (data) => {
    return api.post(`/cakepin/tagihan/tunggakan-donatur`, data);
  },
  cancelPembayaransantri: (data) => {
    return api.post(`/cakepin/pembayaran/cancel`, data);
  },
  getHistorySantri: (nuwb, thn) => {
    return api.get(`/cakepin/pembayaran/${nuwb}/${thn}`);
  }};
export default ApiPembayaran;
