import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiPembayaran from "../../api/pembayaran";

export const getPembayaran = createAsyncThunk(
    'pembayaran/get',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiPembayaran.get(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const postPembayaran = createAsyncThunk(
    'pembayaran/post',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiPembayaran.bayar(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const allTunggakan = createAsyncThunk(
    'tunggakan/all',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiPembayaran.getTunggakan(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const cetakBukti = createAsyncThunk(
    'cetak/bukti',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiPembayaran.bukti(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getTunggakanDonatur = createAsyncThunk(
    'tunggakan/donatur',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiPembayaran.getTunggakanDonatur(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const batalPembayaran = createAsyncThunk(
    'pembayaran/batal',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiPembayaran.cancelPembayaransantri(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const historySantri = createAsyncThunk(
    'history/santri',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiPembayaran.getHistorySantri(data.nuwb,data.thn)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
