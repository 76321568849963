import React, { forwardRef, useImperativeHandle, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "./Input";
import Radio from "./Radio";
import Options from "./Options";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { pekerjaan, pendidikan, penghasilan, status } from "./OptSelect";
import { findIbu } from "../store/actions/santri";
import { santriActions } from "../store/slice/santri";

const FormIbu = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { santriOne, loadingSantri, ibuOne } = useSelector(
    (state) => state.santri
  );
  const location = useLocation();
  const methodIbu = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        nama: yup.string().required("Tidak Boleh Kosong"),
        t_lahir: yup.string().required("Tidak Boleh Kosong"),
        tgl_lahir: yup.string().required("Tidak Boleh Kosong"),
        thn_lahir: yup.string().required("Tidak Boleh Kosong"),
        nik: yup
          .string()
          .min(16, "Tidak Boleh Kurang dari 16 karakter")
          .max(16, "Tidak Boleh Lebih dari 16 karakter")
          .required("Tidak Boleh Kosong"),
        kewarganegaraan: yup
          .string()
          .oneOf(["WNI", "WNA"], "Pilih Salah Satu")
          .required("Tidak Boleh Kosong"),
        alamat: yup.string().required("Tidak Boleh Kosong"),
        pendidikan: yup.string().required("Tidak Boleh Kosong"),
        pekerjaan: yup.string().required("Tidak Boleh Kosong"),
        penghasilan: yup.string().required("Tidak Boleh Kosong"),
        status: yup.string().required("Tidak Boleh Kosong"),
        no_wa: yup.string().required("Tidak Boleh Kosong"),
        no_hp: yup.string().required("Tidak Boleh Kosong"),
      })
    ),
  });
  const watchNik = methodIbu.watch("nik");
  useImperativeHandle(ref, () => ({
    method: methodIbu,
  }));
  useEffect(() => {
    if (ibuOne?.nik) {
      methodIbu.reset({ ...ibuOne });
    }
  }, [ibuOne?.nik]);
  useEffect(() => {
    if (location.pathname !== "/home/input/santri") {
      if (santriOne?.ibu?.uuid) {
        methodIbu.reset({ ...santriOne.ibu });
      } else {
        methodIbu.reset({
          nama: "",
          t_lahir: "",
          tgl_lahir: "",
          thn_lahir: "",
          nik: "",
          kewarganegaraan: "",
          pendidikan: "",
          pekerjaan: "",
          penghasilan: "",
          alamat: "",
          status: "",
          no_hp: "",
          no_wa: "",
        });
      }
    }
  }, [santriOne?.ibu]);
  useEffect(() => {
    dispatch(santriActions.clearIbu());
    if (watchNik?.length === 16) {
      dispatch(findIbu({ nik: watchNik, statusOrtu: "ibu" }));
    }
  }, [watchNik,santriOne?.ibu]);
  return (
    <div>
      <form className="md:grid md:grid-cols-2 md:gap-5">
        <Input label="NIK" method={methodIbu} methodName="nik" type="number" />
        <Input label="Nama Lengkap" method={methodIbu} methodName="nama" />
        <Input label="Tempat Lahir" method={methodIbu} methodName="t_lahir" />
        <Input
          label="Tanggal Lahir"
          method={methodIbu}
          methodName="tgl_lahir"
          type="date"
        />
        <Input
          label="Tahun Lahir"
          method={methodIbu}
          methodName="thn_lahir"
          type="number"
        />
        <div>
          <h1 className="font-light text-lg mb-2">kewarganegaraan</h1>
          <div className="flex my-auto md:space-x-0 space-x-2">
            <Radio
              method={methodIbu}
              methodName="kewarganegaraan"
              value="WNI"
              label="WNI"
            />
            <Radio
              method={methodIbu}
              methodName="kewarganegaraan"
              value="WNA"
              label="WNA"
            />
          </div>
          {methodIbu.formState.errors["kewarganegaraan"] && (
            <p className="text-red-600 mt-1 mx-1">
              {methodIbu.formState.errors["kewarganegaraan"].message}
            </p>
          )}
        </div>
        <Input label="Alamat" method={methodIbu} methodName="alamat" />
        <Options
          label="Pendidikan"
          method={methodIbu}
          methodName="pendidikan"
          options={pendidikan}
        />
        <Options
          label="Pekerjaan"
          method={methodIbu}
          methodName="pekerjaan"
          options={pekerjaan}
        />
        <Options
          label="Penghasilan"
          method={methodIbu}
          methodName="penghasilan"
          options={penghasilan}
        />
        <Options
          label="Status"
          method={methodIbu}
          methodName="status"
          options={status}
        />
        <Input label="Nomor WA" method={methodIbu} methodName="no_wa" />
        <Input label="Nomor HP" method={methodIbu} methodName="no_hp" />
      </form>
    </div>
  );
});

export default FormIbu;
