import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { HiOutlineTrash } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import { currency } from '../component/Currency'
import FormDeletePermanen from '../component/FormDeletePermanen'
import HeaderUi from '../component/HeaderUi'
import Loader from '../component/Loader'
import SnackBar from '../component/SnackBar'
import Table from '../component/Table'
import { deleteLaporanPermanen, getAllLaporanTerhapus } from '../store/actions/laporan'

function TablePmebayaranTerhapus() {
    const dispatch = useDispatch()
    const {allLaporanTerhapus,loadingLaporan,msgLaporan} = useSelector((state)=>state.laporan)
    const [snack,setSnack] = useState(false)
    const [formAprove,setFormAprove] = useState(false)
    const [uuid,setUuid] = useState("")
    useEffect(()=>{
        dispatch(getAllLaporanTerhapus())
    },[snack])
    const aprovePermanen = (e) =>{
        dispatch(deleteLaporanPermanen(e))
        setFormAprove(!formAprove)
        setSnack(!snack)
    }
    return (
        <div>
            <HeaderUi titleHeader="Laporan Pembayaran Terhapus" />
            <Loader show={loadingLaporan}/>
            <SnackBar show={snack} toggle={setSnack} msg={msgLaporan}/>
            <FormDeletePermanen showForm={formAprove} closeForm={setFormAprove} uuid={uuid} submit={aprovePermanen} />
            <Table
                classFirst="hidden"
            >
                <div>
                    {allLaporanTerhapus.length === 0 ? <div>
                        <h1 className='font-light text-center my-10 text-xl'>Tidak Ada History</h1>
                    </div> :
                        <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden">
                            <table className="w-full text-sm text-left text-gray-500 ">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            Tgl
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Nuwb
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Nama Santri
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            User
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            ket
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Metode
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Jumlah
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Catatan
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Proses
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allLaporanTerhapus.map((d, id) => (
                                        <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                                            <td className='px-6 py-3'>{moment(d.createdAt).format("DD-MM-YYYY hh:mm")}</td>
                                            <td className='px-6 py-3'>{d.pembayaran?.nuwb}</td>
                                            <td className='px-6 py-3'>{d.pembayaran?.santri?.nama}</td>
                                            <td className='px-6 py-3'>{d.user?.nama}</td>
                                            <td className='px-6 py-3'>{d.pembayaran?.ket}</td>
                                            <td className='px-6 py-3'>{d.pembayaran?.metode_pembayaran}</td>
                                            <td className='px-6 py-3'>{d.pembayaran.jumlah !== null ? currency(d.pembayaran.jumlah) : "0"}</td>
                                            <td className='px-6 py-3'>{d.catatan}</td>
                                            <td className='px-6 py-3'>{d.processed ? "Diproses" : "Menunggu"}</td>
                                            <td className='px-6 py-3'>{<HiOutlineTrash className='w-6 h-6 mx-3 cursor-pointer' onClick={() => {
                                                setUuid(d.uuid_process)
                                                setFormAprove(!formAprove)
                                            }} />}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </Table>
        </div>
    )
}

export default TablePmebayaranTerhapus