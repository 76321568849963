import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect} from 'react'
import { useForm } from 'react-hook-form'
import HeaderUi from '../component/HeaderUi'
import Table from '../component/Table'
import * as yup from "yup"
import { useDispatch, useSelector } from 'react-redux'
import Options from '../component/Options'
import { bulanTunggakanOpt } from '../component/OptSelect'
import { getKelas, getLembaga, getRuang } from '../store/actions/optional'
import { allTunggakan } from '../store/actions/pembayaran'
import { currency } from "../component/Currency"
import Loader from '../component/Loader'

function ListTunggakan() {
  const { tunggakan, loadingPembayaran } = useSelector((state) => state.pembayaran)
  const { lembaga,kelas,ruang } = useSelector((state) => state.optional)
  const dispatch = useDispatch()
  const methodTunggakan = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        kode_lembaga: yup.number().required("Lembaga harus di isi"),
        bulan: yup.string().required("Bulan harus di isi"),
        kelas:yup.number().required("Kelas harus di isi"),
        ruang : yup.string().required("Ruang harus di isi"),
        lunas : yup.number().required("Harus di isi"),
        tahun: yup.number().required("Tahun harus di isi"),
        alumni:yup.number().required("Alumni harus di isi")
      })
    )
  })
  useEffect(() => {
    dispatch(getLembaga())
  }, [])
  const lemOpt = lembaga.map((d) => {
    return { value: d.kode_lembaga, label: d.nama }
  })
  const getTunggak = (e) => {
    dispatch(allTunggakan(e))
  }
  const watchLem = methodTunggakan.watch("kode_lembaga")
  const watchKelas = methodTunggakan.watch("kelas")
  useEffect(()=>{
    if(watchLem !== "" && watchLem !== undefined){
      dispatch(getKelas(watchLem))
    }
  },[watchLem])
  useEffect(()=>{
    if(watchKelas !== "" && watchKelas !== undefined){
      dispatch(getRuang(watchKelas))
    }
  },[watchKelas])
  const kelasOpt = kelas.map((d)=>{
    return {value:d.id,label:d.kelas}
  })
  const ruangOpt = ruang.map((d)=>{
    return {value:d.ruang,label:d.ruang}
  })
  kelasOpt.splice(0,0,{value:0,label:"Semua"})
  ruangOpt.splice(0,0,{value:"-",label:"Semua"})
  const ketOpt = [
    {value:1,label:"Lunas"},
    {value:0,label:"Belum Lunas"},
  ]
  const tahunOpt = [
    {value:2,label:"Semua"},
    {value:1,label:'Aktif'},
    {value:0,label:"Tidak Aktif"}
  ]
  const alumniOpt = [
    {value:3,label:"Semua"},
    {value:0,label:"Lain Lainnya"},
    {value:1,label:"Aktif"},
    {value:2,label:"Lulus"}
  ]
  let jml = 0
  tunggakan.map((d) => {
    return jml += parseInt(d.total)
  })
  return (
    <div>
      <HeaderUi titleHeader="Tagihan Santri" location="list" />
      <Loader show={loadingPembayaran} />
      <Table
        classFirst="hidden"
      >
        <div className='bg-white'>
          <form onSubmit={methodTunggakan.handleSubmit(getTunggak)} className='md:flex md:flex-row flex-col md:space-x-3'>
            <div className="grid md:grid-cols-7 grid-cols-2 gap-3 w-full">
              <Options label="Lembaga" method={methodTunggakan} methodName="kode_lembaga" options={lemOpt} />
              <Options label="Sampai Bulan" method={methodTunggakan} methodName="bulan" options={bulanTunggakanOpt} />
              <Options label="Kelas" method={methodTunggakan} methodName="kelas" options={kelasOpt} />
              <Options label="Ruang" method={methodTunggakan} methodName="ruang" options={ruangOpt} />
              <Options label="Tahun" method={methodTunggakan} methodName="tahun" options={tahunOpt} />
              <Options label="Saring" method={methodTunggakan} methodName="lunas" options={ketOpt} />
              <Options label="Status" method={methodTunggakan} methodName="alumni" options={alumniOpt} />
              {/* <Options label="Spesifikasi" method={methodTunggakan} methodName="spesifikasi" options={spekOpt} /> */}
            </div>
            <button type='submit' className='border border-blue-500 bg-slate-100 px-8 py-[7px] my-7'>Proses</button>
          </form>
          <div className='flex space-x-2 border-bya'>
            <h1 className='text-lg font-light'>Total Tunggakan</h1>
            <h1 className='text-lg'> : </h1>
            <h1 className='text-lg text-blue-700'>{currency(jml)}</h1>
          </div>
          {tunggakan.length === 0 ? <div>
            <h1 className='font-light text-center my-10 text-xl'>Tidak Ada Tunggakan</h1>
          </div> :
            <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden z-0">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      No
                    </th>
                    <th scope="col" className="px-6 py-3">
                      NUWB
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Nama
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Kelas
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Ruang
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Wali
                    </th>
                    <th scope="col" className="px-6 py-3">
                      No Telp
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Spp
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Up
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tunggakan.map((d, id) => {
                    return <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                      <td className='px-6 py-3'>{id + 1}</td>
                      <td className='px-6 py-3'>{d.nuwb}</td>
                      <td className='px-6 py-3'>{d.nama}</td>
                      <td className='px-6 py-3'>{d.kelas}</td>
                      <td className='px-6 py-3'>{d.ruang}</td>
                      <td className='px-6 py-3'>{d.wali}</td>
                      <td className='px-6 py-3'>{d.hp}</td>
                      <td className='px-6 py-3'>{d.status === 1 ? "Aktif" : d.status === 2 ? "Lulus" : "Lainnya"}</td>
                      <td className='px-6 py-3'>{currency(d.spp)}</td>
                      <td className='px-6 py-3'>{currency(d.up)}</td>
                      <td className='px-6 py-3'>{currency(d.total)}</td>
                      {/* <td className='px-6 py-3'>{d.kode_lembaga}</td>
                      <td className='px-6 py-3'>{d.nama}</td>
                      <td className='px-3 py-3'><HiOutlinePencilSquare className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                        setFormEdit(!formEdit)
                        setData(d)
                      }} /></td> */}
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
          }
        </div>
      </Table>
    </div>
  )
}

export default ListTunggakan