import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllTahunSistem } from "../store/actions/tahunSistem";


export default function useGetTahunSistem(trigger){
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getAllTahunSistem())
    },[trigger])
}