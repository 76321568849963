import React, { useEffect, useState } from 'react'
import { HiOutlinePencil, HiOutlinePencilSquare } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import { currency } from '../component/Currency'
import FormAddListSpp from '../component/FormAddListSpp'
import FormEditListSpp from '../component/FormEditListSpp'
import HeaderUi from '../component/HeaderUi'
import SnackBar from '../component/SnackBar'
import Table from '../component/Table'
import { allSpp, postSpp, updateListSpp } from '../store/actions/spp'

function ListSpp() {
    const dispatch = useDispatch()
    const { allListSpp,msgSpp } = useSelector((state) => state.spp)
    const [formList, setFormList] = useState(false)
    const [formEditList,setFormEditList] = useState(false)
    const [snack,setSnack] = useState(false)
    const [data,setData] = useState({})
    const addNewList = (e) => {
        dispatch(postSpp(e))
        setFormList(!formList)
        setSnack(!snack)
    }
    const editList = (e) =>{
        dispatch(updateListSpp({uuid:data.uuid,update:e}))
        setFormEditList(!formEditList)
        setSnack(!snack)
    }
    useEffect(() => {
        dispatch(allSpp())
    }, [snack])
    return (
        <div>
            <HeaderUi titleHeader="List Spp" location="List" />
            <SnackBar show={snack} toggle={setSnack} msg={msgSpp} />
            <FormAddListSpp showForm={formList} closeForm={setFormList} submit={addNewList} />
            <FormEditListSpp showForm={formEditList} closeForm={setFormEditList} submit={editList} data={data}/>
            <Table
                titleFirst="Tambah List"
                clickFirst={() => {
                    setFormList(!formList)
                }}
            >
                <div className='bg-white'>
                    {allListSpp.length === 0 ? <div>
                        <h1 className='font-light text-center my-10 text-xl'>Tidak Ada List</h1>
                    </div> :
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-7">
                            <table className="w-full text-sm text-left text-gray-500 ">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            No
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Lembaga
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Tahun Ajar
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Golongan
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Spp
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            UP
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Edit
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allListSpp.map((d, id) => (
                                        <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                                            <td className='px-6 py-3'>{id + 1}</td>
                                            <td className='px-6 py-3'>{d.lembaga?.nama}</td>
                                            <td className='px-6 py-3'>{d.thn_ajar}</td>
                                            <td className='px-6 py-3'>{d.ket}</td>
                                            <td className='px-6 py-3'>{currency(d.spp)}</td>
                                            <td className='px-6 py-3'>{currency(d.up)}</td>
                                            <td className='px-3 py-3'><HiOutlinePencilSquare className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                                setFormEditList(!formEditList)
                                                setData(d)
                                            }} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </Table>
        </div>
    )
}

export default ListSpp