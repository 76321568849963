import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Input from '../component/Input';
import { useDispatch, useSelector } from 'react-redux';
import { HiEye, HiEyeSlash } from 'react-icons/hi2';
import SnackBar from '../component/SnackBar';
import Loader from '../component/Loader';
import { islogin } from '../store/actions/auth';


function Login() {
    const [eye, setEye] = useState(false)
    const [snackLogin, setSnackLogin] = useState(false)
    const dispatch = useDispatch()
    const {msgAuth,loadingAuth } = useSelector((state) => state.auth)
    const method = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                username: yup.string().required("Username Tidak Boleh Kosong"),
                password: yup.string().required("Password Tidak Boleh Kosong").min(8, "Password Minimal 8 Karakater"),
                sistem : yup.string()
            })
        ),
        defaultValues:{sistem:"cakepin"}
    })
    const isLogin = (e) => {
        dispatch(islogin(e))
        setSnackLogin(!snackLogin)

    }
    return (
        <div className='overflow-x-hidden bg-gray-100'>
            <Loader show={loadingAuth} size="w-5 h-5" />
            <SnackBar show={snackLogin} toggle={setSnackLogin} msg={msgAuth} />
            <form className='relative md:w-[30vw] md:h-[100vh] h-[100vh] flex flex-col justify-center mx-auto md:px-0 px-3' onSubmit={method.handleSubmit(isLogin)}>
                <div className='md:flex flex-col'>
                    <div className='flex justify-center md:ml-3'>
                        {/* <img className='w-32 h-32 md:mr-3 rounded-full md:mt-0 mt-14' src={logo} alt="" /> */}
                    </div>
                    <h1 className="md:text-[3vmin] text-xl mb-5 md:text-left text-center md:mt-3 mt-1 mx-auto">Cakep In</h1>
                    <h1 className="md:text-[3vmin] text-xl mb-5 md:text-center text-center md:mt-0 mt-12 mx-auto">Catatan Keuangan Pondok Abu Hurairah</h1>
                </div>
                <div className='space-y-8 mb-8'>
                    <Input
                        className="w-full py-2 px-3"
                        // tag={<User className='w-7 h-7 mr-1 mb-1' />}
                        label="username"
                        method={method}
                        methodName="username"
                    />
                    <Input
                        className="w-full py-2 px-3 "
                        type={eye ? "text" : "password"}
                        label="Password"
                        method={method}
                        methodName="password"
                        icon={eye ? <HiEye className='w-6 h-6 -ml-8 cursor-pointer absolute right-5 mt-3' onClick={() => setEye(!eye)} /> : <HiEyeSlash className='w-6 h-6 my-auto -ml-8 cursor-pointer absolute right-5 mt-3' onClick={() => setEye(!eye)} />}
                    />
                </div>
                <button
                    className="from-sky-600 to-sky-300 py-3 shadow-xl hover:from-sky-700 hover:to-sky-400 bg-gradient-to-bl mx-3 font-medium rounded-lg"
                    type="submit"
                >Masuk</button>
            </form>
        </div>
    )
}

export default Login