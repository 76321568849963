import React from 'react'

function TextArea(props) {
    const { error } = props.method.getFieldState(props.methodName)
    return (
        <div className={`flex flex-col ${props.className}`}>
            <label className='font-light text-gray-600'>{props.label}</label>
            <textarea {...props.method.register(props.methodName)} cols={50} rows={7} className={`py-[7px] px-2 outline-none border mt-1 w-full ${props.method.formState.errors[props.methodName] ? "border-red-600" : "border-[#624d4d] hover:border-slate-400"}`} />
            {error && <p className='text-red-600 mt-1 mx-1'>{error.message}</p>}

        </div>
    )
}

export default TextArea