import React, { useEffect, useState } from 'react'
import { HiOutlinePencilSquare } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import FormAddGolDonatur from '../component/FormAddGolDonatur'
import FormEditGolDonatur from '../component/FormEditGolDonatur'
import HeaderUi from '../component/HeaderUi'
import Loader from '../component/Loader'
import SnackBar from '../component/SnackBar'
import Table from '../component/Table'
import { addNewGolonganDonatur, getAllDonatur, updateOneGolonganDonatur } from '../store/actions/donatur'
import { getGolDonatur } from '../store/actions/optional'

function ListGolDonatur() {
    const dispatch = useDispatch()
    const {golDonatur,loadingOptional} = useSelector((state)=>state.optional)
    const {msgDonatur} = useSelector((state)=>state.donatur)
    const [formGolDonatur, setFormGolDonatur] = useState(false)
    const [formEditGol,setFormEditGol] = useState(false) 
    const [snack,setSnack] = useState(false)
    const [data,setData] = useState({})
    useEffect(() => {
        dispatch(getGolDonatur())
    }, [snack])
    useEffect(()=>{
        dispatch(getAllDonatur())
    },[])
    const add = (e) => {
        dispatch(addNewGolonganDonatur(e))
        setFormGolDonatur(!formGolDonatur)
        setSnack(!snack)
    }
    const edit = (e) =>{
        dispatch(updateOneGolonganDonatur({id:data.id,update:e}))
        setFormEditGol(!formEditGol)
        setSnack(!snack)
    }
    return (
        <div>
            <Loader show={loadingOptional}/>
            <SnackBar show={snack} toggle={setSnack} msg={msgDonatur}/>
            <HeaderUi titleHeader="Golongan Donatur" />
            <FormAddGolDonatur showForm={formGolDonatur} closeForm={setFormGolDonatur} submit={add} />
            <FormEditGolDonatur showForm={formEditGol} closeForm={setFormEditGol} submit={edit} data={data}/>
            <Table
                titleFirst="Tambah"
                clickFirst={() => {
                    setFormGolDonatur(!formGolDonatur)
                }}
            >
                <div className='bg-white'>
                    {golDonatur.length === 0 ? <div>
                        <h1 className='font-light text-center my-10 text-xl'>Tidak Ada Golongan Donatur</h1>
                    </div> :
                        <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden z-0">
                            <table className="w-full text-sm text-left text-gray-500 ">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            No
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Golongan
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Nama Donatur
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Edit
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {golDonatur.map((d, id) => (
                                        <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                                            <td className='px-6 py-3'>{id + 1}</td>
                                            <td className='px-6 py-3'>{d.golongan}</td>
                                            <td className='px-6 py-3'>{d.donatur?.nama}</td>
                                            <td className='px-3 py-3'><HiOutlinePencilSquare className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                                setFormEditGol(!formEditGol)
                                                setData(d)
                                            }} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </Table>
        </div>
    )
}

export default ListGolDonatur