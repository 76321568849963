import api from "./http";

const ApiSpp = {
  add: (data) => {
    return api.post(`/cakepin/list_spp`, data);
  },
  getAll: () => {
    return api.get(`/cakepin/list_spp`);
  },
  getByTahunAjar: (thn) => {
    return api.get(`/`);
  },
  update: (uuid, data) => {
    return api.put(`/cakepin/list_spp/${uuid}`, data);
  },
  addTahun: (data) => {
    return api.post(`/tahun`, data);
  },
  generateTagihan: (data) => {
    return api.post(`/cakepin/tagihan`, data);
  },
  getTagihan: (data) => {
    return api.post(`/cakepin/tagihan/check`, data);
  },
  editTagihan: (uuid, data) => {
    return api.put(`/`, data);
  },
  getDataUp: (uuid) => {
    return api.get(`/cakepin/tagihan/donatur/up/${uuid}`);
  },
  putNormalisasi: (nuwb) => {
    return api.put(`/cakepin/tagihan/normalisasi/${nuwb}`);
  },
};
export default ApiSpp;
