import { createSlice } from "@reduxjs/toolkit";
import { addLembaga,destroyRuang,postRuang,updateLembaga, updateRuang } from "../actions/lembaga";

export const lembagaStore = createSlice({
    name:"lembaga",
    initialState : {
        loadingLembaga : false,
        msgLembaga : ""
    },
    extraReducers : builder =>{
        builder
        .addCase(addLembaga.pending,(state)=>{
            state.loadingLembaga = true
        })
        .addCase(addLembaga.fulfilled,(state,action)=>{
            state.loadingLembaga = false
            state.msgLembaga = action.payload.msg
        })
        .addCase(addLembaga.rejected,(state,action)=>{
            state.loadingLembaga = false
            state.msgLembaga = action.payload
        })
        .addCase(updateLembaga.pending,(state)=>{
            state.loadingLembaga = true
        })
        .addCase(updateLembaga.fulfilled,(state,action)=>{
            state.loadingLembaga= false
            state.msgLembaga = action.payload.msg
        })
        .addCase(updateLembaga.rejected,(state,action)=>{
            state.loadingLembaga = false
            state.msgLembaga = action.payload
        })
        .addCase(postRuang.pending,(state)=>{
            state.loadingLembaga = true
        })
        .addCase(postRuang.fulfilled,(state,action)=>{
            state.loadingLembaga= false
            state.msgLembaga = action.payload.msg
        })
        .addCase(postRuang.rejected,(state,action)=>{
            state.loadingLembaga = false
            state.msgLembaga = action.payload
        })
        .addCase(updateRuang.pending,(state)=>{
            state.loadingLembaga = true
        })
        .addCase(updateRuang.fulfilled,(state,action)=>{
            state.loadingLembaga= false
            state.msgLembaga = action.payload.msg
        })
        .addCase(updateRuang.rejected,(state,action)=>{
            state.loadingLembaga = false
            state.msgLembaga = action.payload
        })
        .addCase(destroyRuang.pending,(state)=>{
            state.loadingLembaga = true
        })
        .addCase(destroyRuang.fulfilled,(state,action)=>{
            state.loadingLembaga= false
            state.msgLembaga = action.payload.msg
        })
        .addCase(destroyRuang.rejected,(state,action)=>{
            state.loadingLembaga = false
            state.msgLembaga = action.payload
        })
    }
})
export default lembagaStore.reducer