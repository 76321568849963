import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import AddButton from './AddButton'
import Dialog from './Dialog'
import * as yup from "yup"
import Input from './Input'
import TextArea from './TextArea'
import Options from './Options'
import { useDispatch, useSelector } from 'react-redux'
import { getLembaga } from '../store/actions/optional'


function FormListSpp(props) {
    const dispatch = useDispatch()
    const { lembaga } = useSelector((state) => state.optional)
    const methodListSpp = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                uuid_santri : yup.string(),
                spp: yup.string().required("Tidak Boleh Kosong"),
                up: yup.string().required("Tidak Boleh Kosong"),
                gol: yup.string().required("Tidak Boleh Kosong"),
                tahun: yup.string().required("Tidak Boleh Kosong"),
                kode_lembaga: yup.string().required("Tidak Boleh Kosong"),
            })
        )
    })
    const lembagaOpt = lembaga.map((e) => {
        return { value: e.kode_lembaga, label: e.nama }
    })
    const tahunOpt = [
        { value: "2020", label: "2020" }
    ]
    useEffect(() => {
        dispatch(getLembaga())
    }, [])
    useEffect(()=>{
        if(props.lembaga !== ""){
            methodListSpp.setValue("kode_lembaga",props.lembaga)
        }
    },[props.lembaga])
    return (
        <div>
            <Dialog show={props.showForm} toggle={props.closeForm}>
                <div className='bg-white md:w-[40vw] w-[95vw]'>
                    <div className='py-4 px-6 border-b border-slate-200'>
                        <h1 className='text-xl'>Formulir Tambah List Spp</h1>
                    </div>
                    <form onSubmit={methodListSpp.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                        <Options label="Lembaga" method={methodListSpp} methodName="kode_lembaga" options={lembagaOpt} />
                        <Options label="Tahun Ajaran" method={methodListSpp} methodName="tahun" options={tahunOpt} />
                        <Input label="Spp" type="number" method={methodListSpp} methodName="spp" />
                        <Input label="Up" type="number" method={methodListSpp} methodName="up" />
                        <TextArea label="Keterangan" method={methodListSpp} methodName="gol" />
                        <AddButton />
                    </form>
                </div>
            </Dialog>
        </div>
    )
}

export default FormListSpp