import React from 'react'
import Select from 'react-select';

function OptionsField(props) {
    return (
        <div>
            <label className="md:mx-0 text-md font-light text-gray-600">{props.label}</label>
            <Select
                className={props.className}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: "0px",
                        paddingTop: "1px",
                        paddingBottom: "1px",
                        borderColor: state.isTouched ? "#624d4d" : "#624d4d",
                        borderWidth: "2px",
                    }),
                }}
                classNamePrefix="select"
                placeholder={props.placeholder}
                options={props.options}
                isMulti={props.isMulti}
                onBlur={props.onBlur}
                onChange={(e) => {
                    props.onChange(e);
                }
                }
                isDisabled={props.disabled}
                defaultValue={{value:"",label:""}}
                value={props.value}
                isSearchable={props.isSearchable}
            />
        </div>
    )
}

export default OptionsField