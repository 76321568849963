import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import HeaderUi from "../component/HeaderUi";
import Table from "../component/Table";
import * as yup from "yup";
import Input from "../component/Input";
import { useDispatch, useSelector } from "react-redux";
import { getAllUser, getAllUserBySistem } from "../store/actions/user";
import Options from "../component/Options";
import {
  emptyState,
  emptyStateLaporan,
  getAllLaporan,
} from "../store/actions/laporan";
import { currency } from "../component/Currency";
import Loader from "../component/Loader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { HiOutlineTrash } from "react-icons/hi2";
import FormDeleteLaporan from "../component/FormDeleteLaporan";

function Laporan() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allUser } = useSelector((state) => state.user);
  const { username, super_admin, role } = useSelector((state) => state.auth);
  const { allLaporan, loadingLaporan } = useSelector((state) => state.laporan);
  const [user, setUser] = useState([]);
  const methodLaporan = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        dari: yup.string().required(),
        sampai: yup.string().required(),
        uuid_user: yup.string(),
      })
    ),
  });
  const getLaporan = (e) => {
    dispatch(getAllLaporan(e));
  };
  useEffect(() => {
    if (Object.keys(allUser).length !== 0) {
      console.log(allUser)
      let usr = [];
      usr.splice(0, 0, { value: "-", label: "Semua" });
      allUser.map((d) => {
        if (d?.sistem?.find((e) => e?.nama_sistem === "cakepin")) {
          usr.push({ value: d.uuid, label: d.nama });
        }
      });
      setUser(usr)
    }
  }, [allUser]);
  useEffect(() => {
    if (role === "superadmin") {
      dispatch(getAllUser('cakepin'));
    }
    dispatch(emptyStateLaporan());
  }, []);
  let jml = 0;
  allLaporan.map((d) => {
    return (jml += parseInt(d.total));
  });
  return (
    <div>
      <Loader show={loadingLaporan} />
      <HeaderUi titleHeader="Laporan Pembayaran" />
      <Table classFirst="hidden">
        <div>
          <form
            onSubmit={methodLaporan.handleSubmit(getLaporan)}
            className="md:flex md:flex-row flex-col md:space-x-3"
          >
            <div className="grid md:grid-cols-3 grid-cols-2 gap-3 w-full">
              <Input
                label="Dari"
                type="date"
                method={methodLaporan}
                methodName="dari"
              />
              <Input
                label="Sampai"
                type="date"
                method={methodLaporan}
                methodName="sampai"
              />
              <div className={super_admin ? "block" : "hidden"}>
                <Options
                  label="Teller"
                  method={methodLaporan}
                  methodName="uuid_user"
                  options={user}
                />
              </div>
            </div>
            <button
              type="submit"
              className="border border-blue-500 bg-slate-100 px-8 py-[7px] mt-7 mb-3"
            >
              Proses
            </button>
          </form>
          <div className="space-x-3">
            <button
              type="button"
              className="border border-blue-500 bg-slate-100 px-2 py-[7px] w-56"
              onClick={() => navigate(`/home/laporan/lembaga`)}
            >
              Laporan Perlembaga
            </button>
            <button
              type="button"
              className="border border-blue-500 bg-slate-100 px-2 py-[7px] w-56"
              onClick={() => navigate(`/home/laporan/santri`)}
            >
              Laporan Persantri
            </button>
          </div>
          <div className="flex space-x-2 border-bya">
            <h1 className="text-lg font-light">Total Semua</h1>
            <h1 className="text-lg"> : </h1>
            <h1 className="text-lg text-blue-700">{currency(jml)}</h1>
          </div>
          {allLaporan.length === 0 ? (
            <div>
              <h1 className="font-light text-center my-10 text-xl">
                Tidak Ada Laporan
              </h1>
            </div>
          ) : (
            <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden z-0">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      No
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Metode
                    </th>
                    <th scope="col" className="px-6 py-3">
                      UP
                    </th>
                    <th scope="col" className="px-6 py-3">
                      SPP
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allLaporan.map((d, id) => {
                    return (
                      <tr
                        className="bg-white border-b hover:bg-gray-100"
                        key={id}
                      >
                        <td className="px-6 py-3">{id + 1}</td>
                        <td className="px-6 py-3">{d.metode_pembayaran}</td>
                        <td className="px-6 py-3">
                          {currency(parseInt(d.up))}
                        </td>
                        <td className="px-6 py-3">
                          {currency(parseInt(d.spp))}
                        </td>
                        <td className="px-6 py-3">
                          {currency(parseInt(d.total))}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Table>
    </div>
  );
}

export default Laporan;
