import { createSlice } from "@reduxjs/toolkit";
import { getPembayaranDon,getGolonganDon, postPembayaranDon } from "../actions/pembayaranDonatur";

export const pembayaranDonStore = createSlice({
    name:"pembayaranDonatur",
    initialState : {
        onePembayaran : {},
        golDonatur : [],
        msgPembayaranDon : "",
        loadingPembayaranDon : false 
    },
    extraReducers : builder =>{
        builder
        .addCase(getPembayaranDon.pending,(state)=>{
            state.loadingPembayaranDon = true
        })
        .addCase(getPembayaranDon.fulfilled,(state,action)=>{
            state.loadingPembayaranDon = false
            state.onePembayaran = action.payload
        })
        .addCase(getPembayaranDon.rejected,(state)=>{
            state.loadingPembayaranDon = false
        })
        .addCase(getGolonganDon.pending,(state)=>{
            state.loadingPembayaranDon = true
        })
        .addCase(getGolonganDon.fulfilled,(state,action)=>{
            state.loadingPembayaranDon = false
            state.golDonatur = action.payload
        })
        .addCase(getGolonganDon.rejected,(state)=>{
            state.loadingPembayaranDon = false
        })
        .addCase(postPembayaranDon.pending,(state)=>{
            state.loadingPembayaranDon = true
        })
        .addCase(postPembayaranDon.fulfilled,(state,action)=>{
            state.loadingPembayaranDon = false
            state.msgPembayaranDon = action.payload.msg
        })
        .addCase(postPembayaranDon.rejected,(state,action)=>{
            state.loadingPembayaranDon = false
            state.msgPembayaranDon = action.payload
        })
    }
})
export default pembayaranDonStore.reducer