import { createSlice } from "@reduxjs/toolkit";
import { getAllDonatur, postNewDonatur, deleteOneDonatur, editOneDonatur, addNewGolonganDonatur, updateOneGolonganDonatur, postGenerateDonatur, oneDonaturTtpByNuwb, postGenerateDonaturTdkTtp, oneDonaturTdkTtpByNuwb, hapusOneDonaturTdkTtp, hapusOneDonaturTtp, postDonaturUp } from "../actions/donatur";

export const donaturStore = createSlice({
    name: "donatur",
    initialState: {
        allDonatur: [],
        oneDonaturTtp: {},
        oneDonaturTdkTtp: {},
        msgDonatur: "",
        loadingDonatur: false
    },
    extraReducers: builder => {
        builder
            .addCase(getAllDonatur.pending, (state) => {
                state.loadingDonatur = true
            })
            .addCase(getAllDonatur.fulfilled, (state, action) => {
                state.loadingDonatur = false
                state.allDonatur = action.payload
            })
            .addCase(getAllDonatur.rejected, (state) => {
                state.loadingDonatur = false
            })
            .addCase(postNewDonatur.pending, (state) => {
                state.loadingDonatur = true
            })
            .addCase(postNewDonatur.fulfilled, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload.msg
            })
            .addCase(postNewDonatur.rejected, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload
            })
            .addCase(deleteOneDonatur.pending, (state) => {
                state.loadingDonatur = true
            })
            .addCase(deleteOneDonatur.fulfilled, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload.msg
            })
            .addCase(deleteOneDonatur.rejected, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload
            })
            .addCase(editOneDonatur.pending, (state) => {
                state.loadingDonatur = true
            })
            .addCase(editOneDonatur.fulfilled, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload.msg
            })
            .addCase(editOneDonatur.rejected, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload
            })
            .addCase(addNewGolonganDonatur.pending, (state) => {
                state.loadingDonatur = true
            })
            .addCase(addNewGolonganDonatur.fulfilled, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload.msg
            })
            .addCase(addNewGolonganDonatur.rejected, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload
            })
            .addCase(updateOneGolonganDonatur.pending, (state) => {
                state.loadingDonatur = true
            })
            .addCase(updateOneGolonganDonatur.fulfilled, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload.msg
            })
            .addCase(updateOneGolonganDonatur.rejected, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload
            })
            .addCase(postGenerateDonatur.pending, (state) => {
                state.loadingDonatur = true
            })
            .addCase(postGenerateDonatur.fulfilled, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload.msg
            })
            .addCase(postGenerateDonatur.rejected, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload
            })
            .addCase(oneDonaturTtpByNuwb.pending, (state) => {
                state.loadingDonatur = true
            })
            .addCase(oneDonaturTtpByNuwb.fulfilled, (state, action) => {
                state.loadingDonatur = false
                state.oneDonaturTtp = action.payload
            })
            .addCase(oneDonaturTtpByNuwb.rejected, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload
            })
            .addCase(postGenerateDonaturTdkTtp
                .pending, (state) => {
                    state.loadingDonatur = true
                })
            .addCase(postGenerateDonaturTdkTtp
                .fulfilled, (state, action) => {
                    state.loadingDonatur = false
                    state.msgDonatur = action.payload.msg
                })
            .addCase(postGenerateDonaturTdkTtp.rejected, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload
            })
            .addCase(oneDonaturTdkTtpByNuwb.pending, (state) => {
                state.loadingDonatur = true
            })
            .addCase(oneDonaturTdkTtpByNuwb.fulfilled, (state, action) => {
                state.loadingDonatur = false
                state.oneDonaturTdkTtp = action.payload
            })
            .addCase(oneDonaturTdkTtpByNuwb.rejected, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload
            })
            .addCase(hapusOneDonaturTdkTtp.pending, (state) => {
                state.loadingDonatur = true
            })
            .addCase(hapusOneDonaturTdkTtp.fulfilled, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload.msg
            })
            .addCase(hapusOneDonaturTdkTtp.rejected, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload
            })
            .addCase(hapusOneDonaturTtp.pending, (state) => {
                state.loadingDonatur = true
            })
            .addCase(hapusOneDonaturTtp.fulfilled, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload.msg
            })
            .addCase(hapusOneDonaturTtp.rejected, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload
            })
            .addCase(postDonaturUp.pending, (state) => {
                state.loadingDonatur = true
            })
            .addCase(postDonaturUp.fulfilled, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload.msg
            })
            .addCase(postDonaturUp.rejected, (state, action) => {
                state.loadingDonatur = false
                state.msgDonatur = action.payload
            })
    }
})
export default donaturStore.reducer