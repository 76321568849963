import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { HiOutlinePencilSquare } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import CheckBox from '../component/CheckBox'
import Dialog from '../component/Dialog'
import InputField from '../component/InputField'
import OptionsField from '../component/OptionsField'
import { getAllDonatur } from '../store/actions/donatur'
import { getThnAjar } from '../store/actions/optional'
import { getGolonganDon, getPembayaranDon, postPembayaranDon } from '../store/actions/pembayaranDonatur'
import * as yup from "yup"
import Options from '../component/Options'
import Loader from '../component/Loader'
import SnackBar from '../component/SnackBar'

function PembayaranDonatur() {
    const params = useParams()
    const dispatch = useDispatch()
    const [confirm, setConfirm] = useState(false)
    const [snack,setSnack] = useState(false)
    const { thn_ajar } = useSelector((state) => state.optional)
    const { allDonatur } = useSelector((state) => state.donatur)
    const { onePembayaran, golDonatur,msgPembayaranDon,loadingPembayaranDon } = useSelector((state) => state.pembayaranDon)
    const bulan = ["jan", "feb", "mar", "apr", "mei", "jun", "jul", "agus", "sept", "okt", "nov", "des"]
    const [readJumlahSpp, setReadJumlahSpp] = useState(true)
    const [selectedGol, setSelectedGol] = useState({})
    const [tahun, setTahun] = useState({})
    const [jumlahSpp, setJumlahSpp] = useState(0)
    const [antrian, setAntrian] = useState([])
    const [jul, setJul] = useState(false)
    const [aug, setAug] = useState(false)
    const [sep, setSep] = useState(false)
    const [okt, setOkt] = useState(false)
    const [nov, setNov] = useState(false)
    const [des, setDes] = useState(false)
    const [jan, setJan] = useState(false)
    const [feb, setFeb] = useState(false)
    const [mar, setMar] = useState(false)
    const [apr, setApr] = useState(false)
    const [mei, setMei] = useState(false)
    const [jun, setJun] = useState(false)
    const methodPembayaran = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                uuid_donatur: yup.string().required("Tidak Boleh Kosong"),
                tahun_ajar: yup.string().required("Tidak Boleh Kosong"),
                spp: yup.object().shape({
                    jul: yup.number(),
                    agus: yup.number(),
                    sept: yup.number(),
                    okt: yup.number(),
                    nov: yup.number(),
                    des: yup.number(),
                    jan: yup.number(),
                    feb: yup.number(),
                    mar: yup.number(),
                    apr: yup.number(),
                    mei: yup.number(),
                    jun: yup.number(),
                }),
                id_golongan_donatur : yup.string().required("Tidak Boleh Kosong"),
                metode_pembayaran: yup.string().required("Tidak Boleh Kosong")
            }),
        ),
        defaultValues: { spp: { jul: 0, agus: 0, sept: 0, okt: 0, nov: 0, des: 0, jan: 0, feb: 0, mar: 0, apr: 0, mei: 0, jun: 0 }}
    })
    const create = () => {
        setConfirm(!confirm)
        let jumlah = parseInt(jumlahSpp)
        antrian.map((e) => {
            if (jumlah >= e.jumlah) {
                methodPembayaran.setValue(`spp.${bulan[e.no - 1]}`, e.jumlah)
                jumlah -= e.jumlah
            }
            else if (jumlah < e.jumlah) {
                methodPembayaran.setValue(`spp.${bulan[e.no - 1]}`, jumlah)
                jumlah = 0
            }
        })
        methodPembayaran.setValue("id_golongan_donatur",selectedGol.value)
    }
    const add = (e) =>{
        dispatch(postPembayaranDon(e))
        setConfirm(!confirm)
        setSnack(!snack)
    }
    const detailDonatur = () => {
        methodPembayaran.setValue("metode_pembayaran","tunai")
        methodPembayaran.setValue("tahun_ajar",params.tahun.replace("-","/"))
        setSelectedGol({ value: params.golongan, label: golDonatur.find((e) => e.id === parseInt(params.golongan)).golongan })
    }
    const golOpt = golDonatur.map((d) => {
        return { value: d.id, label: d.golongan }
    })
    const thnOpt = thn_ajar.map((d) => {
        return { value: d.tahun, label: d.tahun }
    })
    const metodeOpt = [
        { value: "tunai", label: "Tunai" },
        { value: "cimb", label: "CIMB" },
        { value: "bsi", label: "BSI" },
        { value: "bntb", label: "BNTB" },
    ]
    useEffect(() => {
        dispatch(getThnAjar())
    }, [])
    const clickJul = () => {
        if (!aug) {
            setJul(!jul)
            if (jumlahSpp === 0) {
                if (!jul) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.jul)
                    antrian.push({ no: 7, jumlah: onePembayaran.spp.jul })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!jul) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.jul)
                    antrian.push({ no: 7, jumlah: onePembayaran.spp.jul })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - onePembayaran.spp.jul)
                    antrian.splice(-1, 1)
                }
            }
        }
    }
    const clickaug = () => {
        if ((onePembayaran.spp?.jul === 0 && !sep) || (jul && !sep)) {
            setAug(!aug)
            if (jumlahSpp === 0) {
                if (!aug) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.agus)
                    antrian.push({ no: 8, jumlah: onePembayaran.spp.agus })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!aug) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.agus)
                    antrian.push({ no: 8, jumlah: onePembayaran.spp.agus })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - onePembayaran.spp.agus)
                    antrian.splice(-1, 1)
                }
            }

        }
    }
    const clicksep = () => {
        if ((onePembayaran.spp?.agus === 0 && !okt) || (aug && !okt)) {
            setSep(!sep)
            if (jumlahSpp === 0) {
                if (!sep) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.sept)
                    antrian.push({ no: 9, jumlah: onePembayaran.spp.sept })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!sep) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.sept)
                    antrian.push({ no: 9, jumlah: onePembayaran.spp.sept })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - onePembayaran.spp.sept)
                    antrian.splice(-1, 1)
                }
            }
        }
    }
    const clickOkt = () => {
        if ((onePembayaran.spp?.sept === 0 && !nov) || (sep && !nov)) {
            setOkt(!okt)
            if (jumlahSpp === 0) {
                if (!okt) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.okt)
                    antrian.push({ no: 10, jumlah: onePembayaran.spp.okt })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)

                }
            }
            else {
                if (!okt) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.okt)
                    antrian.push({ no: 10, jumlah: onePembayaran.spp.okt })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - onePembayaran.spp.okt)
                    antrian.splice(-1, 1)
                }
            }
        }
        else if (!sep) {

        }

    }
    const clickNov = () => {
        if ((onePembayaran.spp?.okt === 0 && !des) || (okt && !des)) {
            setNov(!nov)
            if (jumlahSpp === 0) {
                if (!nov) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.nov)
                    antrian.push({ no: 11, jumlah: onePembayaran.spp.nov })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!nov) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.nov)
                    antrian.push({ no: 11, jumlah: onePembayaran.spp.nov })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - onePembayaran.spp.nov)
                    antrian.splice(-1, 1)
                }
            }
        }
    }
    const clickDes = () => {
        if ((onePembayaran.spp?.nov === 0 && !jan) || (nov && !jan)) {
            setDes(!des)
            if (jumlahSpp === 0) {
                if (!des) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.des)
                    antrian.push({ no: 12, jumlah: onePembayaran.spp.des })

                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!des) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.des)
                    antrian.push({ no: 12, jumlah: onePembayaran.spp.des })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - onePembayaran.spp.des)
                    antrian.splice(-1, 1)
                }
            }

        }
    }
    const clickJan = () => {
        if ((onePembayaran.spp?.des === 0 && !feb) || (des && !feb)) {
            setJan(!jan)
            if (jumlahSpp === 0) {
                if (!jan) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.jan)
                    antrian.push({ no: 1, jumlah: onePembayaran.spp.jan })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!jan) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.jan)
                    antrian.push({ no: 1, jumlah: onePembayaran.spp.jan })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - onePembayaran.spp.jan)
                    antrian.splice(-1, 1)
                }
            }
        }
    }
    const clickFeb = () => {
        if ((onePembayaran.spp?.jan === 0 && !mar) || (jan && !mar)) {
            setFeb(!feb)
            if (jumlahSpp === 0) {
                if (!feb) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.feb)
                    antrian.push({ no: 2, jumlah: onePembayaran.spp.feb })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!feb) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.feb)
                    antrian.push({ no: 2, jumlah: onePembayaran.spp.feb })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - onePembayaran.spp.feb)
                    antrian.splice(-1, 1)
                }
            }
        }
    }
    const clickMar = () => {
        if ((onePembayaran.spp?.feb === 0 && !apr) || (feb && !apr)) {
            setMar(!mar)
            if (jumlahSpp === 0) {
                if (!mar) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.mar)
                    antrian.push({ no: 3, jumlah: onePembayaran.spp.mar })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!mar) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.mar)
                    antrian.push({ no: 3, jumlah: onePembayaran.spp.mar })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - onePembayaran.spp.mar)
                    antrian.splice(-1, 1)
                }
            }
        }
    }
    const clickApr = () => {
        if ((onePembayaran.spp?.mar === 0 && !mei) || (mar && !mei)) {
            setApr(!apr)
            if (jumlahSpp === 0) {
                if (!apr) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.apr)
                    antrian.push({ no: 4, jumlah: onePembayaran.spp.apr })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!apr) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.apr)
                    antrian.push({ no: 4, jumlah: onePembayaran.spp.apr })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - onePembayaran.spp.apr)
                    antrian.splice(-1, 1)
                }
            }
        }
    }
    const clickMei = () => {
        if ((onePembayaran.spp?.apr === 0 && !jun) || (apr && !jun)) {
            setMei(!mei)
            if (jumlahSpp === 0) {
                if (!mei) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.mei)
                    antrian.push({ no: 5, jumlah: onePembayaran.spp.mei })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!mei) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.mei)
                    antrian.push({ no: 5, jumlah: onePembayaran.spp.mei })

                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - onePembayaran.spp.mei)
                    antrian.splice(-1, 1)
                }
            }
        }
    }
    const clickJun = () => {
        if (onePembayaran.spp?.mei === 0 || mei) {
            setJun(!jun)
            if (jumlahSpp === 0) {
                if (!jun) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.jun)
                    antrian.push({ no: 6, jumlah: onePembayaran.spp.jun })
                }
                else {
                    setJumlahSpp(0)
                    antrian.splice(-1, 1)
                }
            }
            else {
                if (!jun) {
                    setJumlahSpp(() => parseInt(jumlahSpp) + onePembayaran.spp.jun)
                    antrian.push({ no: 6, jumlah: onePembayaran.spp.jun })
                }
                else {
                    setJumlahSpp(() => parseInt(jumlahSpp) - onePembayaran.spp.jun)
                    antrian.splice(-1, 1)
                }
            }
        }
    }
    const changeJumlahSpp = (e) => {
        setJumlahSpp(e.target.value)
    }
    const cancel = () =>{
        setJumlahSpp(0)
        setJul(false)
        setAug(false)
        setSep(false)
        setOkt(false)
        setNov(false)
        setDes(false)
        setJan(false)
        setFeb(false)
        setMar(false)
        setApr(false)
        setMei(false)
        setJun(false)
    }
    useEffect(() => {
        if (Object.keys(params).length !== 0) {
            dispatch(getGolonganDon(params.donatur))
            if (selectedGol.value !== "" && selectedGol.value !== undefined) {
                dispatch(getPembayaranDon({ tahun_ajar: params.tahun.replace("-", "/"), uuid_donatur: params.donatur, id_golongan_donatur: selectedGol.value }))
                setTahun({ value: params.tahun.replace("-", "/"), label: params.tahun.replace("-", "/") })
            }
            methodPembayaran.setValue("uuid_donatur",params.donatur)
        }
        dispatch(getAllDonatur())
    }, [params, selectedGol,snack])
    return (
        <div >
            <Loader show={loadingPembayaranDon} />
            <SnackBar show={snack} toggle={setSnack} msg={msgPembayaranDon} />
            <Dialog show={confirm} toggle={setConfirm}>
                <div className='bg-white md:w-[40vw] w-[95vw]'>
                    <div className='py-4 px-6 border-b border-slate-200'>
                        <h1 className='text-xl'>Lakukan Pembayaran ?</h1>
                    </div>
                    <form onSubmit={methodPembayaran.handleSubmit(add)} className='px-6 py-7 space-y-1'>
                        <button className='w-full py-2 border border-blue-500 hover:border-blue-700 hover:bg-slate-50 transition-colors ease-in-out duration-200 font-light'>Iya</button>
                    </form>
                </div>
            </Dialog>
            <div className='md:flex m-7 flex-wrap'>
                <div className='bg-white md:w-[45%] shadow-lg'>
                    <h1 className='bg-sky-600 p-4 font-light text-white text-xl'>Info Donatur</h1>
                    {Object.keys(allDonatur).length !== 0 ?
                        <div className='m-4'>
                            <InputField label="Donatur" value={allDonatur.find((e) => e.uuid === params.donatur).nama} read={true} />
                            <OptionsField label="Golongan" options={golOpt} onChange={(e) => setSelectedGol(e)} value={selectedGol} />
                            <button className='border border-sky-500 px-4 py-1 my-2 hover:border-sky-700 hover:bg-blue-500 hover:text-white transition-all ease-in-out duration-300 rounded-sm' onClick={detailDonatur}>Tampilkan</button>
                            {/* <InputField label="Nama" value={santriByKey[0]?.nama === undefined || !show ? "" : santriByKey[0]?.nama} read={true} />
                            <InputField label="Lembaga" value={santriByKey[0]?.lembaga?.nama === undefined || !show ? "" : santriByKey[0]?.lembaga.nama} read={true} />
                            <InputField label="Kelas" value={santriByKey[0]?.kelas === undefined || !show ? "" : santriByKey[0]?.kelas} read={true} />
                            <InputField label="Ruang" value={santriByKey[0]?.ruang === undefined || !show ? "" : santriByKey[0]?.ruang} read={true} /> */}
                        </div>
                        :
                        <div>
                        </div>
                    }
                </div>
                <div className='bg-white shadow-lg md:mx-10 md:w-[45%]'>
                    <h1 className='bg-sky-600 p-4 font-light text-white text-xl'>Info Kewajiban</h1>
                    <div className='m-4'>
                        <div className='flex'>
                            <InputField type="number" label="Pembayaran SPP" value={parseInt(jumlahSpp)} onChange={changeJumlahSpp} read={readJumlahSpp} />
                            <button className=' ml-2'><HiOutlinePencilSquare className='w-6 h-6 -mb-6' onClick={() => setReadJumlahSpp(!readJumlahSpp)} /></button>
                        </div>
                    </div>
                    <form className='bg-white'>
                        <h1 className='bg-sky-600 p-4 font-light text-white text-xl'>Aksi</h1>
                        <div className='m-4 space-y-1'>
                            <OptionsField options={thnOpt} onChange={(e) => setTahun(e)} value={tahun} />
                            <Options method={methodPembayaran} methodName="metode_pembayaran" options={metodeOpt} />
                        </div>
                        <div className='m-2'>
                            <button type='button' onClick={create} className=' bg-blue-600 hover:bg-blue-700 px-10 py-2 mx-2 text-white'>Simpan</button>
                            <button type='button' className=' bg-red-600 hover:bg-red-700 px-10 py-2 text-white' onClick={cancel}>Batal</button>
                        </div>
                    </form>

                </div>
                <div className=' bg-white shadow-lg my-7 md:w-[30%] h-full'>
                    <h1 className='bg-sky-600 p-4 font-light text-white text-xl'>Panel Pembayaran</h1>
                    {Object.keys(onePembayaran).length !== 0 ?
                        <div className='mx-4 mt-4 flex '>
                            <div className='grid grid-cols-4 w-[90%] m-2'>
                                <CheckBox label="jul" onChange={clickJul} checked={onePembayaran?.spp?.jul !== 0 ? jul : true} />
                                <CheckBox label="aug" onChange={clickaug} checked={onePembayaran?.spp?.agus !== 0 ? aug : true} />
                                <CheckBox label="sep" onChange={clicksep} checked={onePembayaran?.spp?.sept !== 0 ? sep : true} />
                                <CheckBox label="okt" onChange={clickOkt} checked={onePembayaran?.spp?.okt !== 0 ? okt : true} />
                                <CheckBox label="nov" onChange={clickNov} checked={onePembayaran?.spp?.nov !== 0 ? nov : true} />
                                <CheckBox label="des" onChange={clickDes} checked={onePembayaran?.spp?.des !== 0 ? des : true} />
                                <CheckBox label="jan" onChange={clickJan} checked={onePembayaran?.spp?.jan !== 0 ? jan : true} />
                                <CheckBox label="feb" onChange={clickFeb} checked={onePembayaran?.spp?.feb !== 0 ? feb : true} />
                                <CheckBox label="mar" onChange={clickMar} checked={onePembayaran?.spp?.mar !== 0 ? mar : true} />
                                <CheckBox label="apr" onChange={clickApr} checked={onePembayaran?.spp?.apr !== 0 ? apr : true} />
                                <CheckBox label="mei" onChange={clickMei} checked={onePembayaran?.spp?.mei !== 0 ? mei : true} />
                                <CheckBox label="jun" onChange={clickJun} checked={onePembayaran?.spp?.jun !== 0 ? jun : true} />
                            </div>
                        </div>
                        :
                        <div className='mx-4 mt-4 flex '>
                            <div className='grid grid-cols-4 w-[90%] m-2'>
                                <CheckBox label="jul" checked={false} read={true} />
                                <CheckBox label="aug" checked={false} read={true} />
                                <CheckBox label="sep" checked={false} read={true} />
                                <CheckBox label="okt" checked={false} read={true} />
                                <CheckBox label="nov" checked={false} read={true} />
                                <CheckBox label="des" checked={false} read={true} />
                                <CheckBox label="jan" checked={false} read={true} />
                                <CheckBox label="feb" checked={false} read={true} />
                                <CheckBox label="mar" checked={false} read={true} />
                                <CheckBox label="apr" checked={false} read={true} />
                                <CheckBox label="mei" checked={false} read={true} />
                                <CheckBox label="jun" checked={false} read={true} />
                            </div>
                        </div>
                    }
                    <h1 className={`px-5 py-2 text-sm ${Object.keys(onePembayaran).length === 0 ? "text-red-700" : "text-blue-700"}`}>{Object.keys(onePembayaran).length === 0 ? "Tagihan Tidak Tersedia / Tunggakan Tahun Sebelumnya Belum Lunas " : ""}</h1>
                </div>
                <div className='shadow-lg md:ml-7 my-8 md:w-[65%]'>
                    <h1 className='bg-sky-600 p-4 font-light text-white text-xl'>History Pembayaran</h1>
                    <div className='bg-white p-2'>
                        {/* {onePembayaran?.pembayaran?.length === 0 ? <div>
                            <h1 className='font-light text-center my-10 text-xl'>Tidak Ada History</h1>
                        </div> :
                            <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden">
                                <table className="w-full text-sm text-left text-gray-500 ">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                Tgl
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Ket
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Jumlah
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {onePembayaran?.pembayaran?.map((d, id) => (
                                            <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                                                <td className='px-6 py-3'>{moment(d.createdAt).format("DD-MM-YYYY hh:mm")}</td>
                                                <td className='px-6 py-3'>{d.ket}</td>
                                                <td className='px-6 py-3'>{currency(d.jumlah)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        } */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PembayaranDonatur