import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiBersaudara from "../../api/bersaudara";

export const getAllBersaudara = createAsyncThunk(
  "bersaudara/all",
  async (nuwb, { rejectWithValue }) => {
    try {
      const res = await ApiBersaudara.get(nuwb);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.msg);
    }
  }
);
export const postPotonganBersaudara = createAsyncThunk(
  "bersaudara/potongan",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiBersaudara.post(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data.msg);
    }
  }
);
export const editNoKkBersaudara = createAsyncThunk(
  "bersaudara/edit/kk",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiBersaudara.editKk(data);
      if (res.status === 200) {
        return res.data;
      } else if (res.status === 201) {
        window.location.href = `/home/panel/bersaudara/${res.data?.msg}`;
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.msg);
    }
  }
);
