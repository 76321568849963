import { createSlice } from "@reduxjs/toolkit";
import { deleteLaporanPermanen, emptyStateLaporan, getAllLaporan, getAllLaporanDonatur, getAllLaporanLem, getAllLaporanSantri, getAllLaporanTerhapus } from "../actions/laporan";

export const laporanStore = createSlice({
    name:"laporan",
    initialState: {
        allLaporan : [],
        allLaporanDonatur : [],
        allLaporanLembaga : [],
        allLaporanSantri : [],
        allLaporanSantriTerhapus : [],
        allLaporanTerhapus : [],
        msgLaporan : "",
        loadingLaporan : false
    },
    extraReducers : builder =>{
        builder
        .addCase(getAllLaporan.pending,(state)=>{
            state.loadingLaporan = true
        })
        .addCase(getAllLaporan.fulfilled,(state,action)=>{
            state.loadingLaporan = false
            state.allLaporan = action.payload
        })
        .addCase(getAllLaporan.rejected,(state)=>{
            state.loadingLaporan = false
            state.allLaporan = []
        })
        .addCase(getAllLaporanDonatur.pending,(state)=>{
            state.loadingLaporan = true
        })
        .addCase(getAllLaporanDonatur.fulfilled,(state,action)=>{
            state.loadingLaporan = false
            state.allLaporanDonatur = action.payload
        })
        .addCase(getAllLaporanDonatur.rejected,(state)=>{
            state.loadingLaporan = false
            state.allLaporanDonatur = []
        })
        .addCase(getAllLaporanLem.pending,(state)=>{
            state.loadingLaporan = true
        })
        .addCase(getAllLaporanLem.fulfilled,(state,action)=>{
            state.loadingLaporan = false
            state.allLaporanLembaga = action.payload
        })
        .addCase(getAllLaporanLem.rejected,(state)=>{
            state.loadingLaporan = false
            state.allLaporanLembaga = []
        })
        .addCase(getAllLaporanSantri.pending,(state)=>{
            state.loadingLaporan = true
        })
        .addCase(getAllLaporanSantri.fulfilled,(state,action)=>{
            state.loadingLaporan = false
            state.allLaporanSantri = action.payload
        })
        .addCase(getAllLaporanSantri.rejected,(state)=>{
            state.loadingLaporan = false
            state.allLaporan = []
        })
        .addCase(emptyStateLaporan.pending,(state)=>{
            state.loadingLaporan = true
        })
        .addCase(emptyStateLaporan.fulfilled,(state,action)=>{
            state.loadingLaporan = false
            state.allLaporanSantri = []
            state.allLaporan = []
            state.allLaporanDonatur = []
        })
        .addCase(emptyStateLaporan.rejected,(state)=>{
            state.loadingLaporan = false
            state.allLaporanSantri = []
            state.allLaporan = []
            state.allLaporanDonatur = []
            
        })
        .addCase(deleteLaporanPermanen.pending,(state)=>{
            state.loadingLaporan = true
        })
        .addCase(deleteLaporanPermanen.fulfilled,(state,action)=>{
            state.loadingLaporan = false
            state.msgLaporan = action.payload.msg
        })
        .addCase(deleteLaporanPermanen.rejected,(state,action)=>{
            state.loadingLaporan = false
            state.msgLaporan = action.payload
        })
        .addCase(getAllLaporanTerhapus.pending,(state)=>{
            state.loadingLaporan = true
        })
        .addCase(getAllLaporanTerhapus.fulfilled,(state,action)=>{
            state.loadingLaporan = false
            state.allLaporanTerhapus= action.payload
        })
        .addCase(getAllLaporanTerhapus.rejected,(state,action)=>{
            state.loadingLaporan = false
        })
    }
})
export default laporanStore.reducer