import { createSlice } from "@reduxjs/toolkit";
import { addGambar, addGambarToDb, allGambar, updateStatusGambar } from "../actions/gambar";

export const gambarStore = createSlice({
    name:"gambar",
    initialState : {
        gambarAll : [],
        succes : {},
        msgGambar : "",
        loadingGambar : false
    },
    reducers:{
        clearUuid : (state)=>{
            state.succes = {}
        }
    },
    extraReducers : builder =>{
        builder
        .addCase(allGambar.pending,(state)=>{
            state.loadingGambar = true
        })
        .addCase(allGambar.fulfilled,(state,action)=>{
            state.loadingGambar = false
            state.gambarAll = action.payload
        })
        .addCase(allGambar.rejected,(state,action)=>{
            state.loadingGambar = false
            state.gambarAll = []
            state.msgGambar = action.payload
        })
        .addCase(addGambar.pending,(state)=>{
            state.loadingGambar = true
        })
        .addCase(addGambar.fulfilled,(state,action)=>{
            state.loadingGambar = false
            state.msgGambar = action.payload.msg
            state.succes = action.payload
        })
        .addCase(addGambar.rejected,(state,action)=>{
            state.loadingGambar = false
            state.msgGambar = action.payload
        })
        .addCase(addGambarToDb.pending,(state)=>{
            state.loadingGambar = true
        })
        .addCase(addGambarToDb.fulfilled,(state,action)=>{
            state.loadingGambar = false
            state.msgGambar = action.payload.msg
        })
        .addCase(addGambarToDb.rejected,(state,action)=>{
            state.loadingGambar = false
            state.msgGambar = action.payload
        })
        .addCase(updateStatusGambar.pending,(state)=>{
            state.loadingGambar = true
        })
        .addCase(updateStatusGambar.fulfilled,(state,action)=>{
            state.loadingGambar = false
            state.msgGambar = action.payload.msg
        })
        .addCase(updateStatusGambar.rejected,(state,action)=>{
            state.loadingGambar = false
            state.msgGambar = action.payload
        })
    }
})
export const gambarActions = gambarStore.actions
export default gambarStore.reducer