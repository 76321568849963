import { createSlice } from "@reduxjs/toolkit";
import { getAllRekapSpp } from "../actions/dashboard";

export const DashboardStore = createSlice({
  name: "dashboard",
  initialState: {
    allRekapSpp: [],
    loadingDashboard: false,
    msgDashboard: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRekapSpp.pending, (state) => {
        state.loadingDashboard = true;
      })
      .addCase(getAllRekapSpp.fulfilled, (state, action) => {
        state.loadingDashboard = false;
        state.allRekapSpp = action.payload;
      })
      .addCase(getAllRekapSpp.rejected, (state, action) => {
        state.loadingDashboard = false;
        state.allRekapSpp = [];
      });
  },
});

export default DashboardStore.reducer;
