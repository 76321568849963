import React, { useEffect } from 'react'
import AddButton from './AddButton'
import Dialog from './Dialog'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Input from './Input'

function FormDeleteLaporan(props) {
    const methodDeleteLaporan = useForm({
        mode:"all",
        resolver : yupResolver(
            yup.object().shape({
                uuid : yup.string().required(),
                catatan : yup.string().required("Tidak Boleh Kosong")
            })
        )
    })
    useEffect(()=>{
        if(props.uuid !== "" && props.uuid !== undefined){
            methodDeleteLaporan.reset({uuid:props.uuid,catatan:""})
        }
    },[props.uuid,props.showForm])
    return (
        <Dialog show={props.showForm} toggle={props.closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Delete History Pembayaran</h1>
                </div>
                <form onSubmit={methodDeleteLaporan.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                    <Input label="Catatan" method={methodDeleteLaporan} methodName="catatan" />
                    <AddButton />
                </form>
            </div>
        </Dialog>
    )
}

export default FormDeleteLaporan