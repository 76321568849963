import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { cetakBukti } from '../store/actions/pembayaran'
import { searchSantri } from '../store/actions/santri'
import ReactToPrint from 'react-to-print'
import moment from 'moment'
import { currency } from '../component/Currency'
import Loader from '../component/Loader'

function CetakBukti() {
    let componentRef = useRef();
    const dispatch = useDispatch()
    const param = useParams()
    const { username, nama } = useSelector((state) => state.auth)
    const { loadingPembayaran, cetak } = useSelector((state) => state.pembayaran)
    const { santriByKey } = useSelector((state) => state.santri)
    const [show, setShow] = useState(false)
    const now = new Date()
    const bulan = ["up", "jul", "agus", "sept", "okt", "nov", "des", "jan", "feb", "mar", "apr", "mei", "jun"]
    useEffect(() => {
        dispatch(cetakBukti({ nuwb: param.nuwb, tahun_ajar: param.tahun.replace("-", "/") }))
        setShow(true)
    }, [show, param])
    useEffect(() => {
        setShow(false)
    }, [])
    let jml_ = 0
    const [tampilan] = useState([])
    // useEffect(()=>{
    if (Object.keys(cetak).length !== 0) {
        const urut = [...cetak.santri.pembayaran]?.sort((a, b) => {
            return bulan.indexOf(a.ket) - bulan.indexOf(b.ket)
        })
        let total = 0
        let up = 0
        let sem1 = 0
        let sem2 = 0
        let sementara = 0
        tampilan.length = 0
        urut.map((d, id) => {
            if (d.ket === "up") {
                if (d.jumlah === 0) {
                    tampilan.length = 0
                }
                else {
                    tampilan.length = 0
                    up += d.jumlah
                    tampilan.push({ createdAt: d.createdAt, jumlah: up, ket: "up" })
                }
            }
            else if (d.ket === "des") {
                sem1 += d.jumlah
                if (sem1 === urut.find((e) => e.ket === "nov")?.jumlah) {
                    if (tampilan[0].ket === "up") {
                        tampilan.length = 1
                        total += d.jumlah
                        tampilan.push({ createdAt: d.createdAt, jumlah: total, ket: "spp smt 1 lunas" })
                    }
                    else {
                        tampilan.length = 0
                        total += d.jumlah
                        tampilan.push({ createdAt: d.createdAt, jumlah: total, ket: "spp smt 1 lunas" })
                    }
                }
                else {
                    if (d.ket !== urut[id - 1]?.ket) {
                        tampilan.push(d)
                        sementara = 0
                    }
                    else {
                        if (d.ket !== urut[id - 1]?.ket) {
                            tampilan.push(d)
                            sementara = d.jumlah
                        }
                        else {
                            tampilan.pop()
                            sementara += d.jumlah
                            tampilan.push({ createdAt: d.createdAt, ket: d.ket, jumlah: sementara })
                        }
                    }
                    total += d.jumlah
                }
            }
            else if (d.ket === "jun") {
                sem2 += d.jumlah
                if (sem2 === urut.find((e) => e.ket === "mei")?.jumlah) {
                    if (tampilan[0].ket === "up") {
                        tampilan.length = 1
                        total += d.jumlah
                        tampilan.push({ createdAt: d.createdAt, jumlah: total, ket: "spp smt 1&2 lunas" })
                    }
                    else {
                        tampilan.length = 0
                        total += d.jumlah
                        tampilan.push({ createdAt: d.createdAt, jumlah: total, ket: "spp smt 1&2 lunas" })
                    }
                }
                else {
                    if (d.ket !== urut[id - 1]?.ket) {
                        tampilan.push(d)
                        sementara = d.jumlah
                    }
                    else {
                        tampilan.pop()
                        sementara += d.jumlah
                        tampilan.push({ createdAt: d.createdAt, ket: d.ket, jumlah: sementara })
                        sem2 = sementara
                    }
                }
                total += d.jumlah
            }
            else {
                if (d.ket !== urut[id - 1]?.ket) {
                    tampilan.push(d)
                    sementara = d.jumlah
                }
                else {
                    tampilan.pop()
                    sementara += d.jumlah
                    tampilan.push({ createdAt: d.createdAt, ket: d.ket, jumlah: sementara })
                }
                total += d.jumlah
            }
            if (moment(d.createdAt).format("D-M-YYYY") === `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}`) {
                jml_ += d.jumlah
            }
        })
    }
    const time = new Date()
    return (
        <div>
            <ReactToPrint
                trigger={() => <button className='border border-blue-600 px-8 py-2 m-2 hover:bg-white transition-colors ease-in-out duration-300'>Print</button>}
                content={() => componentRef}
            />
            {/* <button className='border border-blue-600 px-8 py-2 m-2 hover:bg-white transition-colors ease-in-out duration-300' onClick={showBukti}>Tampilkan Bukti</button> */}
            <Loader show={loadingPembayaran} />
            {Object.keys(cetak).length !== 0 && tampilan.length !== 0 ?
                <div ref={(el) => (componentRef = el)} className="w-[10.16cm] h-[20.32cm] bg-white mx-auto p-3 relative">
                    <div>
                        <h1 className='text-center text-lg mt-2'>PONPES ABU HURAIRAH MATARAM</h1>
                        <h1 className='text-center text-md '>KEBENDAHARAAN</h1>
                    </div>
                    <div className='w-full h-[1px] bg-black mt-5'></div>
                    <h1 className='text-center my-3'>{cetak?.santri?.lembaga?.nama}</h1>
                    <div className='flex flex-wrap justify-between'>
                        <h1 className='w-[5cm] text-[12px]'>NUWB : {param.nuwb}</h1>
                        <h1 className='text-[12px] w-[2.5cm]'>KELAS : {cetak?.santri?.kelas}</h1>
                        <div className='w-[6cm] flex space-x-1'>
                            <h1 className=' text-[12px] flex justify-center items-center' >Nama  </h1>
                            <h1 className='text-[12px] flex justify-center items-center'>:</h1>
                            <h1 className={cetak?.santri?.nama.length < 25 ? "text-[12px] mt-[1px]" : "text-[10.5px] mt-[5px]"}>{cetak?.santri?.nama}</h1>
                        </div>
                        <h1 className='text-[12px] w-[2.5cm]'>RUANG : {cetak?.santri?.ruang}</h1>
                        <h1 className='text-[12px]'>Thn ajar : {param.tahun.replace("-", "/")}</h1>
                    </div>
                    <div className=" sm:rounded-lg my-3 overflow-y-hidden">
                        <table className="w-full text-sm text-left text-gray-500 ">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                <tr>
                                    <th scope="col" className="pl-3 py-[4px]">
                                        Tgl
                                    </th>
                                    <th scope="col" className="px-6 py-[4px]">
                                        Ket
                                    </th>
                                    <th scope="col" className="px-6 py-[4px]">
                                        Jumlah
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {tampilan.map((d, id) => {
                                    // if (moment(d.createdAt).format("dd") === moment(now).format("dd")) {
                                    //     jml_ += d.jumlah
                                    // }
                                    return <tr className="bg-white border-b hover:bg-gray-100 text-xs" key={id}>
                                        <td className='pl-3 py-[6px] '>{moment(d.createdAt).format("DD-MM-YYYY")}</td>
                                        <td className='px-6 py-[6px] '>{d.ket}</td>
                                        <td className='px-6 py-[6px] '>{currency(d.jumlah)}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='flex justify-between'>
                        <div className='text-sm'>
                            <h1>Spp : {currency(cetak.spp)}</h1>
                            <h1>Up : {currency(cetak.up)}</h1>
                        </div>
                        <div>
                            <h1 className='text-sm text-right'>Total Bayar Hari Ini : </h1>
                            <h1 className='text-sm text-right font-bold'>{currency(jml_)}</h1>
                        </div>
                    </div>
                    <div className='mt-5'>
                        <h1 className='text-sm'>{moment(time).format("DD-MM-YYYY hh:mm")}</h1>
                        <h1 className='font-semibold text-sm'>Teller</h1>
                        <h1 className='mt-14 text-sm'>{nama}</h1>
                    </div>
                    <footer className='mx-14 absolute bottom-5'>
                        <h1 className='text-center text-sm'>Simpan sebagai bukti bayar selanjutnya.</h1>
                    </footer>
                </div>
                :
                <div ref={(el) => (componentRef = el)} className="w-[10.16cm] h-[20.32cm] bg-white mx-auto p-3 relative">
                    <div>
                        <h1 className='text-center text-lg mt-2'>PONPES ABU HURAIRAH MATARAM</h1>
                        <h1 className='text-center text-md '>KEBENDAHARAAN</h1>
                    </div>
                    <div className='w-full h-[1px] bg-black mt-5'></div>
                    <h1 className='text-center my-3'>{cetak?.santri?.lembaga?.nama}</h1>
                    <div className='flex flex-wrap justify-between'>
                        <h1 className='w-[5cm] text-[12px]'>NUWB : {param.nuwb}</h1>
                        <h1 className='text-[12px] w-[2.5cm]'>KELAS : {cetak?.santri?.kelas}</h1>
                        <div className='w-[6cm] flex space-x-1'>
                            <h1 className=' text-[12px] flex justify-center items-center' >Nama  </h1>
                            <h1 className='text-[12px] flex justify-center items-center'>:</h1>
                            <h1 className={cetak?.santri?.nama.length < 25 ? "text-[12px] mt-[1px]" : "text-[10.5px] mt-[11px]"}>{cetak?.santri?.nama}</h1>
                        </div>
                        <h1 className='text-[12px] w-[2.5cm]'>RUANG : {cetak?.santri?.ruang}</h1>
                        <h1 className='text-[12px]'>Thn ajar : {param.tahun.replace("-", "/")}</h1>
                    </div>
                    <div className=" sm:rounded-lg my-3 overflow-y-hidden">
                        <table className="w-full text-sm text-left text-gray-500 ">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                <tr>
                                    <th scope="col" className="pl-3 py-[4px]">
                                        Tgl
                                    </th>
                                    <th scope="col" className="px-6 py-[4px]">
                                        Ket
                                    </th>
                                    <th scope="col" className="px-6 py-[4px]">
                                        Jumlah
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>
                    <div className='flex justify-between'>
                        <div className='text-sm'>
                            <h1>Spp : {cetak?.spp !== undefined && currency(cetak.spp)}</h1>
                            <h1>Up : {cetak?.up !== undefined && currency(cetak.up)}</h1>
                        </div>
                        <div>
                            <h1 className='text-sm text-right'>Total Bayar Hari Ini : </h1>
                            <h1 className='text-sm text-right font-bold'>{currency(jml_)}</h1>
                        </div>
                    </div>
                    <div className='mt-5'>
                        <h1 className='text-sm'>{moment(time).format("DD-MM-YYYY hh:mm")}</h1>
                        <h1 className='font-semibold text-sm'>Teller</h1>
                        <h1 className='mt-14 text-sm'>{nama}</h1>
                    </div>
                    <footer className='mx-14 absolute bottom-5'>
                        <h1 className='text-center text-sm'>Simpan sebagai bukti bayar selanjutnya.</h1>
                    </footer>
                </div>}
        </div>
    )
}

export default CetakBukti