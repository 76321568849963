import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiSantri from "../../api/santri";

export const addSantri = createAsyncThunk(
  "santri/add",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiSantri.postSantri(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.msg);
    }
  }
);
export const allSantri = createAsyncThunk(
  "santri/all",
  async (_, { rejectWithValue }) => {
    try {
      const res = await ApiSantri.getAllSantri();
      if (res.status === 200) {
        return res.data;
      }
    } catch (_) {
      return rejectWithValue("error");
    }
  }
);
export const oneSantri = createAsyncThunk(
  "santri/one",
  async (uuid, { rejectWithValue }) => {
    try {
      const res = await ApiSantri.getOneSantri(uuid);
      if (res.status === 200) {
        return res.data;
      }
    } catch (_) {
      return rejectWithValue("error");
    }
  }
);
export const searchSantri = createAsyncThunk(
  "santri/search",
  async (key, { rejectWithValue }) => {
    try {
      const res = await ApiSantri.getSearchSantri(key);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.msg);
    }
  }
);
export const updateSantri = createAsyncThunk(
  "santri/update",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiSantri.putSantri(data.uuid, data.update);
      if (res.status === 200) {
        return res.data;
      }else if(res.status === 201){
        window.location.href = `/home/panel/bersaudara/${res.data?.msg}`;
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.msg);
    }
  }
);
export const destroySantri = createAsyncThunk(
  "santri/destroy",
  async (uuid, { rejectWithValue }) => {
    try {
      const res = await ApiSantri.deleteSantri(uuid);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.msg);
    }
  }
);
export const addUserMobile = createAsyncThunk(
  "santri/mobile/user",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiSantri.createMobilUser(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.msg);
    }
  }
);
export const addMutasiSantri = createAsyncThunk(
  "santri/mutasi",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiSantri.createMutasi(data.uuid, data.ket);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.msg);
    }
  }
);
export const allReportSantri = createAsyncThunk(
  "santri/all/report",
  async (_, { rejectWithValue }) => {
    try {
      const res = await ApiSantri.reportSantri();
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.msg);
    }
  }
);
export const findAyah = createAsyncThunk(
  "santri/ayah/find",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiSantri.getOrtu(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.msg);
    }
  }
);
export const findIbu = createAsyncThunk(
  "santri/ibu/find",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiSantri.getOrtu(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data.msg);
    }
  }
);
