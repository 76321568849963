import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import AddButton from './AddButton'
import * as yup from "yup"
import Options from './Options'
import { bulanOpt, bulanTagihanOpt } from './OptSelect'
import Dialog from './Dialog'
import Input from './Input'
import { useDispatch, useSelector } from 'react-redux'
import { getGolDonatur, getGolongan, getThnAjar } from '../store/actions/optional'
import OptionsField from './OptionsField'
import InputField from './InputField'
import { getAllDonatur } from '../store/actions/donatur'
import RadioField from './RadioField'
import { useParams } from 'react-router-dom'
import { getOneTagihan } from '../store/actions/spp'
import Loader from './Loader'
import { searchSantri } from '../store/actions/santri'

function FormGenerateTagihan(props) {
    const param = useParams()
    const dispatch = useDispatch()
    const { lembaga, thn_ajar, golongan, golDonatur } = useSelector((state) => state.optional)
    const { allDonatur } = useSelector((state) => state.donatur)
    const { oneTagihan, loadingSpp } = useSelector((state) => state.spp)
    const [lem, setLem] = useState({})
    const [gol, setGol] = useState({})
    const [dariBul, setDariBul] = useState({})
    const [sampaiBul, setSampaiBul] = useState({})
    const [spp, setSpp] = useState("")
    const [up, setUp] = useState("")
    const [change, setChange] = useState("")
    const [donatur, setDonatur] = useState("")
    const [disableTahun, setDisableTahun] = useState(false)
    const methodGenerateTagihan = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                uuid: yup.string().required("Tidak Boleh Kosong"),
                uuid_tagihan: yup.string().required("Tidak Boleh Kosong"),
                angkatan: yup.string().required("Tidak Boleh Kosong"),
                tahun_ajar: yup.string().required("Tidak Boleh Kosong"),
                jumlah_tagihan: yup.string().required("Tidak Boleh Kosong"),
            })
        ),
        defaultValues: { uuid_donatur: null, golongan_donatur: null }
    })
    const lembagaOpt = lembaga.map((d) => {
        return { value: d.kode_lembaga, label: d.nama }
    })
    const thn_ajaranOpt = thn_ajar.map((d) => {
        return { value: d.tahun, label: d.tahun }
    })
    const golOpt = golongan.map((d) => {
        return { value: d.ket, label: d.ket }
    })
    const golDonaturOpt = golDonatur.map((d) => {
        return { value: d.id, label: d.golongan }
    })
    const watchThn = methodGenerateTagihan.watch("angkatan")
    useEffect(() => {
        dispatch(getThnAjar())
        dispatch(searchSantri(param.nuwb))
    }, [])
    useEffect(() => {
        if (param.nuwb !== "" && props.showForm) {
            dispatch(getOneTagihan({ nuwb: param.nuwb }))
        }
    }, [param.nuwb, props.showForm])
    useEffect(() => {
        if (Object.keys(oneTagihan).length !== 0) {
            if (oneTagihan.created) {
                setGol(golOpt.find((e) => e.label === oneTagihan.santri.gol))
                setDariBul(bulanTagihanOpt.find((e) => e.value === oneTagihan.santri.dari))
                setSampaiBul(bulanTagihanOpt[11])
            }
            else {
                setGol({})
                setDariBul({})
                setUp(0)
                setSpp(0)
                setSampaiBul(bulanTagihanOpt[11])
            }
        }
    }, [oneTagihan, golongan,props.showForm])
    useEffect(() => {
        if (Object.keys(oneTagihan).length !== 0) {
            if (oneTagihan.created) {
                if(oneTagihan?.santri?.tahun_masuk !== null){
                    setDisableTahun(true)
                    methodGenerateTagihan.setValue("angkatan", oneTagihan?.santri?.tahun_masuk)
                }
                else{
                    methodGenerateTagihan.setValue("angkatan", "")
                    setDisableTahun(false)
                }
            }
            else {
                methodGenerateTagihan.setValue("angkatan", "")
                setDisableTahun(false)

            }
        }
    }, [oneTagihan,props.showForm])
    useEffect(() => {
        if (watchThn !== "" && lem.value !== "") {
            let chnge = watchThn?.replace("/", "-")
            dispatch(getGolongan({ thn: chnge, kode: lem.value }))
        }
    }, [watchThn])
    useEffect(() => {
        if (gol?.value !== "" && Object.keys(golongan).length !== 0) {
            golongan.map((d) => {
                if (d.ket === gol?.value) {
                    methodGenerateTagihan.setValue("uuid_tagihan", d.uuid)
                    setSpp(d.spp)
                    setUp(d.up)
                    setSampaiBul(bulanTagihanOpt[11])
                }
            })
        }
    }, [gol,props.showForm])
    useEffect(() => {
        if (dariBul?.value !== "" && sampaiBul?.value !== "") {
            let total = sampaiBul?.value - dariBul?.value + 1
            methodGenerateTagihan.setValue("jumlah_tagihan", total)
        }
        else if(Object.keys(oneTagihan).length !== 0){
            let total = sampaiBul?.value - oneTagihan?.santri?.dari + 1
            methodGenerateTagihan.setValue("jumlah_tagihan", total)
        }
    }, [dariBul, sampaiBul,props.showForm,oneTagihan])
    useEffect(() => {
        if (Object.keys(props.data).length !== 0 && props.uuid_santri !== "") {
            setLem({ value: props.data.kode_lembaga, label: props.data.lembaga?.nama })
            methodGenerateTagihan.reset({ uuid: props.uuid_santri })
            // if (props.data?.status_santri?.tahun_masuk === null) {
            //     setDisableTahun(false)
            // }
            // else {
            //     setDisableTahun(true)
            // }
        }
    }, [props.data, props.uuid_santri, props.showForm])
    return (
        <div>
            <Loader show={loadingSpp} />
            <Dialog show={props.showForm} toggle={props.closeForm}>
                <div className='bg-white md:w-[40vw] w-[95vw]'>
                    <div className='py-4 px-6 border-b border-slate-200'>
                        <h1 className='text-xl'>Generate Tagihan Spp</h1>
                    </div>
                    <form onSubmit={methodGenerateTagihan.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                        <OptionsField label="Lembaga" options={lembagaOpt} onChange={(e) => setLem(e)} value={{ value: lem.value, label: lem.label }} />
                        <Options label="Angkatan" method={methodGenerateTagihan} methodName="angkatan" options={thn_ajaranOpt} disabled={disableTahun} />
                        <Options label="Tahun ajar" method={methodGenerateTagihan} methodName="tahun_ajar" options={thn_ajaranOpt} />
                        <OptionsField label="Golongan" options={golOpt} onChange={(e) => setGol(e)} value={{ value: gol?.value, label: gol?.label }} />
                        <InputField label="Spp" value={spp} read={true} />
                        <InputField label="Up" value={up} read={true} />
                        <OptionsField label="Dari Bulan" options={bulanTagihanOpt} onChange={(e) => setDariBul(e)} value={{ value: dariBul?.value, label: dariBul?.label }} />
                        <OptionsField label="Sampai Bulan" options={bulanTagihanOpt} onChange={(e) => setSampaiBul(e)} value={{ value: sampaiBul?.value, label: sampaiBul?.label }} disabled={true} />
                        <AddButton />
                    </form>
                </div>
            </Dialog>

        </div>
    )
}

export default FormGenerateTagihan 