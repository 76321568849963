import api from "./http"

const ApiTahunSistem = {
    getAll:()=>{
        return api.get(`/active-year-in-system`)
    },
    add:(data)=>{
        return api.post(`/active-year-in-system`,data)
    },
    update:(id,data)=>{
        return api.put(`/active-year-in-system/${id}`,data)
    },
    delete:(id)=>{
        return api.delete(`/active-year-in-system/${id}`)
    }
}

export default ApiTahunSistem