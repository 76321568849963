import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ButtonNext from "../component/ButtonNext";
import FormAyah from "../component/FormAyah";
import FormIbu from "../component/FormIbu";
import FormSantri from "../component/FormSantri";
import FormWali from "../component/FormWali";
import HeaderUi from "../component/HeaderUi";
import Loader from "../component/Loader";
import SnackBar from "../component/SnackBar";
import { addSantri, oneSantri, updateSantri } from "../store/actions/santri";
import { santriActions } from "../store/slice/santri";

function EditSantri() {
  const { loadingSantri, msgSantri, santriOne } = useSelector(
    (state) => state.santri
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const uuid = useParams();
  const [snackForm, setSnackForm] = useState(false);
  const steps = ["santri", "ayah", "ibu", "wali"];
  const [currentStep, setCurrentStep] = useState(0);
  const forms = [useRef(), useRef(), useRef(), useRef()];
  const [data, setData] = useState({ santri: {}, ayah: {}, ibu: {}, wali: {} });
  const handleNext = async () => {
    await forms[currentStep].current.method.trigger(
      Object.keys(forms[currentStep].current.method.getValues())
    );
    const err = forms[currentStep].current.method.formState.errors;
    if (Object.keys(err).length === 0) {
      setData((pref) => {
        pref[steps[currentStep]] =
          forms[currentStep].current.method.getValues();
        return pref;
      });
      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1);
      } else {
        dispatch(updateSantri({ uuid: uuid.uuid, update: data }));
        setSnackForm(!snackForm);
        // if(msgSantri === "updated" && !loadingSantri){
        //     navigate("/home/table/santri")
        // }
      }
    }
  };
  const handleJump = (toIndex) => {
    if (currentStep > toIndex) {
      currentStep < steps.length && setCurrentStep(toIndex);
    }
  };
  const handlePrev = () => {
    currentStep > 0 && setCurrentStep(currentStep - 1);
  };
  useEffect(() => {
    if (params.uuid !== "") {
      dispatch(oneSantri(params.uuid));
    }
  }, [params.uuid, snackForm]);
  return (
    <div>
      <Loader show={loadingSantri} />
      <SnackBar show={snackForm} toggle={setSnackForm} msg={msgSantri} />
      <HeaderUi titleHeader="Edit Santri" location={"add"} />
      <div className="p-5 mx-7 mt-10 mb-5 bg-white">
        <div className="flex items-center">
          <button
            className="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer mr-2 
        hover:bg-gray-200  
        bg-gray-50 
        text-gray-700 
        border duration-200 ease-in-out 
        border-gray-600 transition"
            onClick={handlePrev}
          >
            Previous
          </button>
          <div className="flex w-full">
            {steps.map((s, i) => (
              <div key={i} className="group w-full last:w-auto uppercase">
                <div className="flex items-center text-teal-600 relative">
                  <div
                    onClick={() => handleJump(i)}
                    className={`rounded-full text-sm flex items-center justify-center transition duration-500 ease-in-out h-10 w-16 border-2 p-2 cursor-pointer ${
                      currentStep >= i && "border-sky-300"
                    } ${currentStep === i && "bg-sky-600 text-white"}`}
                  >
                    {s}
                  </div>
                  <div
                    className={`group-last:hidden h-1 w-full ${
                      currentStep >= i + 1 ? "bg-sky-600" : "bg-sky-200"
                    } ${i === 3 ? "hidden" : "block"}`}
                  ></div>
                </div>
              </div>
            ))}
          </div>
          <ButtonNext
            class="text-base  ml-2  hover:scale-110 focus:outline-none flex justify-center px-8 py-2 rounded font-bold cursor-pointer    
                        hover:bg-gray-200  
                        bg-gray-50 
                        text-sky-600
                        hover:text-blue-700
                        border duration-200 ease-in-out 
                        border-sky-600 transition"
            onClick={handleNext}
            name={currentStep === 3 ? "Edit" : "Next"}
          />
        </div>
      </div>
      <div className="mx-7 bg-white p-8">
        <div className={`${currentStep !== 0 && "hidden"}`}>
          <FormSantri ref={forms[0]} />
        </div>
        <div className={`${currentStep !== 1 && "hidden"}`}>
          <FormAyah ref={forms[1]} />
        </div>
        <div className={`${currentStep !== 2 && "hidden"}`}>
          <FormIbu ref={forms[2]} />
        </div>
        <div className={`${currentStep !== 3 && "hidden"}`}>
          <FormWali ref={forms[3]} ayah={data.ayah} ibu={data.ibu} />
        </div>
      </div>
    </div>
  );
}

export default EditSantri;
