import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import Sidebar from './component/Sidebar';
import InputSantri from './UI/InputSantri';
import TablesSantri from './UI/TablesSantri';
import EditSantri from './UI/EditSantri';
import Pembayaran from './UI/Pembayaran';
import ListSpp from './UI/ListSpp';
import ListLembaga from './UI/ListLembaga';
import Login from './UI/Login';
import { useSelector } from 'react-redux';
import TableUser from './UI/TableUser';
import CetakBukti from './UI/CetakBukti';
import TableThn from './UI/TableThn';
import ListRuang from './UI/ListRuang';
import ListTunggakan from './UI/ListTunggakan';
import TableDonatur from './UI/TableDonatur';
import PanelSantri from './UI/PanelSantri';
import ListTunggakanDonatur from './UI/ListTunggakanDonatur';
import Laporan from './UI/Laporan';
import Dashboard from './UI/Dashboard';
import ListGolDonatur from './UI/ListGolDonatur';
import PembayaranDonatur from './UI/PembayaranDonatur';
import LaporanDonatur from './UI/LaporanDonatur';
import LaporanLembaga from './UI/LaporanLembaga';
import LaporanSantri from './UI/LaporanSantri';
import TableHistorySantri from './UI/TableHistorySantri';
import TablePmebayaranTerhapus from './UI/TablePmebayaranTerhapus';
import Bersaudara from './UI/Bersaudara';
import TableAlumni from './UI/TableAlumni';
import UploadGambar from './UI/UploadGambar';
import BelanjaSantri from './UI/BelanjaSantri';
import Subsidi from './UI/Subsidi';
import ListDiskonBersaudara from './UI/ListDiskonBersaudara';
import ListTahunSistem from './UI/ListTahunSistem';

function App() {
  const {token,super_admin,generalUser} = useSelector((state)=>state.auth)
  return (
    <div>
      <Routes>
        <Route path='/' element={token === "" ? <Login/> : <Navigate to={`/home/santri`} replace/>}/>
        <Route path='/home' element={token === "" ? <Navigate to={`/`} replace/> : <Sidebar />}>
          <Route path='/home/dashboard' element={<Dashboard/>}/>
          <Route path='/home/upload/gambar' element={<UploadGambar/>}/>
          <Route path='/home/input/santri' element={<InputSantri />} />
          <Route path='/home/edit/santri/:uuid' element={<EditSantri/>}/>
          <Route path='/home/santri' element={<TablesSantri/>}/>
          <Route path='/home/alumni' element={<TableAlumni/>}/>
          <Route path='/home/subsidi' element={<Subsidi/>}/>
          <Route path='/home/laporan' element={<Laporan/>}/>
          <Route path='/home/laporan/donatur' element={<LaporanDonatur/>}/>
          <Route path='/home/laporan/lembaga' element={<LaporanLembaga/>}/>
          <Route path='/home/laporan/santri' element={<LaporanSantri/>}/>
          <Route path='/home/pembayaran/:nuwb' element={<Pembayaran/>}/>
          <Route path='/home/pembayaran/donatur/:tahun/:donatur/:golongan' element={<PembayaranDonatur/>}/>
          <Route path='/home/tunggakan' element={<ListTunggakan/>}/>
          <Route path='/home/belanja/santri/:nuwb' element={<BelanjaSantri/>}/>
          <Route path='/home/panel/santri/:nuwb' element={<PanelSantri/>}/>
          <Route path='/home/panel/history/:nuwb' element={<TableHistorySantri/>}/>
          <Route path='/home/panel/bersaudara/:nuwb' element={<Bersaudara/>}/>
          <Route path='/home/laporan/terhapus' element={super_admin ? <TablePmebayaranTerhapus/> : <Navigate to={`/home/santri`} replace/>} />
          <Route path='/home/tunggakan/donatur' element={<ListTunggakanDonatur/>}/>
          <Route path='/home/list/spp' element={super_admin ? <ListSpp/> : <Navigate to={`/home/santri`} replace/>}/>
          <Route path='/home/list/lembaga' element={super_admin ? <ListLembaga/> :  <Navigate to={`/home/santri`} replace/>}/>
          <Route path='/home/list/diskon-bersaudara' element={super_admin ? <ListDiskonBersaudara/> :  <Navigate to={`/home/santri`} replace/>}/>
          <Route path='/home/list/user' element={ generalUser || super_admin ? <TableUser/> : <Navigate to={`/home/santri`} replace/>}/>
          <Route path='/home/list/tahun' element={super_admin ? <TableThn/> : <Navigate to={`/home/santri`} replace/>}/>
          <Route path='/home/list/tahun-sistem' element={super_admin ? <ListTahunSistem/> : <Navigate to={`/home/santri`} replace/>}/>
          <Route path='/home/list/ruang' element={super_admin ? <ListRuang/> : <Navigate to={`/home/santri`} replace/>}/>
          <Route path='/home/list/donatur' element={super_admin ? <TableDonatur/> : <Navigate to={`/home/santri`} replace/>}/>
          <Route path='/home/list/golongan/donatur' element={super_admin ? <ListGolDonatur/> : <Navigate to={`/home/santri`} replace/>}/>
          <Route path='/home/bukti/:nuwb/:tahun' element={<CetakBukti/>}/>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
