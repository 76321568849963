import React, { useState } from "react";
import { HiOutlinePencilSquare, HiOutlineTrash } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import DialogDelete from "../component/DialogDelete";
import HeaderUi from "../component/HeaderUi";
import SnackBar from "../component/SnackBar";
import Table from "../component/Table";
import Loader from "../component/Loader";
import {
  addTahunSistem,
  deleteTahunSistem,
  updateTahunSistem,
} from "../store/actions/tahunSistem";
import FormTahunSistem from "../component/FormTahunSistem";
import useGetTahunSistem from "../hooks/useGetTahunSistem";

function ListTahunSistem() {
  const [formTahunSistem, setFormTahunSistem] = useState(false);
  const [formEdit, setFormEdit] = useState(false);
  const [snack, setSnack] = useState(false);
  const [formDelete, setFormDelete] = useState(false);
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  useGetTahunSistem(snack);
  const { tahunSistemAll, msgTahunSistem, loadingTahunSistem } = useSelector(
    (state) => state.tahunSistem
  );
  const add = (e) => {
    e = { name: e.name, academic_year: `${e.from}/${e.to}`,active:e.active === "aktif" ? true : false };
    dispatch(addTahunSistem(e));
    setFormTahunSistem(!formTahunSistem);
    setSnack(!snack);
  };
  const edit = (e) => {
    e.active = e.active === "aktif" ? true : false;
    dispatch(updateTahunSistem({ id: data.id, data: e }));
    setFormTahunSistem(!formTahunSistem);
    setSnack(!snack);
  };
  const deleted = () => {
    dispatch(deleteTahunSistem(data.id));
    setFormDelete(!formDelete);
    setSnack(!snack);
  };
  console.log(tahunSistemAll);
  return (
    <div>
      <HeaderUi titleHeader="List Tahun Sistem" />
      <DialogDelete show={formDelete} close={setFormDelete} onClick={deleted} />
      <SnackBar show={snack} toggle={setSnack} msg={msgTahunSistem} />
      <Loader show={loadingTahunSistem} />
      <FormTahunSistem
        show={formTahunSistem}
        close={setFormTahunSistem}
        submit={data ? edit : add}
        oneData={data}
      />
      <Table
        titleFirst="Tambah"
        clickFirst={() => {
          setData(null);
          setFormTahunSistem(!formTahunSistem);
        }}
      >
        <div className="bg-white">
          {tahunSistemAll?.length === 0 ? (
            <div>
              <h1 className="font-light text-center my-10 text-xl">
                Tidak Ada Tahun Sistem
              </h1>
            </div>
          ) : (
            <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden z-0">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      No
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Tahun
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Sistem
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Edit
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Hapus
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tahunSistemAll.map((d, id) => (
                    <tr
                      className="bg-white border-b hover:bg-gray-100"
                      key={id}
                    >
                      <td className="px-6 py-3">{id + 1}</td>
                      <td className="px-6 py-3">{d.academic_year}</td>
                      <td className="px-6 py-3">{d.name}</td>
                      <td className="px-6 py-3">
                        {d.active ? "Aktif" : "Tidak Aktif"}
                      </td>
                      <td className="px-3 py-3">
                        <HiOutlinePencilSquare
                          className="w-6 h-6 cursor-pointer mx-3"
                          onClick={() => {
                            setFormTahunSistem(!formEdit);
                            setData(d);
                          }}
                        />
                      </td>
                      <td className="px-3 py-3">
                        <HiOutlineTrash
                          className="w-6 h-6 cursor-pointer mx-3"
                          onClick={() => {
                            setFormDelete(!formDelete);
                            setData(d);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Table>
    </div>
  );
}

export default ListTahunSistem;
