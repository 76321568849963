import React from 'react'

function RadioField(props) {
  return (
    <div className='mr-2 space-x-1'>
        <input type="radio" value={props.value} onChange={props.onChange} id={props.id} name={props.name}/>
        <label>{props.label}</label>
    </div>
  )
}

export default RadioField