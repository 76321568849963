import React, { useEffect, useState } from 'react'
import { HiOutlineChevronDown, HiOutlinePencilSquare, HiOutlineTrash } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import FormAddLembaga from '../component/FormAddLembaga'
import FormEditLembaga from '../component/FormEditLembaga'
import HeaderUi from '../component/HeaderUi'
import SnackBar from '../component/SnackBar'
import Table from '../component/Table'
import { addLembaga, updateLembaga } from '../store/actions/lembaga'
import { getLembaga } from '../store/actions/optional'

function ListLembaga() {
    const dispatch = useDispatch()
    const { lembaga } = useSelector((state) => state.optional)
    const {msgLembaga} = useSelector((state)=>state.lembaga)
    const [formLembaga, setFormLembaga] = useState(false)
    const [snack,setSnack] = useState(false)
    const [formEdit,setFormEdit] = useState(false)
    const [data,setData] = useState({})
    useEffect(() => {
        dispatch(getLembaga())
    }, [snack])
    const addNewLembaga = (e) =>{
        dispatch(addLembaga(e))
        setFormLembaga(!formLembaga)
        setSnack(!snack)
    }
    const editLembaga = (e) =>{
        dispatch(updateLembaga({uuid:data.kode_lembaga,update:e}))
        setFormEdit(!formEdit)
        setSnack(!snack)
    }
    return (
        <div>
            <SnackBar show={snack} toggle={setSnack} msg={msgLembaga}/>
            <HeaderUi titleHeader="List Lembaga" location="List" />
            <FormAddLembaga showForm={formLembaga} closeForm={setFormLembaga} submit={addNewLembaga}/>
            <FormEditLembaga showForm={formEdit} closeForm={setFormEdit} submit={editLembaga} data={data}/>
            <Table
                titleFirst="Tambah"
                clickFirst={() => {
                    setFormLembaga(!formLembaga)
                }}
            >
                <div className='bg-white'>
                    {lembaga.length === 0 ? <div>
                        <h1 className='font-light text-center my-10 text-xl'>Tidak Ada Lembagai</h1>
                    </div> :
                        <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden z-0">
                            <table className="w-full text-sm text-left text-gray-500 ">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            No
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Kode Lembaga
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Nama Lembaga
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Edit
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lembaga.map((d, id) => (
                                        <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                                            <td className='px-6 py-3'>{id + 1}</td>
                                            <td className='px-6 py-3'>{d.kode_lembaga}</td>
                                            <td className='px-6 py-3'>{d.nama}</td>
                                            <td className='px-3 py-3'><HiOutlinePencilSquare className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                                setFormEdit(!formEdit)
                                                setData(d)
                                            }} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </Table>
        </div>
    )
}

export default ListLembaga