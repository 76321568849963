import { useSelector } from "react-redux";
import useGetTahun from "../hooks/useGetTahun";
import { useEffect, useState } from "react";


export default function useGetTahunOptions(trigger){
    const [tahunOptions,setTahunOptions] = useState([])
    const {thn_ajar} = useSelector(state=>state.optional)
    useGetTahun(trigger)
    useEffect(()=>{
        if(thn_ajar?.length > 0){
            const tahun_ = thn_ajar?.map((data)=>{
                return {value:data.tahun,label:data.tahun}
            })
            setTahunOptions(tahun_)
        }
    },[thn_ajar])
    return tahunOptions
}
