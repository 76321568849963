import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormaddTahun from '../component/FormaddTahun'
import HeaderUi from '../component/HeaderUi'
import Loader from '../component/Loader'
import SnackBar from '../component/SnackBar'
import Table from '../component/Table'
import { getThnAjar } from '../store/actions/optional'
import { createTahun } from '../store/actions/spp'

function TableThn() {
    const dispatch = useDispatch()
    const [snack,setSnack] = useState(false)
    const {msgSpp,loadingSpp} = useSelector((state)=>state.spp)
    const [formAddTahun, setFormAddTahun] = useState(false)
    const {thn_ajar} = useSelector((state)=>state.optional)
    const add = (e) => {
        dispatch(createTahun(e))
        setFormAddTahun(!formAddTahun)
        setSnack(!snack)
    }
    useEffect(()=>{
        dispatch(getThnAjar())
    },[snack])
    return (
        <div>
            <HeaderUi titleHeader="Tahun Ajar" locattion={"list"} />
            <Loader show={loadingSpp}/>
            <SnackBar show={snack} toggle={setSnack} msg={msgSpp}/>
            <FormaddTahun showForm={formAddTahun} closeForm={setFormAddTahun} submit={add} />
            <Table
                titleFirst="Tambah"
                clickFirst={() => setFormAddTahun(!formAddTahun)}
            >
                <div className='bg-white'>
                    {thn_ajar.length === 0 ? <div>
                        <h1 className='font-light text-center my-10 text-xl'>Tidak Ada Tahun Ajar</h1>
                    </div> :
                        <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden z-0">
                            <table className="w-full text-sm text-left text-gray-500 ">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            No
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Tahun Ajar
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Active
                                        </th>
                                        {/* <th scope="col" className="px-6 py-3">
                                            Edit
                                        </th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {thn_ajar.map((d, id) => (
                                        <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                                            <td className='px-6 py-3'>{id + 1}</td>
                                            <td className='px-6 py-3'>{d.tahun}</td>
                                            <td className='px-6 py-3'>{d.active ? "Aktif" : "Tidak"}</td>
                                            {/* <td className='px-3 py-3'><HiOutlinePencilSquare className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                                setFormEdit(!formEdit)
                                                setData(d)
                                            }} /></td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </Table>
        </div>
    )
}

export default TableThn