import api from "./http";

const ApiLembaga = {
    addNew : (data) =>{
        return api.post(`/lembaga`,data)
    },
    edit : (uuid,data) =>{
        return api.put(`/lembaga/${uuid}`,data)
    },
    addRuang : (data) =>{
        return api.post(`/ruang`,data)
    },
    editRuang : (id,data) =>{
        return api.put(`/ruang/${id}`,data)
    },
    deleteRuang : (id) =>{
        return api.delete(`/ruang/${id}`)
    }
}
export default ApiLembaga