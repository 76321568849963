import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiDashboard from "../../api/dashboard";

export const getAllRekapSpp = createAsyncThunk(
  "/get/dashboard/rekap/golongan",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiDashboard.getRekapSpp(
        data.kode_lembaga,
        data.kelas
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (e) {
      return rejectWithValue(e?.response?.data?.msg);
    }
  }
);

