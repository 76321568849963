import React from 'react'
import Button from './Button'
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi2'
import Select from 'react-select'
import { useSelector } from 'react-redux'


function Table(props) {
    return (
        <div className='bg-white my-7 md:mx-5 mx-3 h-full md:py-7 py-5 md:px-12 px-3 shadow-lg'>
            <div className='flex md:justify-end md:space-x-4 space-x-3'>
                <div className={`relative md:mt-0 mt-3 `}>
                    <Button classname={props.classFirst} title={props.titleFirst} click={props.clickFirst} icon={props.dropDown ? <HiOutlineChevronUp className='my-auto ml-2' /> : <HiOutlineChevronDown className={`my-auto ml-2`} />} />
                    <div className={`z-10 absolute -bottom-5 transition-all ease-in-out duration-200 bg-gray-100 w-full ${props.dropDown ? "visible -bottom-[7.5rem] bg-opacity-100 text-opacity-100" : "invisible -bottom-[7rem] bg-opacity-0 text-transparent"}`}>
                        <div onClick={props.formAdd} className='p-2 w-full hover:bg-gray-300 cursor-pointer'>
                            Tambah
                        </div>
                        <div onClick={props.formEdit} className='p-2 w-full hover:bg-gray-300 cursor-pointer'>
                            Edit
                        </div>
                        <div onClick={props.formDelete} className='p-2 w-full hover:bg-gray-300 cursor-pointer'>
                            Delete
                        </div>
                    </div>
                </div>
            </div>
            {props.children}
        </div>
    )
}

export default Table