import React, { forwardRef, useImperativeHandle, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Input from './Input'
import Radio from './Radio'
import Options from './Options'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { pekerjaan, pendidikan, penghasilan, status } from './OptSelect'

const FormWali = forwardRef((props, ref) => {
    const { santriOne, loadingSantri } = useSelector((state) => state.santri)
    const location = useLocation()
    const methodWali = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                nama: yup.string().required("Tidak Boleh Kosong"),
                t_lahir: yup.string().required("Tidak Boleh Kosong"),
                tgl_lahir: yup.string().required("Tidak Boleh Kosong"),
                thn_lahir: yup.string().required("Tidak Boleh Kosong"),
                nik: yup.string().min(16, "Tidak Boleh Kurang dari 16 karakter").max(16, "Tidak Boleh Lebih dari 16 karakter").required("Tidak Boleh Kosong"),
                kewarganegaraan: yup.string().oneOf(["WNI", "WNA"], "Pilih Salah Satu").required("Tidak Boleh Kosong"),
                alamat: yup.string().required("Tidak Boleh Kosong"),
                pendidikan: yup.string().required("Tidak Boleh Kosong"),
                pekerjaan: yup.string().required("Tidak Boleh Kosong"),
                penghasilan: yup.string().required("Tidak Boleh Kosong"),
                status: yup.string().required("Tidak Boleh Kosong"),
                no_wa: yup.string().required("Tidak Boleh Kosong"),
                no_hp: yup.string().required("Tidak Boleh Kosong"),
                status_keluarga: yup.string().nullable().required("Status Keluarga wajib di isi"),
            })
        )
    })
    useImperativeHandle(
        ref, () => ({
            method: methodWali

        })
    )
    const statusWatch = methodWali.watch("status_keluarga")
    useEffect(() => {
        if (statusWatch !== undefined) {
            methodWali.setValue("status_keluarga", statusWatch)
            if (statusWatch === "Ayah") {
                methodWali.reset({...props.ayah,status_keluarga:statusWatch})
            }
            else if (statusWatch === "Ibu") {
                methodWali.reset({...props.ibu,status_keluarga:statusWatch})
            }
            else if (statusWatch ===  santriOne?.wali?.status_keluarga){
                methodWali.reset(santriOne.wali)
            }
            else {
                methodWali.reset({
                    nama: "",
                    t_lahir: "",
                    tgl_lahir: "",
                    thn_lahir: "",
                    nik: "",
                    kewarganegaraan: "",
                    alamat: "",
                    pendidikan: "",
                    pekerjaan: "",
                    penghasilan: "",
                    status: "",
                    no_wa: "",
                    no_hp: "",
                    status_keluarga:statusWatch
                })
            }
        }
    }, [statusWatch])
    useEffect(() => {
        if (location.pathname !== "/home/input/santri") {
            if (Object.keys(santriOne).length !== 0) {
                if (santriOne.wali !== null) {
                    if (Object.keys(santriOne.wali).length !== 0) {
                        methodWali.reset(santriOne.wali)
                    }
                }
            }
        }
    }, [loadingSantri])
    const statusKeluarga = [
        { value: "Ayah", label: "Ayah" },
        { value: "Ibu", label: "Ibu" },
        { value: "Kakek", label: "Kakek" },
        { value: "Nenek", label: "Nenek" },
        { value: "Kakak", label: "Kakak" },
        { value: "Adik", label: "Adik" },
        { value: "Lainnya", label: "Lainnya" },
    ]
    return (
        <div>
            <form className='md:grid md:grid-cols-2 md:gap-5'>
                <Options
                    // onChange={(v) => {
                    //     if (v === "Ayah") {
                    //         methodWali.reset(props.ayah)
                    //         // methodWali.setValue("status_keluarga", v)
                    //     }
                    //     else if (v === "Ibu") {
                    //         methodWali.reset(props.ibu)
                    //         // methodWali.setValue("status_keluarga", v.value)
                    //     }
                    //     else {
                    //         methodWali.reset({})
                    //         // methodWali.setValue("status_keluarga", v.value)
                    //     }
                    // }}
                    method={methodWali}
                    options={statusKeluarga}
                    label="Status Keluarga Wali"
                    methodName="status_keluarga"
                />
                <Input label="Nama Lengkap" method={methodWali} methodName="nama" />
                <Input label="Tempat Lahir" method={methodWali} methodName="t_lahir" />
                <Input label="Tanggal Lahir" method={methodWali} methodName="tgl_lahir" type="date" />
                <Input label="Tahun Lahir" method={methodWali} methodName="thn_lahir" type="number" />
                <Input label="NIK" method={methodWali} methodName="nik" type="number" />
                <div>
                    <h1 className='font-light text-lg mb-2'>kewarganegaraan</h1>
                    <div className='flex my-auto md:space-x-0 space-x-2'>
                        <Radio method={methodWali} methodName="kewarganegaraan" value="WNI" label="WNI" />
                        <Radio method={methodWali} methodName="kewarganegaraan" value="WNA" label="WNA" />
                    </div>
                    {methodWali.formState.errors["kewarganegaraan"] && (
                        <p className="text-red-600 mt-1 mx-1">
                            {methodWali.formState.errors["kewarganegaraan"].message}
                        </p>
                    )}
                </div>
                <Input label="Alamat" method={methodWali} methodName="alamat" />
                <Options label="Pendidikan" method={methodWali} methodName="pendidikan" options={pendidikan} />
                <Options label="Pekerjaan" method={methodWali} methodName="pekerjaan" options={pekerjaan} />
                <Options label="Penghasilan" method={methodWali} methodName="penghasilan" options={penghasilan} />
                <Options label="Status" method={methodWali} methodName="status" options={status} />
                <Input label="Nomor WA" method={methodWali} methodName="no_wa" />
                <Input label="Nomor HP" method={methodWali} methodName="no_hp" />
            </form>
        </div>
    )
})

export default FormWali