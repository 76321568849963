import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { HiEye, HiEyeSlash } from 'react-icons/hi2'
import * as yup from "yup"
import AddButton from './AddButton'
import Dialog from './Dialog'
import Input from './Input'
import Radio from './Radio'

function FormEditUser(props) {
    const [eye, setEye] = useState(false)
    const methodEditUser = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                nama: yup.string().required("Tidak Boleh Kosong"),
                username: yup.string().required("Tidak Boleh Kosong"),
                password: yup.string(),
                general_user: yup.string().oneOf(["true","false"],"Pilih Salah Satu").required("Tidak Boleh Kosong")
            })
        )
    })
    useEffect(() => {
        if (Object.keys(props.data).length !== 0) {
            if (props.data.general_user) {
                methodEditUser.reset({ username: props.data.username, general_user: "true", nama: props.data.nama })
            }
            else {
                methodEditUser.reset({ username: props.data.username, general_user: "false", nama: props.data.nama })
            }
        }
    }, [props.data])
    return (
        <div>
            <Dialog show={props.showForm} toggle={props.closeForm}>
                <div className='bg-white md:w-[40vw] w-[90vw]'>
                    <div className='py-4 px-6 border-b border-slate-200'>
                        <h1 className='text-xl'>Formulir Edit User</h1>
                    </div>
                    <form onSubmit={methodEditUser.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                        <div className='md:grid md:grid-cols-1 md:gap-5'>
                            <Input
                                label="Nama"
                                method={methodEditUser}
                                methodName="nama"
                            />
                            <Input
                                label="username"
                                method={methodEditUser}
                                methodName="username"
                            />
                            <Input
                                type={eye ? "text" : "password"}
                                label="Password"
                                method={methodEditUser}
                                methodName="password"
                                icon={eye ? <HiEye className='w-6 h-6 -mr-3 cursor-pointer absolute right-5 mt-3' onClick={() => setEye(!eye)} /> : <HiEyeSlash className='w-6 h-6 my-auto -mr-3 cursor-pointer absolute right-5 mt-3' onClick={() => setEye(!eye)} />}
                            />
                            <div>
                                <h1 className='font-light text-lg mb-2'>General User</h1>
                                <div className='flex my-auto md:space-x-0 space-x-2'>
                                    <Radio method={methodEditUser} methodName="general_user" value="true" label="Iya" />
                                    <Radio method={methodEditUser} methodName="general_user" value="false" label="Tidak" />
                                </div>
                                {methodEditUser.formState.errors["general_user"] && (
                                    <p className="text-red-600 mt-1 mx-1">
                                        {methodEditUser.formState.errors["general_user"].message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <AddButton />
                    </form>
                </div>
            </Dialog>
        </div>)
}

export default FormEditUser