import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderUi from "../component/HeaderUi";
import Table from "../component/Table";
import { allReportSantri } from "../store/actions/santri";
import { getKelas, getLembaga } from "../store/actions/optional";
import OptionsField from "../component/OptionsField";
import Button from "../component/Button";
import { getAllRekapSpp } from "../store/actions/dashboard";
import Loader from "../component/Loader";
import { useNavigate } from "react-router-dom";
import { currency } from "../component/Currency";
import moment from "moment";
import "moment/locale/id"

function useCount(data, key) {
  let result = 0;
  for (let i of data) {
    result += i[key];
  }
  return result;
}

function Dashboard() {
  const headTable = [
    "No",
    "Lembaga",
    "Kelas",
    "Keterangan",
    "Donatur",
    "Jumlah Santri",
    "Total Resapan",
    "Waktu Rekap",
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [oneLembaga, setOneLembaga] = useState({});
  const [oneKelas, setOneKelas] = useState({});
  const { lembaga, kelas } = useSelector((state) => state.optional);
  const { reportSantriAll, loadingSantri } = useSelector(
    (state) => state.santri
  );
  const { allRekapSpp, loadingDashboard } = useSelector(
    (state) => state.dashboard
  );
  useEffect(() => {
    dispatch(allReportSantri());
    dispatch(getLembaga());
  }, []);
  useEffect(() => {
    dispatch(getKelas(oneLembaga?.value));
  }, [oneLembaga]);
  const lembagaOpt = lembaga.map((e) => {
    return { value: e.kode_lembaga, label: e.nama };
  });
  lembagaOpt.splice(0, 0, { value: 0, label: "Semua" });
  const kelasOpt = kelas.map((d) => {
    return { value: d.kelas, label: d.kelas };
  });
  kelasOpt.splice(0, 0, { value: 0, label: "Semua" });
  const filterGolongan = () => {
    dispatch(
      getAllRekapSpp({
        kode_lembaga: oneLembaga?.value,
        kelas: oneKelas?.value,
      })
    );
  };
  console.log(allRekapSpp);
  return (
    <div>
      <HeaderUi titleHeader="Dashboard" />
      <Loader show={loadingDashboard} />
      <Table classFirst="hidden">
        {Object.keys(reportSantriAll).length !== 0 && (
          <div className="">
            <div className="bg-base text-white p-3 rounded-lg w-[19%]">
              <h1 className="text-2xl">Total Seluruh Santri</h1>
              <div className="h-20 items-center flex justify-center">
                <h1 className="text-center text-4xl">
                  {reportSantriAll.total}
                </h1>
              </div>
            </div>
            <div className="w-full h-[2px] bg-base my-5"></div>
            <div className="grid md:grid-cols-5 gap-5">
              {reportSantriAll?.santri_lembaga?.map((d, id) => (
                <div
                  key={id}
                  className="bg-base text-white p-3 rounded-lg"
                >
                  <h1 className="text-xl">{d.lembaga}</h1>
                  <div className="h-20 items-center flex justify-center">
                    <h1 className="text-center text-4xl">{d.total}</h1>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Table>
      <Table classFirst="hidden">
        <h1 className="font-semibold mt-5 mb-3 text-2xl text-sky-700">
          Resapan Spp Santri
        </h1>
        <div className="flex justify-between">
          <div className="flex gap-5">
            <div className="w-72">
              <OptionsField
                label="Lembaga"
                value={oneLembaga}
                onChange={(e) => setOneLembaga(e)}
                options={lembagaOpt}
              />
            </div>
            <div className="w-72">
              <OptionsField
                label="Kelas"
                value={oneKelas}
                onChange={(e) => setOneKelas(e)}
                options={kelasOpt}
              />
            </div>
          </div>
          <div className={`mt-5 flex gap-5`}>
            <Button title="Saring" click={filterGolongan} />
          </div>
        </div>

        <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden">
          <h1 className="text-yellow-600 text-lg font-semibold mb-2">
            Perhatian!!! Data terupdate setiap 3 jam sekali
          </h1>
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                {headTable.map((value, id) => (
                  <th className="px-6 py-3" key={id}>
                    {value}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {allRekapSpp?.map((value, id) => (
                <tr className="bg-white border-b hover:bg-gray-100">
                  <td className="px-6 py-3">{id + 1}</td>
                  <td className="px-6 py-3">{value?.lembaga?.nama}</td>
                  <td className="px-6 py-3">{value?.kelas}</td>
                  <td className="px-6 py-3">{value?.keterangan}</td>
                  <td className="px-6 py-3">{value?.donatur}</td>
                  <td className="px-6 py-3">{value?.jumlah_santri}</td>
                  <td className="px-6 py-3">
                    {value?.total_resapan
                      ? currency(value?.total_resapan)
                      : currency(0)}
                  </td>
                  <td className="px-6 py-3">
                    {moment(value.createdAt).format("dddd, DD MMMM YYYY HH:mm")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-end gap-10 font-semibold text-slate-700 p-4">
            <h1>
              Total Santri : {useCount(allRekapSpp, "jumlah_santri")} Santri
            </h1>
            <h1>
              Total Resapan : {currency(useCount(allRekapSpp, "total_resapan"))}
            </h1>
          </div>
        </div>
      </Table>
    </div>
  );
}

export default Dashboard;
