import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiDonatur from "../../api/donatur";

export const getAllDonatur = createAsyncThunk(
    'donatur/all',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiDonatur.get()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const postNewDonatur = createAsyncThunk(
    'donatur/post',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiDonatur.post(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const deleteOneDonatur = createAsyncThunk(
    'donatur/delete',
    async(uuid,{rejectWithValue})=>{
        try{
            const res = await ApiDonatur.delete(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const editOneDonatur = createAsyncThunk(
    'donatur/edit',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiDonatur.edit(data.uuid,data.update)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const addNewGolonganDonatur = createAsyncThunk(
    'gol/new/danotur',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiDonatur.addGolongan(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const updateOneGolonganDonatur= createAsyncThunk(
    'update/gol/donatur',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiDonatur.editGolongan(data.id,data.update)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const postGenerateDonatur = createAsyncThunk(
    'generate/donatur',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiDonatur.generateDonatur(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const oneDonaturTtpByNuwb = createAsyncThunk(
    'donatur/tetap/one',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiDonatur.getOneDonaturTtpByNuwb(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const postGenerateDonaturTdkTtp = createAsyncThunk(
    'generate/donatur/tdk-tetap',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiDonatur.generateDonaturTdkTtp(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const oneDonaturTdkTtpByNuwb = createAsyncThunk(
    'donatur/tidak_tetap/one',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiDonatur.getOneDonaturTdkTtpByNuwb(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const hapusOneDonaturTdkTtp = createAsyncThunk(
    'donatur/tidak_tetap/delete',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiDonatur.deleteOneDonaturTdkTtp(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const hapusOneDonaturTtp = createAsyncThunk(
    'donatur/tetap/delete',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiDonatur.deleteOneDonaturTtp(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const postDonaturUp = createAsyncThunk(
    'donatur/up/put',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiDonatur.createDonaturUp(data.uuid,data.update)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
    
)
