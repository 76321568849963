import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from "yup"
import { getKelas, getLembaga } from '../store/actions/optional'
import Options from './Options'
import OptionsField from './OptionsField'
import Dialog from './Dialog'
import Input from './Input'
import AddButton from './AddButton'

function FormEditRuang(props) {
    const methodEditRuang = useForm({
        mode:"all",
        resolver : yupResolver(
            yup.object().shape({
                ruang : yup.string().required("Tidak Boleh Kosong"),
            })
        )
    })
    useEffect(()=>{
        if(Object.keys(props.data).length !== 0){
            methodEditRuang.reset({ruang:props.data.ruang})
        }
    },[props.data])
  return (
    <Dialog show={props.showForm} toggle={props.closeForm}>
    <div className='bg-white md:w-[40vw] w-[95vw]'>
        <div className='py-4 px-6 border-b border-slate-200'>
            <h1 className='text-xl'>Edit Ruang Kelas</h1>
        </div>
        <form onSubmit={methodEditRuang.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
            <Input label="Nama Ruang" method={methodEditRuang} methodName="ruang" />
            <AddButton />
        </form>
    </div>
</Dialog>
  )
}

export default FormEditRuang