import React, { useState } from "react";
import { HiOutlinePencilSquare, HiOutlineTrash } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import DialogDelete from "../component/DialogDelete";
import HeaderUi from "../component/HeaderUi";
import OptionsField from "../component/OptionsField";
import SnackBar from "../component/SnackBar";
import Table from "../component/Table";
import { destroyRuang, postRuang, updateRuang } from "../store/actions/lembaga";
import useGetAllDiskonBersaudara from "../hooks/useGetAllDiskonBersaudara";
import useGetTahunOptions from "../options/tahun";
import Loader from "../component/Loader";
import FormAddDiskonBersaudara from "../component/FormAddDiskonBersaudara";
import FormEditDiskonBersaudara from "../component/FormEditDiskonBersaudara";
import { addDiskonBersaudara, deleteDiskonBersaudara, updateDiskonBersaudara } from "../store/actions/diskon";

function ListDiskonBersaudara() {
  const [formDiskon, setFormDiskon] = useState(false);
  const [formEdit, setFormEdit] = useState(false);
  const [tahun, setTahun] = useState({});
  const [snack, setSnack] = useState(false);
  const [formDelete, setFormDelete] = useState(false);
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  useGetAllDiskonBersaudara(tahun?.value,snack);
  const { diskonAll, msgDiskon, loadingDiskon } = useSelector(
    (state) => state.diskonBersaudara
  );
  const tahunOptions = useGetTahunOptions(true);
  const add = (e) => {
    dispatch(addDiskonBersaudara(e));
    setFormDiskon(!formDiskon);
    setSnack(!snack);
  };
  const edit = (e) => {
    dispatch(updateDiskonBersaudara({ id: data.id, data: e }));
    setFormEdit(!formEdit);
    setSnack(!snack);
  };
  const deleted = () => {
    dispatch(deleteDiskonBersaudara(data.id));
    setFormDelete(!formDelete);
    setSnack(!snack);
  };
  return (
    <div>
      <HeaderUi titleHeader="List Diskon Bersaudara" />
      <DialogDelete show={formDelete} close={setFormDelete} onClick={deleted} />
      <SnackBar show={snack} toggle={setSnack} msg={msgDiskon} />
      <Loader show={loadingDiskon} />
      <FormAddDiskonBersaudara
        show={formDiskon}
        close={setFormDiskon}
        tahunOptions={tahunOptions}
        submit={add}
      />
      <FormEditDiskonBersaudara
        show={formEdit}
        close={setFormEdit}
        tahunOptions={tahunOptions}
        data={data}
        submit={edit}
      />
      <Table titleFirst="Tambah" clickFirst={() => setFormDiskon(!formDiskon)}>
        <div className="bg-white">
          <div className="grid grid-cols-4 gap-4">
            <OptionsField
              label="Tahun"
              onChange={(e) => setTahun(e)}
              value={tahun}
              options={tahunOptions}
            />
          </div>
          {diskonAll?.length === 0 ? (
            <div>
              <h1 className="font-light text-center my-10 text-xl">
                Tidak Ada Diskon
              </h1>
            </div>
          ) : (
            <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden z-0">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      No
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Diskon
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Tahun
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Edit
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Hapus
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {diskonAll.map((d, id) => (
                    <tr
                      className="bg-white border-b hover:bg-gray-100"
                      key={id}
                    >
                      <td className="px-6 py-3">{id + 1}</td>
                      <td className="px-6 py-3">{`${d.diskon}%`}</td>
                      <td className="px-6 py-3">{d.tahun_ajar}</td>
                      <td className="px-3 py-3">
                        <HiOutlinePencilSquare
                          className="w-6 h-6 cursor-pointer mx-3"
                          onClick={() => {
                            setFormEdit(!formEdit);
                            setData(d);
                          }}
                        />
                      </td>
                      <td className="px-3 py-3">
                        <HiOutlineTrash
                          className="w-6 h-6 cursor-pointer mx-3"
                          onClick={() => {
                            setFormDelete(!formDelete);
                            setData(d);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Table>
    </div>
  );
}

export default ListDiskonBersaudara;
