import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiOptional from "../../api/optional";

export const getLembaga = createAsyncThunk(
    'lembaga/all',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiOptional.lembaga()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getKecamatan = createAsyncThunk(
    'kecamatan/all',
    async(no,{rejectWithValue})=>{
        try{
            const res = await ApiOptional.kecamatan(no)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getKabupaten = createAsyncThunk(
    'kabupaten/all',
    async(no,{rejectWithValue})=>{
        try{
            const res = await ApiOptional.kabupaten(no)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getProvinsi = createAsyncThunk(
    'provinsi/all',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiOptional.provinsi()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getThnAjar = createAsyncThunk(
    'thnAjara/all',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiOptional.thunAjr()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getGolongan = createAsyncThunk(
    'gol/all',
    async(data,{rejectWithValue})=>{
        try{
            const res = await ApiOptional.golongan(data.thn,data.kode)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getTagihan =  createAsyncThunk(
    'tagihan/all',
    async(gol,{rejectWithValue})=>{
        try{
            const res = await ApiOptional.tagihan(gol)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getKelas = createAsyncThunk(
    'kelas/all',
    async(no,{rejectWithValue})=>{
        try{
            const res = await ApiOptional.kelas(no)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getRuang = createAsyncThunk(
    'ruang/all',
    async(id,{rejectWithValue})=>{
        try{
            const res = await ApiOptional.ruang(id)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getSistem = createAsyncThunk(
    'sistem/all',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiOptional.sistem()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getRole = createAsyncThunk(
    'role/all',
    async(uuid,{rejectWithValue})=>{
        try{
            const res = await ApiOptional.role(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getLembagaList = createAsyncThunk(
    'lembaga/list/all',
    async (_, { rejectWithValue }) => {
        try {
            const res = await ApiOptional.LembagaList()
            if (res.status === 200) {
                return res.data
            }
        }
        catch (_) {
            return rejectWithValue("error")
        }
    }
)
export const getGolDonatur = createAsyncThunk(
    'golongan/donatur',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiOptional.golDonatur()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getGolDonaturByDon = createAsyncThunk(
    'golongan/donatur/uuid',
    async(uuid,{rejectWithValue})=>{
        try{
            const res = await ApiOptional.golDonaturByDon(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
