import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Input from '../component/Input'
import Table from '../component/Table'
import HeaderUi from '../component/HeaderUi'
import { useDispatch, useSelector } from 'react-redux'
import { getAllLaporanLem } from '../store/actions/laporan'
import { currency } from '../component/Currency'
import Loader from '../component/Loader'


function LaporanLembaga() {
    const dispatch = useDispatch()
    const { allLaporanLembaga, loadingLaporan } = useSelector((state) => state.laporan)
    const methodLaporan = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                dari: yup.string().required(),
                sampai: yup.string().required(),
            })
        )
    })
    const getLaporan = (e) => {
        dispatch(getAllLaporanLem(e))
    }
    let jml = 0
    const [totalPerLembaga,setTotalPerlembaga] = useState([])
    totalPerLembaga.length = 0
    allLaporanLembaga.map((d,id) => {
        let sementara = 0
        d.report.map((e) => {
            sementara += e.total
            return jml += e.total
        })
        totalPerLembaga.push(sementara)
    })
    return (
        <div>
            <Loader show={loadingLaporan} />
            <HeaderUi titleHeader="Laporan Pembayaran" />
            <Table
                classFirst="hidden"
            >
                <div>
                    <form onSubmit={methodLaporan.handleSubmit(getLaporan)} className='md:flex md:flex-row flex-col md:space-x-3'>
                        <div className="grid md:grid-cols-4 grid-cols-2 gap-3 w-full">
                            <Input label="Dari" type="date" method={methodLaporan} methodName="dari" />
                            <Input label="Sampai" type="date" method={methodLaporan} methodName="sampai" />
                        </div>
                        <button type='submit' className='border border-blue-500 bg-slate-100 px-8 py-[7px] my-7'>Proses</button>
                    </form>
                    <div className='flex space-x-2 border-bya'>
                        <h1 className='text-lg font-light'>Total Semua</h1>
                        <h1 className='text-lg'> : </h1>
                        <h1 className='text-lg text-blue-700'>{currency(jml)}</h1>
                    </div>

                    {allLaporanLembaga.length === 0 ? <div>
                        <h1 className='font-light text-center my-10 text-xl'>Tidak Ada Laporan</h1>
                    </div> :
                        <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden z-0">
                            {allLaporanLembaga.map((d, id) => (
                                <div key={id}>
                                    <h1 className='my-3 font-semibold'>{d.lembaga}</h1>
                                    <div className='flex space-x-2 border-bya'>
                                        <h1 className='text-lg font-light'>Total Per Lembaga</h1>
                                        <h1 className='text-lg'> : </h1>
                                        <h1 className='text-lg text-blue-700'>{currency(totalPerLembaga[id])}</h1>
                                    </div>
                                    <table className="w-full text-sm text-left text-gray-500 ">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    Metode
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    UP
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    SPP
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Total
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allLaporanLembaga[id].report.map((d, id) => {
                                                return <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                                                    <td className='px-6 py-3'>{d.metode_pembayaran}</td>
                                                    <td className='px-6 py-3'>{currency(parseInt(d.up))}</td>
                                                    <td className='px-6 py-3'>{currency(parseInt(d.spp))}</td>
                                                    <td className='px-6 py-3'>{currency(parseInt(d.total))}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </Table>
        </div>
    )
}

export default LaporanLembaga