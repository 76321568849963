import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import AddButton from './AddButton'
import Dialog from './Dialog'
import Input from './Input'
import * as yup from "yup"

function DialogKk(props) {
    const methodKk = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                nuwb : yup.string().required("Tidak Boleh Kosong"),
                old_kk : yup.string().required("Tidak Boleh Kosong"),
                new_kk: yup.string().required("Tidak Boleh Kosong").min(16, "Tidak Boleh Kurang Dari 16 Karakter").max(16, "Tidak Boleh Lebih Dari 16 Karakter")
            })
        )
    })
    useEffect(() => {
        if (Object.keys(props.data).length !== 0) {
            methodKk.reset({nuwb:props.data.nuwb ,old_kk: props.data.no_kk })
        }
    }, [props.showForm, props.data])
    return (
        <Dialog show={props.showForm} toggle={props.closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Edit No KK</h1>
                </div>
                <form onSubmit={methodKk.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                    <Input label="KK Lama" method={methodKk} methodName="old_kk" read={true} />
                    <Input label="KK Baru" method={methodKk} methodName="new_kk" />
                    <div className='flex justify-end pt-5'>
                        <button type='submit' className='border border-sky-400  px-8 py-2 hover:text-sky-800 hover:border-sky-600 transition-colors ease-in-out duration-300'>Simpan</button>
                    </div>
                </form>
            </div>
        </Dialog>)
}

export default DialogKk