import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProvinsi } from "../store/actions/optional"



export default function useProvinceOptions(){
    const [provinceOptions,setProvinceOptions] = useState([])
    const dispatch = useDispatch()
    const {provinsi} = useSelector(state=>state.optional)
    useEffect(()=>{
        dispatch(getProvinsi())
    },[])
    useEffect(()=>{
        if(provinsi.length > 0){
            const data = provinsi.map((value)=>{
                return {value:value.id,label:value.nama}
            })
            setProvinceOptions(data)
        }
    },[provinsi])
    return provinceOptions
}