import React from "react";
import Dialog from "./Dialog";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useResetForm from "../hooks/custom/useResetForm";
import Input from "./Input";
import Options from "./Options";
import Radio from "./Radio";
import AddButton from "./AddButton";

export default function FormEditDiskonBersaudara(props) {
  const method = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        diskon: yup.number().required("Tidak boleh kosong"),
      })
    ),
  });
  useResetForm(method, { diskon: props.data?.diskon }, props.data);
  return (
    <Dialog show={props.show} toggle={props.close}>
      <div className="bg-white md:w-[40vw] w-[95vw]">
        <div className="py-4 px-6 border-b border-slate-200">
          <h1 className="text-xl">Edit Diskon Bersaudara</h1>
        </div>
        <form
          onSubmit={method.handleSubmit(props.submit)}
          className="px-6 py-7 space-y-1"
        >
          <div className="flex space-x-2">
            <Input
              label="Diskon"
              type="number"
              method={method}
              methodName="diskon"
            />
          </div>
          <AddButton />
        </form>
      </div>
    </Dialog>
  );
}
