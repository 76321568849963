import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect} from 'react'
import { useForm } from 'react-hook-form'
import HeaderUi from '../component/HeaderUi'
import Table from '../component/Table'
import * as yup from "yup"
import { useDispatch, useSelector } from 'react-redux'
import Options from '../component/Options'
import { bulanTunggakanOpt } from '../component/OptSelect'
import { getGolDonatur, getGolDonaturByDon, getKelas, getRuang, getThnAjar } from '../store/actions/optional'
import {getTunggakanDonatur } from '../store/actions/pembayaran'
import { currency } from "../component/Currency"
import Loader from '../component/Loader'
import { getAllDonatur } from '../store/actions/donatur'
import { getPembayaranDon } from '../store/actions/pembayaranDonatur'
import { Navigate, useNavigate } from 'react-router-dom'


function ListTunggakanDonatur() {
    const navigate = useNavigate()
    const { tunggakanDonatur, loadingPembayaran } = useSelector((state) => state.pembayaran)
    const { kelas, ruang,thn_ajar,golDonaturByUid } = useSelector((state) => state.optional)
    const { allDonatur } = useSelector((state) => state.donatur)
    const {onePembayaran} = useSelector((state) => state.pembayaranDon)
    const dispatch = useDispatch()
    const methodTunggakan = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                // kode_lembaga: yup.number().required(),
                bulan: yup.string().required(),
                // kelas: yup.number().required(),
                // ruang: yup.string().required(),
                tahun_ajar : yup.string().required(),
                donatur: yup.string().required(),
                golongan_donatur : yup.string().required()
            })
        )
    })
    const watchDonatur = methodTunggakan.watch("donatur")
    useEffect(() => {
        dispatch(getAllDonatur())
        // dispatch(getLembaga())
        dispatch(getThnAjar())
    }, [])
    useEffect(()=>{
       if(watchDonatur !== ""){
        dispatch(getGolDonaturByDon(watchDonatur))
       }
    },[watchDonatur])
    const donaturOpt = allDonatur.map((d) => {
        return { value: d.uuid, label: d.nama }
    })
    const golDonaturOpt = golDonaturByUid.map((d)=>{
        return {value:d.id,label:d.golongan}
    })
    const thnOpt = thn_ajar.map((d)=>{
        return {value:d.tahun,label:d.tahun}
    })
    const getTunggak = (e) => {
        dispatch(getTunggakanDonatur(e))
    }
    const watchThn = methodTunggakan.watch("tahun_ajar")
    const watchDon = methodTunggakan.watch("donatur")
    const watchGol = methodTunggakan.watch("golongan_donatur")
    const pembayaran = () =>{
        // dispatch(getPembayaranDon({tahun_ajar:watchThn,uuid_donatur:watchDon,id_golongan_donatur:watchGol}))
        navigate(`/home/pembayaran/donatur/${watchThn.replace("/","-")}/${watchDon}/${watchGol}`)
    }
        let jml = 0
    tunggakanDonatur.map((d) => {
        return jml += parseInt(d.total)
    })
    return (
        <div>
            <Loader show={loadingPembayaran}/>
            <HeaderUi titleHeader="Tunggakan Donatur" location="" />
            <Table
                classFirst="hidden"
            >
                <div>
                    <form onSubmit={methodTunggakan.handleSubmit(getTunggak)} className='md:flex md:flex-row flex-col md:space-x-3'>
                        <div className="grid md:grid-cols-6 grid-cols-2 gap-3 w-full">
                            {/* <Options label="Lembaga" method={methodTunggakan} methodName="kode_lembaga" options={lemOpt} /> */}
                            <Options label="Sampai Bulan" method={methodTunggakan} methodName="bulan" options={bulanTunggakanOpt} />
                            <Options label="Tahun Ajar" method={methodTunggakan} methodName="tahun_ajar" options={thnOpt} />
                            {/* <Options label="Kelas" method={methodTunggakan} methodName="kelas" options={kelasOpt} /> */}
                            {/* <Options label="Ruang" method={methodTunggakan} methodName="ruang" options={ruangOpt} /> */}
                            <Options label="Donatur" method={methodTunggakan} methodName="donatur" options={donaturOpt} />
                            <Options label="Golongan" method={methodTunggakan} methodName="golongan_donatur" options={golDonaturOpt} />
                            {/* <Options label="Spesifikasi" method={methodTunggakan} methodName="spesifikasi" options={spekOpt} /> */}
                        </div>
                        <button type='submit' className='border border-blue-500 bg-slate-100 px-8 py-[7px] my-7'>Proses</button>
                    </form>
                    <div className='flex space-x-2 border-bya'>
                        <h1 className='text-lg font-light'>Total Tunggakan</h1>
                        <h1 className='text-lg'> : </h1>
                        <h1 className='text-lg text-blue-700'>{currency(jml)}</h1>
                    </div>
                    <button type='button' onClick={pembayaran} className={`border border-blue-500 bg-slate-100 px-8 py-[7px] my-1 ${Object.keys(tunggakanDonatur).length !== 0 ? "block" : "hidden"}`}>Pembayaran</button>
                    {tunggakanDonatur.length === 0 ? <div>
                        <h1 className='font-light text-center my-10 text-xl'>Tidak Ada Tunggakan</h1>
                    </div> :
                        <div className=" overflow-x-auto shadow-md sm:rounded-lg mt-3 mb-7 overflow-y-hidden z-0">
                            <table className="w-full text-sm text-left text-gray-500 ">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            No
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            NUWB
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Nama
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Total
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tunggakanDonatur.map((d, id) => {
                                        return <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                                            <td className='px-6 py-3'>{id + 1}</td>
                                            <td className='px-6 py-3'>{d.nuwb}</td>
                                            <td className='px-6 py-3'>{d.nama}</td>
                                            <td className='px-6 py-3'>{currency(d.total)}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </Table>
        </div>
    )
}

export default ListTunggakanDonatur