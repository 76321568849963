import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from "yup"
import { getRole, getSistem } from '../store/actions/optional'
import { destroySistem, editSistemOne } from '../store/actions/user'
import AddButton from './AddButton'
import Dialog from './Dialog'
import InputField from './InputField'
import Options from './Options'
import SistemHelp from './SistemHelp'
import RadioField from "./RadioField"
import Radio from './Radio'

function FormEditSistem(props) {
    const dispatch = useDispatch()
    const { sistem, role } = useSelector((state) => state.optional)
    const [change, setChange] = useState(false)
    const [saveId, setSaveId] = useState()
    const [uuidUsr, setUuidUsr] = useState("")
    const methodEditSistem = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                sistem: yup.array().of(yup.object().shape({
                    uuid_sistem: yup.string().required("Tidak Boleh Kosong"),
                    uuid_role: yup.string().required("Tidak Boleh Kosong"),
                    super_admin: yup.string().oneOf(["true", "false"], "Pilih Salah Satu").required("Tidak Boleh Kosong")
                }))
            })
        ),
        defaultValues: { sistem: [{ uuid_sistem: "", uuid_role: "" }] }

    })
    const { fields, append, remove,replace } = useFieldArray({
        control: methodEditSistem.control, name: "sistem"
    })
    const hapusSistem = (e, id) => {
        if (e.uuid_sistem !== "") {
            remove(id)
            dispatch(destroySistem({ uuid: props.data.uuid, uuid_sis: e.uuid_sistem }))
        }
        else {
            remove(id)
        }
    }
    const getSis = (e) => {
        const watchSistem = methodEditSistem.watch(`sistem[${e}].uuid_sistem`)
        const watchUuidRole = methodEditSistem.watch(`sistem[${e}].uuid_role`)
        const watchSuper = methodEditSistem.watch(`sistem[${e}].super_admin`)
        dispatch(editSistemOne({ uuid: uuidUsr, uuid_sistem: watchSistem, update: { uuid_role: watchUuidRole, super_admin: watchSuper } }))
        setChange(false)
    }
    const roleOpt = role.map((d) => {
        return { value: d.uuid, label: d.nama_role }
    })
    const sistemOpt = sistem.map((d) => {
        return { value: d.uuid, label: d.nama_sistem }
    })
    useEffect(() => {
        dispatch(getSistem())
        if (Object.keys(props.data).length !== 0) {
            let save = []
            props.data.sistem.map((d) => (
                save.push({ ...d, super_admin: d.super_admin.toString() })
            ))
            setUuidUsr(props.data.uuid)
            replace(save)
        }
    }, [props.showForm, props.data])
    return (
        <Dialog show={props.showForm} toggle={props.closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Edit Sistem</h1>
                </div>
                <form onSubmit={methodEditSistem.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                    {fields.map((d, id) => (
                        <div key={d.id}>
                            <Options label="Sistem" method={methodEditSistem} methodName={`sistem[${id}].uuid_sistem`} options={sistemOpt} />
                            {change && saveId === id ?
                                <Options label="Role" method={methodEditSistem} methodName={`sistem[${id}].uuid_role`} options={roleOpt} />
                                :
                                <InputField label="Role" value={Object.keys(props.data).length !== 0 && props.data.sistem[id]?.uuid_role ? props.data?.sistem[id]?.nama_role : ""} read={true} />

                            }
                            <div className='mb-1'>
                                <h1 className='font-light text-lg mb-2'>Super Admin</h1>
                                <div className='flex my-auto md:space-x-0 space-x-2'>
                                    <Radio method={methodEditSistem} methodName={`sistem[${id}].super_admin`} value={"true"} label="Iya" />
                                    <Radio method={methodEditSistem} methodName={`sistem[${id}].super_admin`} value={'false'} label="Tidak" />
                                </div>
                            </div>
                            <button type='button' onClick={() => hapusSistem(d, id)} className="border border-red-700 my-1 px-5 py-[2px] hover:border-red-800 transition-colors ease-in-out duration-300 hover:text-red-800">Hapus</button>
                            {change && saveId === id ?
                                <button type='button' onClick={() => getSis(id)} className="ml-2 border border-blue-700 my-1 px-5 py-[2px] hover:border-blue-800 transition-colors ease-in-out duration-300 hover:text-blue-800">Simpan</button>
                                :
                                <button type='button' onClick={() => {
                                    setSaveId(id)
                                    setChange(true)
                                    dispatch(getRole(methodEditSistem.getValues(`sistem[${id}].uuid_sistem`)))
                                }} className="ml-2 border border-blue-700 my-1 px-5 py-[2px] hover:border-blue-800 transition-colors ease-in-out duration-300 hover:text-blue-800">Edit</button>
                            }
                        </div>
                    ))}
                    <div className='flex justify-end'>
                        <button type='button' onClick={() => props.closeForm(false)} className='border border-sky-400  px-8 py-2 hover:text-sky-800 hover:border-sky-600 transition-colors ease-in-out duration-300 flex'>Simpan</button>
                    </div>
                    {/* <AddButton /> */}
                </form>
            </div>
        </Dialog>
    )
}

export default FormEditSistem