import React from "react";
import Dialog from "./Dialog";
import AddButton from "./AddButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "./Input";

function AddUploadGambar(props) {
  const checkEkstension = (e) => {
    let valid = true;
    if (Object.keys(e).length !== 0) {
      if (!["application/pdf", "image/jpeg", "image/png"].includes(e[0].type)) {
        valid = false;
      }
    }
    return valid;
  };
  const checkSize = (e) => {
    let valid = true;
    if (Object.keys(e).length !== 0) {
      const size = e[0].size / 1024 / 1024;
      if (size > 6) {
        valid = false;
      }
    }
    return valid;
  };
  const methodGambar = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        gambar: yup
          .mixed()
          .required("tidak boleh kosong")
          .test("Cek Ekstensi", "Ekstensi tidak sesuai", checkEkstension)
          .test("Cek Size", "Ukuran gambar terlalu beasr", checkSize),
        title: yup.string().required("tidak boleh kosong"),
      })
    ),
  });
  return (
    <Dialog show={props.showForm} toggle={props.closeForm}>
      <div className="bg-white md:w-[40vw] w-[95vw]">
        <div className="py-4 px-6 border-b border-slate-200">
          <h1 className="text-xl">Upload Gambar</h1>
        </div>
        <form
          onSubmit={methodGambar.handleSubmit(props.submit)}
          className="px-6 py-7"
        >
          <Input
            label="Masukkan Gambar"
            type="file"
            method={methodGambar}
            methodName="gambar"
          />
          <div
            className={props.load ? "hidden" : "w-full bg-slate-300 h-1 my-3"}
          >
            <div
              className={`h-1 bg-blue-400`}
              style={{ width: props.val }}
            ></div>
          </div>
          <h1 className="font-light my-2">Size = 522px * 340px</h1>
          <Input
            label="Masukkan Judul"
            method={methodGambar}
            methodName="title"
          />
          <div className={props.showButton}>
            <AddButton />
          </div>
        </form>
      </div>
    </Dialog>
  );
}

export default AddUploadGambar;
