import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from "yup"
import { upByUuid } from '../store/actions/spp'
import AddButton from './AddButton'
import CheckBox from './CheckBox'
import CheckBoxUp from './CheckboxUp'
import Dialog from './Dialog'
import Input from './Input'
import Radio from './Radio'

function FormUp(props) {
    const dispatch = useDispatch()
    const { oneUp } = useSelector((state) => state.spp)
    const [checkYayasan,setCheckYayasan] = useState(false)
    const [checkPondok,setCheckPondok] = useState(false)
    const [kelompok,setKelompok] = useState([])
    const methodUp = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                up: yup.string().required("tidak boleh kosong"),
                donatur: yup.string().oneOf(["yes", "no"], "pilih salah satu").required("tidak boleh kosong"),
                kelompokDonatur: yup.string().nullable().when("donatur",(donatur,field)=> donatur === "yes" ? field.required("tidak boleh kosong"): field),
                potongan: yup.string().required("tidak boleh kosong"),
            })
        )
    })
    const watchDonatur = methodUp.watch("donatur")
    useEffect(() => {
        if (props.showForm && props.uuid !== undefined && props.uuid !== "") {
            dispatch(upByUuid(props.uuid))
        }
    }, [props.showForm, props.uuid])
    useEffect(() => {
        methodUp.reset({ up: oneUp?.up, donatur: false, potongan: null })
    }, [oneUp, props.showForm])
    useEffect(()=>{
        if(watchDonatur === "no"){
            methodUp.setValue("kelompokDonatur",null)
        }
        else if(watchDonatur === "yes"){
            methodUp.setValue("kelompokDonatur",null)
        }
    },[watchDonatur])
    return (
        <Dialog show={props.showForm} toggle={props.closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Donatur Up</h1>
                </div>
                <form onSubmit={methodUp.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                    <Input label="Up" type="number" method={methodUp} methodName="up" />
                    <div>
                        <h1 className='font-light text-lg mb-2'>Donatur</h1>
                        <div className='flex my-auto md:space-x-0 space-x-2'>
                            <Radio method={methodUp} methodName="donatur" value="yes" label="Iya" />
                            <Radio method={methodUp} methodName="donatur" value="no" label="Tidak" />
                        </div>
                        {methodUp.formState.errors["donatur"] && (
                            <p className="text-red-600 mt-1 mx-1">
                                {methodUp.formState.errors["donatur"].message}
                            </p>
                        )}
                    </div>
                    <div className={`flex py-3 ${watchDonatur === "yes" ? "block" : "hidden"}`}>
                        <CheckBoxUp label="Yayasan" value="yayasan" method={methodUp} methodName="kelompokDonatur"/>
                        <CheckBoxUp label="Pondok" value="pondok" method={methodUp} methodName="kelompokDonatur"/>
                    </div>
                    <Input label="Potongan" method={methodUp} methodName="potongan" />
                    <AddButton />
                </form>
            </div>
        </Dialog>)
}

export default FormUp