import api from "./http";

const ApiOptional = {
    lembaga : () =>{
        return api.get(`/lembaga/cakepin`)
    },
    kecamatan : (id_kab) =>{
        return api.get(`/kecamatan/${id_kab}`)
    },
    kabupaten : (id_prov) =>{
        return api.get(`/kabupaten/${id_prov}`)
    },
    provinsi : () =>{
        return api.get(`/provinsi`)
    },
    thunAjr : () =>{
        return api.get(`/tahun`)
    },
    golongan : (thn,kode_lem) =>{
        return api.get(`/cakepin/list_spp/${thn}/${kode_lem}`)
    },
    kelas : (no_lem) =>{
        return api.get(`/kelas/${no_lem}`)
    },
    ruang : (id_kel) =>{
        return api.get(`/ruang/${id_kel}`)
    },
    sistem : () =>{
        return api.get(`/sistem`)
    },
    role : (uuid) =>{
        return api.get(`/role/${uuid}`)
    },
    LembagaList : () =>{
        return api.get(`/apakah/list-lembaga`)
    },
    golDonatur : () =>{
        return api.get(`/cakepin/list-golongan-donatur`)
    },
    golDonaturByDon : (uuid) =>{
        return api.get(`/cakepin/list-golongan-donatur/${uuid}`)
    }

}
export default ApiOptional