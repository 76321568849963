import { createSlice } from "@reduxjs/toolkit";
import { addTahunSistem, deleteTahunSistem, getAllTahunSistem, updateTahunSistem } from "../actions/tahunSistem";

export const tahunSistemStore = createSlice({
  name: "tahun-sistem",
  initialState: {
    tahunSistemAll: [],
    loadingTahunIstem: false,
    msgTahunSistem: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTahunSistem.pending, (state) => {
        state.loadingTahunIstem = true;
      })
      .addCase(getAllTahunSistem.fulfilled, (state, action) => {
        state.loadingTahunIstem = false;
        state.tahunSistemAll = action.payload;
      })
      .addCase(getAllTahunSistem.rejected, (state, action) => {
        state.loadingTahunIstem = false;
        state.tahunSistemAll = [];
        state.msgTahunSistem = action.payload;
      })
      .addCase(addTahunSistem.pending, (state) => {
        state.loadingTahunIstem = true;
      })
      .addCase(addTahunSistem.fulfilled, (state, action) => {
        state.loadingTahunIstem = false;
        state.msgTahunSistem = action.payload?.msg;
      })
      .addCase(addTahunSistem.rejected, (state, action) => {
        state.loadingTahunIstem = false;
        state.tahunSistemAll = [];
        state.msgTahunSistem = action.payload;
      })
      .addCase(updateTahunSistem.pending, (state) => {
        state.loadingTahunIstem = true;
      })
      .addCase(updateTahunSistem.fulfilled, (state, action) => {
        state.loadingTahunIstem = false;
        state.msgTahunSistem = action.payload?.msg;
      })
      .addCase(updateTahunSistem.rejected, (state, action) => {
        state.loadingTahunIstem = false;
        state.tahunSistemAll = [];
        state.msgTahunSistem = action.payload;
      })
      .addCase(deleteTahunSistem.pending, (state) => {
        state.loadingTahunIstem = true;
      })
      .addCase(deleteTahunSistem.fulfilled, (state, action) => {
        state.loadingTahunIstem = false;
        state.msgTahunSistem = action.payload?.msg;
      })
      .addCase(deleteTahunSistem.rejected, (state, action) => {
        state.loadingTahunIstem = false;
        state.tahunSistemAll = [];
        state.msgTahunSistem = action.payload;
      });
  },
});
export default tahunSistemStore.reducer;
