import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import AddButton from './AddButton'
import Dialog from './Dialog'
import Input from './Input'
import Options from './Options'

function FormAddDonatur(props) {
  const methodDonatur = useForm({
    mode:"all",
    resolver:yupResolver(
      yup.object().shape({
        nama:yup.string().required("Tidak Boleh Kosong"),
      })
    )
  })
  const golDonOpt = [
    {value:"yatim",label:"Yatim"},
    {value:"piatu",label:"Piatu"},
    {value:"miskin",label:"Miskin"},
    // {value:"",label:""},
  ]
  useEffect(()=>{
    methodDonatur.reset({nama:"",golongan:""})
  },[props.showForm])
  return (
    <Dialog show={props.showForm} toggle={props.closeForm}>
      <div className='bg-white md:w-[40vw] w-[95vw]'>
        <div className='py-4 px-6 border-b border-slate-200'>
          <h1 className='text-xl'>Tambah Donatur</h1>
        </div>
        <form onSubmit={methodDonatur.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
          <Input label="Donatur" method={methodDonatur} methodName="nama" />
          <AddButton />
        </form>
      </div>
    </Dialog>
  )
}

export default FormAddDonatur