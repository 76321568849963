import { createSlice } from "@reduxjs/toolkit";
import { allSpp,postSpp,getOneSppByThnAjr,updateListSpp, createTagihan, createTahun,getOneTagihan, upByUuid, normalisasiSpp } from "../actions/spp";

export const sppStore = createSlice({
    name:"spp",
    initialState : {
        allListSpp : [],
        listSppByThnAjr : {},
        oneTagihan : {},
        oneUp : {},
        loadingSpp : false,
        msgSpp : ""
    },
    extraReducers : builder =>{
        builder
        .addCase(allSpp.pending,(state)=>{
            state.loadingSpp = true
        })
        .addCase(allSpp.fulfilled,(state,action)=>{
            state.loadingSpp = false
            state.allListSpp = action.payload
        })
        .addCase(allSpp.rejected,(state)=>{
            state.loadingSpp = false
        })
        .addCase(postSpp.pending,(state)=>{
            state.loadingSpp = true
        })
        .addCase(postSpp.fulfilled,(state,action)=>{
            state.loadingSpp = false
            state.msgSpp = action.payload.msg
        })
        .addCase(postSpp.rejected,(state,action)=>{
            state.loadingSpp = false
            state.msgSpp = action.payload
        })
        .addCase(getOneSppByThnAjr.pending,(state)=>{
            state.loadingSpp = true
        })
        .addCase(getOneSppByThnAjr.fulfilled,(state,action)=>{
            state.loadingSpp = false
            state.listSppByThnAjr = action.payload
        })
        .addCase(getOneSppByThnAjr.rejected,(state)=>{
            state.loadingSpp = false
        })
        .addCase(updateListSpp.pending,(state)=>{
            state.loadingSpp = true
        })
        .addCase(updateListSpp.fulfilled,(state,action)=>{
            state.loadingSpp = false
            state.msgSpp= action.payload.msg
        })
        .addCase(updateListSpp.rejected,(state,action)=>{
            state.loadingSpp = false
            state.msgSpp = action.payload
        })
        .addCase(createTagihan.pending,(state)=>{
            state.loadingSpp = true
        })
        .addCase(createTagihan.fulfilled,(state,action)=>{
            state.loadingSpp = false
            state.msgSpp = action.payload.msg
        })
        .addCase(createTagihan.rejected,(state,action)=>{
            state.loadingSpp = false
            state.msgSpp = action.payload
        })
        .addCase(createTahun.pending,(state)=>{
            state.loadingSpp = true
        })
        .addCase(createTahun.fulfilled,(state,action)=>{
            state.loadingSpp = false
            state.msgSpp = action.payload.msg
        })
        .addCase(createTahun.rejected,(state,action)=>{
            state.msgSpp = false
            state.msgSpp = action.payload
        })
        .addCase(getOneTagihan.pending,(state)=>{
            state.loadingSpp = true
        })
        .addCase(getOneTagihan.fulfilled,(state,action)=>{
            state.loadingSpp = false
            state.oneTagihan = action.payload
        })
        .addCase(getOneTagihan.rejected,(state,action)=>{
            state.msgSpp = false
            state.msgSpp = action.payload
        })
        .addCase(upByUuid.pending,(state)=>{
            state.loadingSpp = true
        })
        .addCase(upByUuid.fulfilled,(state,action)=>{
            state.loadingSpp = false
            state.oneUp = action.payload
        })
        .addCase(upByUuid.rejected,(state,action)=>{
            state.msgSpp = false
            state.msgSpp = action.payload
        })
        .addCase(normalisasiSpp.pending,(state)=>{
            state.loadingSpp = true
        })
        .addCase(normalisasiSpp.fulfilled,(state,action)=>{
            state.loadingSpp = false
            state.msgSpp = action.payload.msg
        })
        .addCase(normalisasiSpp.rejected,(state,action)=>{
            state.loadingSpp = false
            state.msgSpp = action.payload
        })        

    }
})
export default sppStore.reducer