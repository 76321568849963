import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getSistem } from "../store/actions/optional";


export default function useGetSistem(trigger){
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getSistem())
    },[trigger])
}
