import { HiOutlineCalendarDays, HiOutlineChevronDown, HiOutlineCircleStack, HiOutlineUser } from "react-icons/hi2";
import { AiOutlineControl, AiOutlinePicture } from "react-icons/ai";

export const menuUtama = [
    { link: "/home/dashboard", title: "Dashboard", logo: <HiOutlineCircleStack className="w-6 h-6 mx-2" />, drop: false },
    { link: "/home/laporan", title: "Laporan", logo: <HiOutlineCalendarDays className="w-6 h-6 mx-2" />, drop: false },
    { link: "/home/laporan/donatur", title: "Laporan Donatur", logo: <HiOutlineCalendarDays className="w-6 h-6 mx-2" />, drop: false },
    { link: "/home/upload/gambar", title: "Billboard", logo: <AiOutlinePicture className="w-6 h-6 mx-2" />, drop: false },
]
export const menuSantri = [
    { link: "/home/santri", title: "Santri", logo: <HiOutlineUser className="w-6 h-6 mx-2" />, drop: false },
    { link: "/home/alumni", title: "Alumni", logo: <HiOutlineUser className="w-6 h-6 mx-2" />, drop: false },
    { link: "/home/subsidi", title: "Subsidi", logo: <HiOutlineUser className="w-6 h-6 mx-2" />, drop: false },
    { link: "/home/tunggakan", title: "Tunggakan", logo: <HiOutlineCircleStack className="w-6 h-6 mx-2" />, drop: false },
    { link: "/home/tunggakan/donatur", title: "Tunggakan Donatur", logo: <HiOutlineCircleStack className="w-6 h-6 mx-2" />, drop: false }
]
export const menuSpp = [
    {  title: "Panel", logo: <AiOutlineControl className="w-6 h-6 mx-2" />,dropIcon: <HiOutlineChevronDown className='w-5 h-5' />, drop: true,menuDrop:[
        { link: "/home/list/spp",title: "List Spp" },
        { link: "/home/list/lembaga", title: "List Lembaga" },
        { link: "/home/list/tahun", title: "List Tahun Ajar" },
        { link: "/home/list/tahun-sistem", title: "List Tahun Sistem" },
        { link: "/home/list/ruang", title: "List Ruang Kelas" },
        { link: "/home/list/user", title: "List User" },
        { link: "/home/list/donatur", title: "List Donatur" },
        { link: "/home/list/golongan/donatur", title: "List Golongan Donatur" },
        { link: "/home/list/diskon-bersaudara", title: "List Diskon Bersaudara" },

    ] }
]